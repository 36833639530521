import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { editTask, getParticipants, getTask } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IEventsRoot } from 'src/modules/events/types';
import { getEvents } from 'src/modules/events/services';
import { getTinyUsers } from 'src/modules/users/services';
import { editTaskMapper } from '../mappers';
import { TTinyUsersRoot } from 'src/modules/users/types';
import { ITask } from '../types';

export const useEditTask = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { taskId } = useParams();

  const navigate = useNavigate();

  const { data: eventsData } = useQuery('events', getEvents, {
    select: ({ data }: { data: IEventsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      return data.data;
    },
    onError: queryError,
  });

  const { data } = useQuery(
    ['get-task', taskId],
    () => {
      if (taskId) {
        return getTask(taskId);
      }
    },
    {
      select: ({ data }): ITask => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { mutate, isLoading: isEditingTask } = useMutation(
    'edit-task',
    editTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.task')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      event: (data?.event && data?.event.id) || '',
      deadline: data?.deadline || '',
      assignedTo: (data?.assignedTo && data?.assignedTo.id) || null,
      participant: (data?.participant && data?.participant.id) || '',
    },
    onSubmit: (values) => {
      if (values.title.length > 0) {
        const editTaskBody = editTaskMapper(values);

        mutate({ taskId, data: editTaskBody });
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { data: participantsData, isLoading } = useQuery(
    ['get-participants', formik.values.event],
    () => {
      if (formik.values.event !== '') {
        return getParticipants(formik.values.event);
      }
    },
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
      enabled: formik.values.event !== '',
    },
  );

  return {
    data,
    eventsData,
    usersData,
    participantsData,
    isLoading,
    formik,
    isEditingTask,
  };
};
