import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddTaskForm } from '../components/add-task-form';

export const AddTaskContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tasks.title'),
      href: '/dashboard/tasks',
    },
    {
      label: t('tasks.addTask'),
      href: '/dashboard/add-task',
    },
  ];
  return (
    <PageWrapper isPaper={false} routes={routes} title={t('tasks.addTask')}>
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddTaskForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
