import { AdditionalInfosContainer } from '../containers/additional-infos-container';
import AdditionalContextProvider from '../provider/additional-context-provider';


export const AdditionalInfos = () => {
  return (
    <AdditionalContextProvider>
      <AdditionalInfosContainer />
    </AdditionalContextProvider>
  );
};
