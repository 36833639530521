import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Box, Button, Card, Typography } from '@mui/material';
import { useAdditionalInfos } from '../hooks/use-additional-infos';
import { useAdditionalInfosTable } from '../hooks/use-additional-infos-table';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { AdditionalContext } from '../context/additional-info-context';
import AdditionSearch from '../components/addition-search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const AdditionalInfosContainer = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { page, limit, setPage, setLimit } = useContext(AdditionalContext);

  const { data } = useAdditionalInfos();

  const [columns, options, routes] = useAdditionalInfosTable();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      action={
        <Link
          to="/dashboard/additional-infos/add-additional-info"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addAdditionalInfo')}</Button>
        </Link>
      }
      routes={routes}
      title={t('additionalInfos.title')}
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <AdditionSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
};
