import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ITour } from '../types';

export const TourDetailsForm = ({ data }: { data: ITour }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.name')}: {data?.title}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.country')}: {data?.country}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.description')}: {data?.description}
            </Box>
          </Grid>
          {data?.additionalInfo.length > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {data?.additionalInfo.map((note, i) => {
                  return (
                    <Box key={i}>
                      {t('inputs.labels.additionalInfo')}: {note}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
