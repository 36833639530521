import { Link } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRentalsTable } from '../hooks/use-rentals-table';
import { useRentals } from '../hooks/use-rentals';

export const RentalsContainer = () => {
  const { data } = useRentals();

  const [columns, options, routes] = useRentalsTable();

  const { t } = useTranslation();

  return (
    <PageWrapper
      action={
        <Link
          to="/dashboard/rentals/add-rental"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addRental')}</Button>
        </Link>
      }
      routes={routes}
      title={t('rentals.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
