import { AddRepeatingTransaction } from '../pages/add-repeating-transaction';
import { EditRepeatingTransaction } from '../pages/edit-repeating-transaction';
import { RepeatingTransactionsDetails } from '../pages/repeating-transaction-details';
import { RepeatingTransactions } from '../pages/repeating-transactions';

const repeatingTransactionsRouter = {
  path: 'repeating-transactions',
  children: [
    {
      path: '',
      element: <RepeatingTransactions />,
      children: [],
    },
    {
      path: ':repeatingTransactionId',
      element: <RepeatingTransactionsDetails />,
    },
    {
      path: 'add-repeating-transaction',
      element: <AddRepeatingTransaction />,
    },
    {
      path: 'edit/:repeatingTransactionId',
      element: <EditRepeatingTransaction />,
    },
  ],
};

export default repeatingTransactionsRouter;
