import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { getUser } from '../services';
import { IUser } from '../types';

export const useUser = () => {
  const { userId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-user', userId],
    () => {
      if (userId) {
        return getUser(userId);
      }
    },
    {
      select: ({ data }): IUser => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
