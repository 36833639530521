import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddTask } from '../hooks/use-add-task';
import { IEvent } from 'src/modules/events/types';
import { ITinyUser } from 'src/modules/users/types';
import { IParticipant } from '../types';

export const AddTaskForm = () => {
  const [selectParticipants, setSelectParticipants] = useState(false);

  const { t } = useTranslation();

  const { formik, eventsData, usersData, participantsData, isLoading } =
    useAddTask();

  useEffect(() => {
    if (participantsData && formik.values.participants) {
      const result =
        participantsData.length === formik.values.participants.length;

      setSelectParticipants(result);
    } else {
      setSelectParticipants(false);
    }
  }, [participantsData, formik.values.participants]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.task')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            fullWidth={true}
            options={eventsData || []}
            disablePortal
            onChange={(_e, newValue: IEvent) => {
              formik.setFieldValue('event', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: IEvent) => {
              return `${option.title} ${option.id}`;
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} label={t('event.title')} />
            )}
          />
          {!!formik.errors.event && formik.touched.event && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.event}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            fullWidth={true}
            options={usersData || []}
            disablePortal
            onChange={(_e, newValue: ITinyUser) => {
              formik.setFieldValue('assignedTo', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: ITinyUser) => {
              return `${option.fullName} (${option.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.whoCompletesTheTask')}
              />
            )}
          />
          {!!formik.errors.assignedTo && formik.touched.assignedTo && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.assignedTo}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              gap: 1.5,
            }}
          >
            <Autocomplete
              fullWidth={true}
              disabled={isLoading}
              disablePortal
              multiple={true}
              options={participantsData || []}
              value={
                formik.values.participants.length > 0 && participantsData
                  ? formik.values.participants.map((pId) => {
                      const participant = participantsData.find(
                        (pObj: IParticipant) => {
                          return pObj.userId.id === pId;
                        },
                      );
                      return participant;
                    })
                  : []
              }
              onChange={(_e, newValue) => {
                formik.setFieldValue(
                  'participants',
                  newValue
                    ? newValue.map((item: IParticipant) => item.userId.id)
                    : [],
                );
                formik.setFieldValue(
                  'title',
                  newValue ? formik.values.title : '',
                );
              }}
              getOptionLabel={(option: IParticipant) =>
                option
                  ? `${option.userId.fullName} (${option.userId.code})`
                  : ''
              }
              renderInput={(params) => (
                <TextField fullWidth {...params} label={t('users.title')} />
              )}
            />

            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={t('inputs.labels.allUsers')}
              control={
                isLoading ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : (
                  <Checkbox
                    onChange={(e) => setSelectParticipants(e.target.checked)}
                    checked={selectParticipants}
                    disabled={isLoading}
                  />
                )
              }
            />
          </Box>
          {!!formik.errors.participants && formik.touched.participants && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.participants}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmDateTimePicker
            inputFormat="dd/MM/yyyy"
            formik={formik}
            label={t('inputs.labels.endDate')}
            name="deadline"
          />
          {!!formik.errors.deadline && formik.touched.deadline && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.deadline}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <SubmitButton load={false} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.add')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
