import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddActionForm } from '../components/add-action-form';

export const AddActionContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('actions.title'),
      href: '/dashboard/actions',
    },
    {
      label: t('actions.addAction'),
      href: '/dashboard/actions/add-action',
    },
  ];
  return (
    <PageWrapper isPaper={false} routes={routes} title={t('actions.addAction')}>
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddActionForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
