import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { PermissionDetailsForm } from '../components/permission-details-form';
import { usePermission } from '../hooks/use-permission';

export const PermissionDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = usePermission();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('permissions.title'),
      href: '/dashboard/permissions',
    },
    {
      label: data?.name ? data?.name : '',
      href: `/dashboard/permissions/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('permissions.premissionName')}: ${
        data?.name ? data?.name : ''
      }`}
    >
      <Helmet>
        <title>{`${t('permissions.premissionName')} | ${
          data?.name ? data?.name : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <PermissionDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
