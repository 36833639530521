import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getEvents = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/events`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getEvent = async (eventId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/events/${eventId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createEvent = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/events`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getEventData = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/app/add-event-data`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteParticipant = async ({ eventId, participantId }) => {
  try {
    return await axios.delete(`/api/v1/events/${eventId}/participants`, {
      data: { participants: [participantId] },
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const registerUserToEvent = async ({
  userIds,
  eventId,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/events/${eventId}/participants`,
      {
        userIds,
      },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const toggleTask = async ({
  taskId,
  executedBy = null,
}: {
  taskId: string;
  executedBy?: string;
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/tasks/${taskId}/toggle`,
      {
        executedBy,
      },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const amountAfterDicountService = async ({
  eventId,
  userId,
  amountAfterDicount,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/events/${eventId}/discount`,
      {
        userId,
        amountAfterDicount,
      },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const addAmount = async ({
  eventId,
  userId,
  amount,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/events/${eventId}/payment`,
      {
        userId,
        amount,
      },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const addArchiveEvent = async (
  eventId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/events/${eventId}/archive`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteEvent = async (eventId: string): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/events/${eventId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getGuides = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/users?roles=guide`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
