import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { createService, getService, editService } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { IService } from '../types';

export const useServiceForm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { serviceId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const { data } = useQuery(
    ['get-service', serviceId],
    () => {
      if (serviceId) {
        return getService(serviceId);
      }
    },
    {
      select: ({ data }):IService => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.inputs.name')),
    price: Yup.string().required(t('yup.inputs.amount')),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      price: data?.price || '',
    },
    onSubmit: (values) => {
      if (values) {
        if (serviceId) {
          editServiceMutate({ serviceId, data: values });
        } else {
          mutate(values);
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading: isAddingService } = useMutation(
    'add-service',
    createService,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const { mutate: editServiceMutate, isLoading: isEditingService } =
    useMutation('edit-service', editService, {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    });

  return {
    data,
    formik,
    isAddingService,
    isEditingService,
  };
};
