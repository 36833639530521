import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getTask } from '../services';
import { ITask } from '../types';

export const useTask = () => {
  const { taskId } = useParams();

  const { data } = useQuery(
    ['get-task', taskId],
    () => {
      if (taskId) {
        return getTask(taskId);
      }
    },
    {
      select: ({ data }): ITask => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
  };
};
