import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IInventory } from '../types';
import { InventoriesTableActionButtons } from '../components/inventories-table-action-buttons';

export const useInventoriesTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const { t } = useTranslation();
  const options: IOptions = {
    defaultLimit: 10,
    pageLimits: [5, 25, 50],
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('inventories.title'),
      href: '/dashboard/inventories',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'teacher',
        align: 'left',
        label: t('table.model'),
        extructor: (inventory: IInventory) => inventory.model,
      },
      {
        id: 'group',
        align: 'center',
        label: t('table.brand'),
        extructor: (inventory: IInventory) => inventory.brand,
      },
      {
        id: 'topic',
        align: 'center',
        label: t('table.type'),
        extructor: (inventory: IInventory) => inventory.type,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (inventory: IInventory) => {
          return <InventoriesTableActionButtons inventory={inventory} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
