import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { useTranslation } from 'react-i18next';
import { ManageTagsActionButtons } from '../components/action-buttons';

export const useManageTagsTable = (
  formik,
): [IGenericTableColumns, IOptions, IBreadcrumbRoutes] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('manageTags.title'),
      href: '/dashboard/users/manage-tags',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (tag) => tag.label,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (tag) => {
          return <ManageTagsActionButtons tag={tag} formik={formik} />;
        },
      },
    ];
    return columns;
  }, [formik]);

  return [columns, options, routes];
};
