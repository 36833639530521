import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getServices } from '../services';
import { TServicesRoot } from '../types';

export const useServices = () => {
  const { data } = useQuery('get-services', getServices, {
    select: ({ data }: { data: TServicesRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
