import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/use-user';
import { Helmet } from 'react-helmet-async';
import { UserDetailsForm } from '../components/user-details-form';
import { UserActionButtons } from '../components/user-action-buttons';

export const UserDetailsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useUser();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('users.title'),
      href: '/dashboard/users',
    },
    {
      label: t('user.title'),
      href: '/dashboard/user',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('user.title')}: ${data?.fullName ? data?.fullName : ''}`}
    >
      <Helmet>
        <title>{`${t('user.title')} | ${
          data?.fullName ? data?.fullName : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <>
          <Grid container>
            <UserActionButtons />
          </Grid>
          <Grid container>
            <UserDetailsForm data={data} />
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};
