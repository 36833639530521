import { Link } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCoupons } from '../hooks/use-coupons';
import { useCouponsTable } from '../hooks/use-coupons-table';

export const CouponsContainer = () => {
  const { t } = useTranslation();

  const { data } = useCoupons();

  const [columns, options, routes] = useCouponsTable();

  return (
    <PageWrapper
      action={
        <Link
          to="/dashboard/coupons/add-coupon"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addCoupon')}</Button>
        </Link>
      }
      routes={routes}
      title={t('coupons.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
