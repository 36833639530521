import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  // InputAdornment,
  // TableContainer,
  // Table,
  // TableHead,
  // TableBody,
  // TableRow,
  // TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'src/shared/components/Submit-button';
import { OmDateTimePicker, OmInput } from 'src/shared/components';
// import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
// import { useUsers } from '../hooks/use-users';
// import { useMemo, useState } from 'react';
// import { IGenericTableColumns } from 'src/shared/components/generic-table/types';

export const UserForm = ({
  addUserData,
  data,
  formik,
  isAddingUser,
  isEditingUser,
}) => {
  // const [query, setQuery] = useState<string>('');
  const { t } = useTranslation();

  // const { data: usersData } = useUsers();

  // const handleQueryChange = (event) => {
  //   event.persist();
  //   setQuery(event.target.value);
  // };

  // const filteredUsers = usersData?.filter((user: any) => {
  //   const searchTerm = query.trim().toLowerCase();
  //   const lowercaseTitle = user.fullName.toLowerCase();
  //   return lowercaseTitle.includes(searchTerm) && user.fullName.trim() !== '';
  // });

  const rolesData = [
    {
      id: 1,
      value: 'owner',
    },
    {
      id: 2,
      value: 'admin',
    },
    {
      id: 3,
      value: 'manager',
    },
    {
      id: 4,
      value: 'guide',
    },
    {
      id: 5,
      value: 'vendor',
    },
    {
      id: 6,
      value: 'client',
    },
  ];

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Card
          sx={{
            p: 2,
            minHeight: '300px',
            maxHeight: '400px',
            overflow: 'auto',
          }}
        >
          <Typography>{t('users.title')}</Typography>
          <TextField
            sx={{
              mt: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder={t('inputs.labels.searchUser')}
            value={query}
            size="small"
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      noWrap
                      variant="subtitle2"
                      sx={{ textTransform: 'lowercase' }}
                    >
                      {t('table.fullName')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={1}>
                      <Typography>{t('noResultFound')}</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUsers?.map((user, i) => (
                    <TableRow key={i}>
                      <TableCell>{user.fullName}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid> */}
      <Grid item xs={12}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.name')}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.surname')}
                name="surname"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.fatherName')}
                name="fatherName"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.email')}
                name="email"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmDateTimePicker
                inputFormat="dd/MM/yyyy"
                formik={formik}
                label={t('inputs.labels.dateOfBirth')}
                name="dateOfBirth"
              />
              {!!formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943',
                  }}
                >
                  {formik.errors.dateOfBirth}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.numberOne')}
                name="numberOne"
                startAdornment={
                  !data &&
                  !formik.values.isForgeinOne && (
                    <InputAdornment position="start">+994</InputAdornment>
                  )
                }
              />
              {!data && (
                <FormControlLabel
                  sx={{ mb: 1 }}
                  label={t('inputs.labels.numbersIsForgein')}
                  control={
                    <Checkbox
                      onChange={formik.handleChange}
                      checked={formik.values.isForgeinOne}
                      name="isForgeinOne"
                    />
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.numberTwo')}
                name="numberTwo"
                startAdornment={
                  !data &&
                  !formik.values.isForgeinTwo && (
                    <InputAdornment position="start">+994</InputAdornment>
                  )
                }
              />
              {!data && (
                <FormControlLabel
                  sx={{ mb: 1 }}
                  label={t('inputs.labels.numbersIsForgein')}
                  control={
                    <Checkbox
                      onChange={formik.handleChange}
                      checked={formik.values.isForgeinTwo}
                      name="isForgeinTwo"
                    />
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput formik={formik} label="facebook" name="facebook" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput formik={formik} label="instagram" name="instagram" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput formik={formik} label="linkedin" name="linkedin" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                multiple={true}
                options={rolesData || []}
                disablePortal
                value={formik.values.roles.map((role) => {
                  const roles = rolesData.find(
                    (roleObj) => roleObj.value === role,
                  );
                  return roles;
                })}
                onChange={(_e, newValue: any) => {
                  formik.setFieldValue(
                    'roles',
                    newValue ? newValue.map((item) => item.value) : '',
                  );
                }}
                getOptionLabel={(option: any) => {
                  return `${option.value}`;
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label={t('inputs.labels.roles')}
                  />
                )}
              />
              {!!formik.errors.roles && formik.touched.roles && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943',
                  }}
                >
                  {formik.errors.roles}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={formik}
                multiline={true}
                rows={3}
                label={t('inputs.labels.note')}
                name="note"
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <SubmitButton
                load={isAddingUser || isEditingUser}
                sx={{ width: { xs: '100%', md: 200 } }}
              >
                {data ? t('buttons.edit') : t('buttons.add')}
              </SubmitButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
