import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getTransactions = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/transactions`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTransaction = async (transactionId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/transactions/${transactionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addTransaction = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/transactions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editTransaction = async ({
  transactionId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/transactions/${transactionId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteTransaction = async (
  transactionId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/transactions/${transactionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
