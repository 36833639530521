import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useServiceDetails } from '../hooks/use-service-details';
import { ServiceDetailsForm } from '../components/service-details-form';

export const ServiceDetailsContainer = () => {
  const { data, isLoading } = useServiceDetails();

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('services.title'),
      href: '/dashboard/services',
    },
    {
      label: data?.name ? data?.name : '',
      href: `/dashboard/services/${data?.id}`,
    },
  ];

  return (
    <PageWrapper isPaper={false} routes={routes} title={t('services.title')}>
      <Helmet>
        <title>{`${t('services.title')} | ${
          data?.name ? data?.name : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container>
            <ServiceDetailsForm data={data} />
          </Grid>
        </Card>
      )}
    </PageWrapper>
  );
};
