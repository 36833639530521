import { Outlet } from 'react-router-dom';
import { ToursContainer } from '../containers/tours-container';

export const Tours = () => {
  return (
    <>
      <Outlet />
      <ToursContainer />
    </>
  );
};
