import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { ServiceForm } from '../components/service-form';
import { useServiceForm } from '../hooks/use-service-form';

export const ServiceContainer = () => {
  const { t } = useTranslation();

  const { data, formik, isAddingService, isEditingService } = useServiceForm();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('services.title'),
      href: '/dashboard/services',
    },
    {
      label: data ? data?.name : t('services.addService'),
      href: data
        ? `/dashboard/services/${data?.id}`
        : '/dashboard/services/add-service',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data ? data?.name : t('services.addService')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <ServiceForm
            data={data}
            formik={formik}
            isAddingService={isAddingService}
            isEditingService={isEditingService}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
