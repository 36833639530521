import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { ActionDetailsForm } from '../components/action-details-form';
import { useAction } from '../hooks/use-action';

export const ActionDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useAction();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('actions.title'),
      href: '/dashboard/actions',
    },
    {
      label: data?.title ? data?.title : '',
      href: `/dashboard/actions/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('actions.actionName')}: ${data?.title ? data?.title : ''}`}
    >
      <Helmet>
        <title>{`${t('actions.actionName')} | ${
          data?.title ? data?.title : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <ActionDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
