import { useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { useTranslation } from 'react-i18next';
import { editRental, getRental } from '../services';
import { IEquipmentsRoot } from 'src/modules/equipments/types';
import { getTinyUsers } from 'src/modules/users/services';
import { IRental } from '../types';
// import { TInventoriesRoot } from 'src/modules/inventories-2/types';
// import { getInventories } from 'src/modules/inventories/services';

export const useEditRental = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { rentalId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['get-rental', rentalId],
    () => {
      if (rentalId) {
        return getRental(rentalId);
      }
    },
    {
      select: ({ data }): IRental => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  // const { data: inventoriesData } = useQuery(
  //   'get-inventories',
  //   getInventories,
  //   {
  //     select: ({ data }: { data: TInventoriesRoot }) => {
  //       if (data?.status === 'success') {
  //         return data?.data;
  //       }
  //     },
  //     onError: queryError,
  //   },
  // );

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: IEquipmentsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading: isEditingRental } = useMutation(
    'edit-rental',
    editRental,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    inventories: Yup.array().required(t('yup.inputs.name')),
    customer: Yup.string().required(t('yup.inputs.customer')),
    totalCost: Yup.number()
      .min(1, t('yup.inputs.totalCostMin'))
      .required(t('yup.inputs.totalCost')),
    startDate: Yup.date().required(t('yup.inputs.startDate')),
    endDate: Yup.date().required(t('yup.inputs.endDate')),
    exacutedBy: Yup.string().required(t('yup.inputs.exacutedBy')),
  });

  const formik = useFormik({
    initialValues: {
      inventories: data?.inventories || [],
      startDate: data?.startDate || '',
      endDate: data?.endDate || '',
      customer: data?.customer.id || '',
      totalCost: data?.rentPrice || 0,
      referal: data?.referal.id || '',
      exacutedBy: data?.exacutedBy.id || '',
    },
    onSubmit: (values) => {
      mutate({ rentalId, data: values });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  // const foundInventory = useMemo(() => {
  //   return formik.values.inventories?.flatMap((item) => {
  //     return inventoriesData?.find((inventory) => inventory.id === item.id);
  //   });
  // }, [formik.values.inventories, inventoriesData]);

  const endDate = useMemo(
    () => new Date(formik.values.endDate),
    [formik.values.endDate],
  );
  const startDate = useMemo(
    () => new Date(formik.values.startDate),
    [formik.values.startDate],
  );
  const daysLength = endDate.getTime() - startDate.getTime();
  const days = useMemo(() => daysLength / (1000 * 60 * 60 * 24), [daysLength]);

  // useEffect(() => {
  //   if (formik.values.inventories.length === 0) {
  //     formik.setFieldValue('totalCost', 0);
  //   } else if (
  //     foundInventory &&
  //     formik.values.startDate &&
  //     formik.values.endDate
  //   ) {
  //     const dailyCost = foundInventory
  //       ?.map((item) => item?.rentPrice)
  //       .reduce((a, b) => a + b, 0);
  //     const dailyCostTotal = dailyCost * days;
  //     const dailyCostTotalFormatted = Number(dailyCostTotal).toFixed(2);
  //     if (dailyCostTotalFormatted) {
  //       formik.setFieldValue('totalCost', dailyCostTotalFormatted);
  //     }
  //   }
  // }, [formik.values.inventories, days, foundInventory]);

  return {
    data,
    usersData,
    // inventoriesData,
    formik,
    isLoading,
    isEditingRental,
  };
};
