import {
  Box,
  Modal,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAddAction } from 'src/modules/actions/hooks/use-add-action';
import { OmDateTimePicker, OmInput } from 'src/shared/components';
import { useAddNewTaskToParticipant } from '../hooks/use-add-new-task-to-participant';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 800,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

export const AddNewTaskToParticipant = () => {
  const { t } = useTranslation();

  const { formik, isLoading } = useAddNewTaskToParticipant();

  const navigate = useNavigate();

  const handleClose = () => {
    if (!isLoading) return navigate(-1);
  };

  return (
    <>
      <Modal
        keepMounted
        open={true}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('modal.addNewTask')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.task')}
                name="title"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <OmDateTimePicker
                inputFormat="dd/MM/yyyy"
                formik={formik}
                label={t('inputs.labels.endDate')}
                name="deadline"
              />
              {!!formik.errors.deadline && formik.touched.deadline && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943',
                  }}
                >
                  {formik.errors.deadline}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: '15px;' }}
              onClick={handleClose}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: '15px;' }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading && <CircularProgress sx={{ mr: 0.5 }} size={14} />}
              {t('buttons.add')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
