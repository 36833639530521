import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddRental } from '../hooks/use-add-rental';
import Manat from 'src/assets/icon/manat';
import { ITinyUser } from 'src/modules/users/types';

export const AddRentalForm = () => {
  // const { formik, isCreatingRental, usersData, inventoriesData } =
  //   useAddRental();

  const { t } = useTranslation();

  return (
    // <Box component="form" onSubmit={formik.handleSubmit}>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} sm={12} md={6}>
    //       {/* <Autocomplete
    //         multiple={true}
    //         options={inventoriesData || []}
    //         disablePortal
    //         disabled={false}
    //         onChange={(_e, newValue: any) => {
    //           formik.setFieldValue(
    //             'inventories',
    //             newValue ? newValue.map((item: IInventory) => item.id) : '',
    //           );
    //         }}
    //         // getOptionLabel={(option: IInventory) => {
    //         //   return `${option.title} (${option.code})`;
    //         // }}
    //         renderInput={(params) => (
    //           <TextField fullWidth {...params} label={t('inventories.title')} />
    //         )}
    //       /> */}
    //       {!!formik.errors.inventories && formik.touched.inventories && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.inventories}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <Autocomplete
    //         options={usersData || []}
    //         disablePortal
    //         onChange={(_e, newValue: ITinyUser) => {
    //           formik.setFieldValue('customer', newValue ? newValue.id : '');
    //         }}
    //         getOptionLabel={(option: ITinyUser) => {
    //           return `${option.fullName} (${option.code})`;
    //         }}
    //         renderInput={(params) => (
    //           <TextField
    //             fullWidth
    //             {...params}
    //             label={t('inputs.labels.customer')}
    //           />
    //         )}
    //       />
    //       {!!formik.errors.customer && formik.touched.customer && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.customer}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <Autocomplete
    //         options={usersData || []}
    //         disablePortal
    //         onChange={(_e, newValue: ITinyUser) => {
    //           formik.setFieldValue('referal', newValue ? newValue.id : '');
    //         }}
    //         getOptionLabel={(option: ITinyUser) => {
    //           return `${option.fullName} (${option.code})`;
    //         }}
    //         renderInput={(params) => (
    //           <TextField
    //             fullWidth
    //             {...params}
    //             label={t('inputs.labels.referal')}
    //           />
    //         )}
    //       />
    //       {!!formik.errors.referal && formik.touched.referal && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.referal}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <Autocomplete
    //         options={usersData || []}
    //         disablePortal
    //         value={
    //           usersData && formik.values.exacutedBy
    //             ? usersData.find(
    //                 (option: ITinyUser) =>
    //                   option.id === formik.values.exacutedBy,
    //               )
    //             : null
    //         }
    //         onChange={(_e, newValue: ITinyUser) => {
    //           formik.setFieldValue('exacutedBy', newValue ? newValue.id : '');
    //         }}
    //         getOptionLabel={(option: ITinyUser) => {
    //           return `${option.fullName} (${option.code ? option.code : ''})`;
    //         }}
    //         renderInput={(params) => (
    //           <TextField
    //             fullWidth
    //             {...params}
    //             label={t('inputs.labels.exacutedBy')}
    //           />
    //         )}
    //       />
    //       {!!formik.errors.exacutedBy && formik.touched.exacutedBy && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.exacutedBy}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <OmDateTimePicker
    //         inputFormat="dd/MM/yyyy"
    //         formik={formik}
    //         label={t('inputs.labels.startDate')}
    //         name="startDate"
    //       />
    //       {!!formik.errors.startDate && formik.touched.startDate && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.startDate}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <OmDateTimePicker
    //         inputFormat="dd/MM/yyyy"
    //         formik={formik}
    //         label={t('inputs.labels.endDate')}
    //         name="endDate"
    //       />
    //       {!!formik.errors.endDate && formik.touched.endDate && (
    //         <Typography
    //           sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
    //         >
    //           {formik.errors.endDate}
    //         </Typography>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <OmInput
    //         formik={formik}
    //         label={t('inputs.labels.totalCost')}
    //         name="totalCost"
    //         type="number"
    //         onWheel={(e: any) => e.target.blur()}
    //         endAdornment={
    //           <InputAdornment position="end">
    //             <Manat />
    //           </InputAdornment>
    //         }
    //       />
    //     </Grid>
    //   </Grid>
    //   <Grid
    //     item
    //     xs={12}
    //     sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
    //   >
    //     <SubmitButton
    //       load={isCreatingRental}
    //       sx={{ width: { xs: '100%', md: 200 } }}
    //     >
    //       {t('buttons.add')}
    //     </SubmitButton>
    //   </Grid>
    // </Box>
    <div>hello</div>
  );
};
