import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@mui/material';
import { OmDateTimePicker, OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import Manat from 'src/assets/icon/manat';
import Precent from 'src/assets/icon/precent';
import CachedIcon from '@mui/icons-material/Cached';

export const CouponForm = ({
  data,
  eventsData,
  formik,
  isAddingCoupon,
  isGettingEvents,
  isEditingCoupon,
  isGettingCouponCode,
}) => {
  const { t } = useTranslation();

  const typeData = [
    {
      value: 'event',
      name: t('event.title'),
    },
    {
      value: 'general',
      name: t('general.title'),
    },
    {
      value: 'equipment',
      name: t('equipment.title'),
    },
  ];

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <OmInput
                readOnly={!formik.values.isEditCuponCode}
                formik={formik}
                label={t('inputs.labels.couponCode')}
                name="couponCode"
                endAdornment={
                  <Box display={'flex'}>
                    <FormControlLabel
                      sx={{ flex: 1, whiteSpace: 'nowrap' }}
                      label={t('inputs.labels.generateCouponCode')}
                      control={
                        <Checkbox
                          onChange={(e) =>
                            formik.setFieldValue(
                              'isEditCuponCode',
                              e.target.checked,
                            )
                          }
                          checked={formik.values.isEditCuponCode}
                          name="isEditCuponCode"
                        />
                      }
                    />
                    <Tooltip title={t('actionButtons.getCouponCode')} arrow>
                      <span>
                        <IconButton
                          component={'button'}
                          color="primary"
                          disabled={formik.values.isEditCuponCode}
                          onClick={() =>
                            formik.setFieldValue('getCouponCode', true)
                          }
                        >
                          {isGettingCouponCode ? (
                            <CircularProgress size={20} />
                          ) : (
                            <CachedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmSelect
              formik={formik}
              label={t('inputs.labels.type')}
              name="type"
              options={typeData}
            />
          </Grid>
          {formik.values.type === 'event' && (
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
                }}
                gap={1}
              >
                <Autocomplete
                  fullWidth={true}
                  multiple={true}
                  options={eventsData || []}
                  disablePortal
                  disabled={isGettingEvents || formik.values.allEvents}
                  value={
                    formik.values.allEvents
                      ? []
                      : formik.values.events.map((eId) => {
                          const event = eventsData?.find(
                            (event) => eId === event.id,
                          );
                          return event;
                        })
                  }
                  onChange={(_e, newValue) => {
                    formik.setFieldValue(
                      'events',
                      newValue.map((item: any) => item.id),
                    );
                  }}
                  getOptionLabel={(option: any) => {
                    return `${option && option.title} (${
                      option && option.code
                    })`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={t('inputs.labels.events')}
                    />
                  )}
                />
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  label={t('inputs.labels.allEvents')}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue('allEvents', e.target.checked)
                      }
                      checked={formik.values.allEvents}
                    />
                  }
                />
              </Box>
              {!!formik.errors.events && formik.touched.events && (
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: '#FF1943',
                  }}
                >
                  {formik.errors.events}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={
                formik.values.isPercent
                  ? t('inputs.labels.precent')
                  : t('inputs.labels.amount')
              }
              name="amount"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              endAdornment={
                <InputAdornment position="end">
                  {formik.values.isPercent ? <Precent /> : <Manat />}
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmDateTimePicker
              inputFormat="dd/MM/yyyy"
              formik={formik}
              label={t('inputs.labels.endDate')}
              name="expirationTime"
            />
            {!!formik.errors.expirationTime && formik.touched.expirationTime && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943',
                }}
              >
                {formik.errors.expirationTime}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={t('inputs.labels.isPercent')}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue('isPercent', e.target.checked)
                  }
                  checked={formik.values.isPercent}
                />
              }
            />
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={t('inputs.labels.isRent')}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue('isRent', e.target.checked)
                  }
                  checked={formik.values.isRent}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.description')}
              name="description"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <SubmitButton
            load={isAddingCoupon || isEditingCoupon}
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.edit') : t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
    </>
  );
};
