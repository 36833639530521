import { Outlet } from 'react-router';
import { AddInventoryContainer } from '../containers/add-inventory-container';

export const AddInventory = function () {
  return (
    <>
      <AddInventoryContainer />
      <Outlet />
    </>
  );
};
