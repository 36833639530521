export const couponMapper = (values) => {
  return {
    couponCode: values.couponCode.trim(),
    type: values.type,
    events: values.events,
    isPercent: values.isPercent,
    isRent: values.isRent,
    expirationTime: values.expirationTime,
    amount: values.amount,
    description: values.description,
  };
};
