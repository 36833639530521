import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getTasks = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tasks`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTask = async (taskId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tasks/${taskId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addTask = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/tasks`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteTask = async (taskId: string): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/tasks/${taskId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteTasks = async (selectedItems): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/tasks`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
      data: {
        ids: selectedItems,
      },
    });
  } catch (e) {
    return e;
  }
};

export const editTask = async ({ taskId, data }): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/tasks/${taskId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getParticipants = async (eventId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/events/${eventId}/participants`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
