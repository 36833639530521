import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getUsers = async (query): Promise<AxiosResponse> => {
  const [, page, limit, name] = query.queryKey;

  const queryObj: any = {
    ...(page && { page: Number(page) + 1 }),
    ...(limit && { limit: limit }),
    ...(name && { query: name }),
  };

  const queryString = '?' + new URLSearchParams(queryObj).toString();

  try {
    return await axios.get(`/api/v1/users${queryString}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTinyUsers = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/users/tiny-users`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getUser = async (userId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/users/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createUser = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/users`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteUser = async (userId): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/users/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addArchiveUser = async (userId): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/users/${userId}/archive`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addUserDataService = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/app/add-user-data`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editUser = async ({ userId, data }): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/users/${userId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
