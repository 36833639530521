import { Outlet } from 'react-router-dom';
import { CertificateDetailsContainer } from '../containers/certificate-details-container';

export const CertificateDetails = () => {
  return (
    <>
      <Outlet />
      <CertificateDetailsContainer />
    </>
  );
};
