import { AddCategory } from '../pages/add-category';
import { Categories } from '../pages/categories';
import { CategoryDetails } from '../pages/category-details';
import { EditCategory } from '../pages/edit-category';

const categoriesRouter = {
  path: 'categories',
  children: [
    {
      path: '',
      element: <Categories />,
      children: [],
    },
    {
      path: ':categoryId',
      element: <CategoryDetails />,
    },
    {
      path: 'add-category',
      element: <AddCategory />,
    },
    {
      path: 'edit/:categoryId',
      element: <EditCategory />,
    },
  ],
};

export default categoriesRouter;
