import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useRepeatingTransactions } from '../hooks/use-repeating-transactions';
import { useRepeatingTransactionsTable } from '../hooks/use-repeating-transactions-table';
import GenericTable from 'src/shared/components/generic-table';

export const RepeatingTransactionsContainer = () => {
  const { t } = useTranslation();
  const { data } = useRepeatingTransactions();

  const [columns, options, routes] = useRepeatingTransactionsTable();

  return (
    <PageWrapper
      routes={routes}
      title={t('repeatingTransactions.title')}
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/repeating-transactions/add-repeating-transaction"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addRepeatingTransaction')}
        </Button>
      }
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
