import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';

import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addTask } from 'src/modules/tasks/services';
import { addNewTaskForParticipantsMapper } from '../mappers';

export const useAddNewTaskToParticipant = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eventId, participantId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.task')),
    deadline: Yup.string().required(t('yup.inputs.endDate')),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      event: eventId || '',
      participants: [participantId] || [''],
      deadline: '',
      relatedTo: 'participant',
      tasks: [],
    },
    onSubmit: (values) => {
      const updatedTask = [
        ...values.tasks,
        {
          title: values.title,
          deadline: values.deadline,
        },
      ];
      if (values) {
        const addNewTaskForParticipantsBody = addNewTaskForParticipantsMapper({
          ...values,
          tasks: updatedTask,
        });

        mutate(addNewTaskForParticipantsBody);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading } = useMutation(
    'add-new-task-to-participant',
    addTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return { formik, isLoading };
};
