import { useContext } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import EditIcon from '@mui/icons-material/Edit';
import { useDeleteService } from '../hooks/use-delete-service';

export const ServicesTableActionButtons = ({ servicesId }) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(ConfirmationModalContext);

  const { mutate } = useDeleteService();

  return (
    <Typography noWrap>
      <Tooltip title={t('actionButtons.view')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/services/${servicesId}`}
          color="primary"
        >
          <LaunchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('actionButtons.edit')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/services/edit/${servicesId}`}
          color="primary"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('actionButtons.delete')} arrow>
        <IconButton
          onClick={() => {
            dispatch({
              type: 'open',
              payload: {
                confirm: () => {
                  mutate(servicesId);
                  dispatch({
                    type: 'close',
                  });
                },
                message: 'modal.delete',
                isOpen: true,
                buttonMessage: 'actionButtons.delete',
                color: 'error',
              },
            });
          }}
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};
