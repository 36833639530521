import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';

export const InventoriesTableActionButtons: FC<{
  inventory;
}> = ({ inventory }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //   const { addArchiveLesson } = useArchiveLesson();
  const { dispatch } = useContext(ConfirmationModalContext);

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('tooltips.view')} arrow>
          <IconButton
            // component={RouterLink}
            // to={``}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.edit')} arrow>
          <IconButton color="primary">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('tooltips.archive')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    // addArchiveLesson(inventory.id);
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: t('messages.archiveInventory'),
                  isOpen: true,
                  buttonMessage: t('buttons.confirm'),
                  closeMessage: t('buttons.cancel'),
                  color: 'primary',
                },
              });
            }}
            color="primary"
          >
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
