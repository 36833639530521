import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { useTranslation } from 'react-i18next';
import { getTinyUsers } from 'src/modules/users/services';
import { editRepeatingTransaction, getRepeatingTransaction } from '../services';
import { IRepeatingTransaction } from '../types';
import { TTinyUsersRoot } from 'src/modules/users/types';

export const useEditRepeatingTransaction = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { repeatingTransactionId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['get-repeating-transaction', repeatingTransactionId],
    () => {
      if (repeatingTransactionId) {
        return getRepeatingTransaction(repeatingTransactionId);
      }
    },
    {
      select: ({ data }): IRepeatingTransaction => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }): TTinyUsersRoot => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading: isEditingRepTransaction } = useMutation(
    'edit-repeating-transaction',
    editRepeatingTransaction,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.name')),
    spender: Yup.string().required(t('yup.inputs.spender')),
    recipient: Yup.string().required(t('yup.inputs.recipient')),
    amount: Yup.number().required(t('yup.inputs.amount')),
    period: Yup.string().required(t('yup.inputs.period')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      spender: data?.spender.id || '',
      recipient: data?.recipient.id || '',
      amount: data?.amount || '',
      description: data?.description || '',
      paymentMethod: data?.paymentMethod || 'cash',
      type: data?.type || 'income',
      period: data?.period || '* * */30 * *',
    },
    onSubmit: (values) => {
      mutate({ repeatingTransactionId, data: values });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return {
    formik,
    data,
    usersData,
    isLoading,
    isEditingRepTransaction,
  };
};
