import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  () => `
        text-decoration: none;
`,
);

function Logo() {
  return (
    <Box sx={{ width: '80px', height: '40px' }}>
      <LogoWrapper to="/">
        <img
          src={process.env.REACT_APP_WHITE_LOGO_URL}
          alt="logo"
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        />
      </LogoWrapper>
    </Box>
  );
}

export default Logo;
