import { Grid, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATA_TYPES } from 'src/shared/costants/constants';
import { IUser } from '../types';

export const UserDetailsForm = ({ data }: { data: IUser }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card sx={{ p: 3 }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.code')}: {data?.code}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.fullName')}: {data?.fullName}
              </Box>
            </Grid>
            {data?.email && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.email')}: {data?.email}
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.gender')}: {data?.gender}
              </Box>
            </Grid>
            {data?.dateOfBirth && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.dateOfBirth')}:{' '}
                  {moment(data?.dateOfBirth).format(DATA_TYPES.mdy)}
                </Box>
              </Grid>
            )}
            {data?.phoneNumbers.length > 0 && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.phoneNumber')}:{' '}
                  {data?.phoneNumbers.map((number) => number).join(', ')}
                </Box>
              </Grid>
            )}
            {data?.tags.length > 0 && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.tag')}:{' '}
                  {data?.tags.map((tag) => tag).join(', ')}
                </Box>
              </Grid>
            )}
            {data?.permissions && data?.permissions.length > 0 && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.permissions')}:{' '}
                  {data?.permissions.map((permission) => permission).join(', ')}
                </Box>
              </Grid>
            )}
            {data?.socialMediaProfiles && data?.socialMediaProfiles.facebook && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  facebook: {data?.socialMediaProfiles.facebook}
                </Box>
              </Grid>
            )}
            {data?.socialMediaProfiles && data?.socialMediaProfiles.instagram && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  instagram: {data?.socialMediaProfiles.instagram}
                </Box>
              </Grid>
            )}
            {data?.socialMediaProfiles && data?.socialMediaProfiles.linkedin && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  linkedin: {data?.socialMediaProfiles.linkedin}
                </Box>
              </Grid>
            )}
            {data?.roles.length > 0 && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                  }}
                >
                  {t('inputs.labels.roles')}:{' '}
                  {data?.roles.map((role) => role).join(', ')}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};
