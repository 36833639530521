import { Grid, Box } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATA_TYPES } from 'src/shared/costants/constants';
import { ITask } from '../types';

export const TaskDetailsForm = ({ data }: { data: ITask }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.code')}: {data?.code}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.name')}: {data?.title}
            </Box>
          </Grid>
          {data?.event && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.event')}: {data?.event.title}
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.assignedTo')}: {data?.assignedTo.fullName}
            </Box>
          </Grid>
          {data?.participant && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.participant')}: {data?.participant.fullName}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.endDate')}:{' '}
              {moment(data?.deadline).format(DATA_TYPES.mdy)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
