import { Service } from '../pages/service';
import { ServiceDetails } from '../pages/service-details';
import { Services } from '../pages/services';

const servicesRouter = {
  path: 'services',
  children: [
    {
      path: '',
      element: <Services />,
    },
    {
      path: ':serviceId',
      element: <ServiceDetails />,
    },
    {
      path: 'add-service',
      element: <Service />,
    },
    {
      path: 'edit/:serviceId',
      element: <Service />,
    },
  ],
};

export default servicesRouter;
