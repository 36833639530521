import * as React from 'react';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { configType } from 'src/shared/contexts/Alert';
import { AlertContext } from 'src/shared/contexts/Alert';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AlertIndex() {
  const { config } = React.useContext(AlertContext);
  const [datas, setDatas] = React.useState<configType>({
    isOpen: false,
    type: 'error',
    message: 'error',
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setDatas({
      isOpen: false,
      type: config.type,
      message: '',
    });
  };

  React.useEffect(() => {
    setDatas({ ...config });
  }, [config]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={datas.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <CloseIcon htmlColor="#fff" />
          </IconButton>
        }
      >
        <Alert
          action={
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{ height: '30px' }}
              onClick={handleClose}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          }
          severity={datas.type}
          sx={{ width: '100%', color: '#fff' }}
        >
          {config.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

//
export default React.memo(AlertIndex);
