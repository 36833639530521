import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getAction } from '../services';
import { useParams } from 'react-router';
import { IAction } from '../types';

export const useAction = () => {
  const { actionId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-action', actionId],
    () => {
      if (actionId) {
        return getAction(actionId);
      }
    },
    {
      select: ({ data }): IAction => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
