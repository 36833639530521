import { AdditionalInfo } from '../pages/additional-info';
import { AdditionalInfoDetails } from '../pages/additional-info-details';
import { AdditionalInfos } from '../pages/additional-infos';

const additionalInfosRouter = {
  path: 'additional-infos',
  children: [
    {
      path: '',
      element: <AdditionalInfos />,
    },
    {
      path: ':additionalInfoId',
      element: <AdditionalInfoDetails />,
    },
    {
      path: 'add-additional-info',
      element: <AdditionalInfo />,
    },
    {
      path: 'edit/:additionalInfoId',
      element: <AdditionalInfo />,
    },
  ],
};

export default additionalInfosRouter;
