import { useTranslation } from 'react-i18next';
import { Box, Grid, InputAdornment } from '@mui/material';
import { OmInput } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import Manat from 'src/assets/icon/manat';

export const ServiceForm = ({
  data,
  formik,
  isAddingService,
  isEditingService,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.name')}
              name="name"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.amount')}
              name="price"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              endAdornment={
                <InputAdornment position="end">
                  <Manat />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <SubmitButton
            load={isAddingService || isEditingService}
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.edit') : t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
    </>
  );
};
