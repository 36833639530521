import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getTag } from '../services';
import { ITag } from '../types';

export const useTag = () => {
  const { tagId } = useParams();

  const { data } = useQuery(
    ['get-tag', tagId],
    () => {
      if (tagId) {
        return getTag(tagId);
      }
    },
    {
      select: ({ data }): ITag => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
  };
};
