import { Outlet } from "react-router-dom";
import { UsersContainer } from "../containers/users-container";
import UserContextProvider from "../provider/users-context-provider";

export const Users = () => {
  return (
    <UserContextProvider>
      <Outlet />
      <UsersContainer />
    </UserContextProvider>
  );
};
