import { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import {
  createCoupon,
  editCoupon,
  getCoupon,
  getCouponCode,
} from '../services';
import { getEvents } from 'src/modules/events/services';
import { IEventsRoot } from 'src/modules/events/types';
import { queryError } from 'src/shared/utils/query-error';
import { couponMapper } from '../mappers';
import { ICoupon } from '../types';

export const useCouponForm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { couponId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const { data: eventsData, isLoading: isGettingEvents } = useQuery(
    'events',
    getEvents,
    {
      select: ({ data }: { data: IEventsRoot }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data } = useQuery(
    ['get-coupon', couponId],
    () => {
      if (couponId) {
        return getCoupon(couponId);
      }
    },
    {
      select: ({ data }): ICoupon => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const validationSchema = Yup.object().shape({
    amount: Yup.number().when('isPercent', {
      is: (value: boolean) => value,
      then: Yup.number()
        .min(0, t('yup.inputs.minPercent'))
        .max(100, t('yup.inputs.maxPercent'))
        .required(t('yup.inputs.precent')),
      otherwise: Yup.number().required(t('yup.inputs.amount')),
    }),
    expirationTime: Yup.string().required(t('yup.inputs.endDate')),
    events: Yup.array().required(t('yup.inputs.events')),
    couponCode: Yup.string()
      .trim()
      .test('no-spaces', t('yup.spacesNotAllowed'), (value) => {
        const stringWithoutSpaces = value.replace(/[.\s]+/g, '');
        return stringWithoutSpaces === value;
      })
      .required(t('yup.inputs.couponCode'))
      .min(10, t('yup.couponCode.min')),
  });

  const formik = useFormik({
    initialValues: {
      type: data?.type || 'event',
      allEvents: (!data && true) || data?.events.length === 0 ? true : false,
      events: data?.events || [],
      isPercent: data?.isPercent || false,
      isRent: data?.isRent || false,
      expirationTime: data?.expirationTime || '',
      amount: data?.amount || '',
      couponCode: '',
      isEditCuponCode: null,
      getCouponCode: false,
      description: data?.description || '',
    },
    onSubmit: (values) => {
      if (values) {
        const couponBody = couponMapper(values);

        if (couponId) {
          editCouponMutate({ couponId, data: couponBody });
        } else {
          mutate(couponBody);
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { data: getCouponCodeData, isLoading: isGettingCouponCode } = useQuery(
    'get-coupon-code',
    getCouponCode,
    {
      enabled: formik.values.getCouponCode,
      select: ({ data }): ICoupon => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  useEffect(() => {
    if (getCouponCodeData) {
      const { couponCode } = getCouponCodeData;

      formik.setFieldValue('couponCode', couponCode);
      formik.setFieldValue('getCouponCode', false);
    }
  }, [getCouponCodeData]);

  const { mutate, isLoading: isAddingCoupon } = useMutation(
    'add-coupon',
    createCoupon,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const { mutate: editCouponMutate, isLoading: isEditingCoupon } = useMutation(
    'edit-coupon',
    editCoupon,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  useEffect(() => {
    if (formik.values.allEvents) {
      formik.setFieldValue('events', []);
    }
  }, [formik.values.allEvents]);

  useEffect(() => {
    if (formik.values.type !== 'event') {
      formik.setFieldValue('events', []);
      formik.setFieldValue('allEvents', true);
    }
  }, [formik.values.type]);

  return {
    data,
    eventsData,
    formik,
    isAddingCoupon,
    isGettingEvents,
    isEditingCoupon,
    isGettingCouponCode,
  };
};
