import { AddTag } from '../pages/add-tag';
import { EditTag } from '../pages/edit-tag';
import { TagDetails } from '../pages/tag-details';
import { Tags } from '../pages/tags';

const tagsRouter = {
  path: 'tags',
  children: [
    {
      path: '',
      element: <Tags />,
      children: [],
    },
    {
      path: ':tagId',
      element: <TagDetails />,
    },
    {
      path: 'add-tag',
      element: <AddTag />,
    },
    {
      path: 'edit/:tagId',
      element: <EditTag />,
    },
  ],
};

export default tagsRouter;
