import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { TaskDetailsForm } from '../components/task-details-form';
import { useTask } from '../hooks/use-task';

export const TaskDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useTask();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tasks.title'),
      href: '/dashboard/tasks',
    },
    {
      label: data?.title ? data?.title : '',
      href: `/dashboard/tasks/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('tasks.taskName')}: ${data?.title ? data?.title : ''}`}
    >
      <Helmet>
        <title>{`${t('tasks.taskName')} | ${
          data?.title ? data?.title : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <TaskDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
