import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryError } from 'src/shared/utils/query-error';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AlertContext } from 'src/shared/contexts/Alert';
import { toggleTask } from '../services';
import { useNavigate, useParams } from 'react-router-dom';
import { getTinyUsers } from 'src/modules/users/services';
import { TTinyUsersRoot } from 'src/modules/users/types';

export const useTaskModal = () => {
  const { t } = useTranslation();

  const { taskId } = useParams();

  const navigate = useNavigate();

  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    executedBy: Yup.string().required(t('yup.inputs.person')),
  });

  const formik = useFormik({
    initialValues: {
      executedBy: '',
    },
    onSubmit: (values) => {
      mutate({
        taskId,
        executedBy: values.executedBy,
      });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { data } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading: isUpdating } = useMutation(
    'toggle-task-modal',
    toggleTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.refetchQueries('get-event');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    data,
    formik,
    isUpdating,
    navigate,
  };
};
