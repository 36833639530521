import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICertificate } from '../types';

export const CertificateDetailsForm = ({ data }: { data: ICertificate }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.certificateCode')}: {data?.code}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.user')}: {data?.user.fullName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.createdBy')}: {data?.createdBy.fullName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.description')}: {data?.description}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
