import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';
import { addTag } from '../services';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export const useAddTag = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(t('yup.inputs.tag')),
    description: Yup.string().required(t('yup.inputs.description')),
  });

  const formik = useFormik({
    initialValues: {
      label: '',
      description: '',
    },
    onSubmit: (values) => {
      addNewTag(values);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate: addNewTag } = useMutation('add-tag', addTag, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return { formik };
};
