import { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { updateUserPermissions } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import { getPermissions } from 'src/modules/permissions/services';
import { queryError } from 'src/shared/utils/query-error';
import { getUser } from 'src/modules/users/services';
import { IUser } from 'src/modules/users/types';
import { TPermissionsRoot } from 'src/modules/permissions/types';

export const useUpdateUserPermissions = () => {
  const { handleMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const { userId } = useParams();

  const { data: userData } = useQuery(
    ['get-user', userId],
    () => {
      if (userId) {
        return getUser(userId);
      }
    },
    {
      select: ({ data }): IUser => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data } = useQuery('get-permissions', getPermissions, {
    select: ({ data }: { data: TPermissionsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading } = useMutation(
    'update-user-permissions',
    updateUserPermissions,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      permissions: userData?.permissions || [],
    },
    onSubmit: (values) => {
      mutate({ userId, data: values });
    },
    enableReinitialize: true,
  });

  return {
    data,
    formik,
    isLoading,
  };
};
