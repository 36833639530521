import { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { addAmount } from '../services';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const useAddAmount = () => {
  const { t } = useTranslation();

  const { participantId, eventId } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { handleMessage } = useContext(AlertContext);

  const validationAmount = Yup.object().shape({
    amount: Yup.string().required(t('yup.inputs.amount')),
  });

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    onSubmit: (values) => {
      if (values.amount.trim() !== '') {
        addNewAmount({
          eventId: eventId,
          userId: participantId,
          amount: values.amount,
        });
      }
    },
    validationSchema: validationAmount,
    enableReinitialize: true,
  });

  const { mutate: addNewAmount, isLoading } = useMutation(
    'add-amount',
    addAmount,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
          queryClient.invalidateQueries('get-event');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    formik,
    isLoading,
  };
};
