import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingCircle = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ height: '66px' }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingCircle;
