import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

function InventorySearch() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: () => {},
  });

  let name = formik.values.name;

  const triggerSearch = useCallback(function (name) {
    if (name.trim() !== '') {
      searchParams.set('name', name);
    } else {
      searchParams.delete('name');
    }
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(name);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [name]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('inputs.labels.name')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('name')}
        />
      </Grid>
    </Grid>
  );
}

export default InventorySearch;
