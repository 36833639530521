import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Learning from 'src/content/dashboards/Learning';
import eventsRouter from 'src/modules/events/router';
import usersRouter from 'src/modules/users/router';
import permissionsRouter from 'src/modules/permissions/router';
import tagsRouter from 'src/modules/tags/router';
import transactionsRouter from 'src/modules/transactions/router';
import actionsRouter from 'src/modules/actions/router';
import tasksRouter from 'src/modules/tasks/router';
import categoriesRouter from 'src/modules/categories/router';
import toursRouter from 'src/modules/tours/router';
import equipmentsRouter from 'src/modules/equipments/router';
import rentalsRouter from 'src/modules/rentals/router';
import certificatesRouter from 'src/modules/certificates/router';
import repeatingTransactionsRouter from 'src/modules/repeating-transactions/router';
import managePermissionsRouter from 'src/modules/manage-permissions/router';
import couponsRouter from 'src/modules/coupons/router';
import addTransactionsRouter from 'src/modules/transactions/add-transaction/router';
import addExpenseRouter from 'src/modules/transactions/add-expense/router';
import additionalInfosRouter from 'src/modules/additional-infos/router';
import servicesRouter from 'src/modules/services/router';
import manageTagsRouter from 'src/modules/manage-tags/router';
import inventoriesRouter from 'src/modules/inventories/router';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics')),
);

const Students = Loader(lazy(() => import('src/content/dashboards/Students')));
const ProfilePage = Loader(
  lazy(() => import('src/modules/profile/pages/profile')),
);

const dashboardsRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard/events" />,
  },
  {
    path: '',
    element: <Analytics />,
  },
  usersRouter,
  eventsRouter,
  permissionsRouter,
  managePermissionsRouter,
  manageTagsRouter,
  tagsRouter,
  actionsRouter,
  rentalsRouter,
  inventoriesRouter,
  transactionsRouter,
  addTransactionsRouter,
  addExpenseRouter,
  certificatesRouter,
  tasksRouter,
  repeatingTransactionsRouter,
  categoriesRouter,
  toursRouter,
  equipmentsRouter,
  couponsRouter,
  additionalInfosRouter,
  servicesRouter,
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: '/dashboard/learning',
    element: <Learning />,
  },
];

export default dashboardsRoutes;
