import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';

import { useTranslation } from 'react-i18next';
import { createCertificate } from '../services';
import { getTinyUsers } from 'src/modules/users/services';
import { queryError } from 'src/shared/utils/query-error';
import { TTinyUsersRoot } from 'src/modules/users/types';

export const useAddCertificate = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    user: Yup.string().required(t('yup.inputs.name')),
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      description: '',
      startDate: '',
      finishDate: '',
    },
    onSubmit: (values) => {
      if (values) {
        mutate(values);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      return data.data;
    },
    onError: queryError,
  });

  const { mutate, isLoading } = useMutation(
    'add-certificate',
    createCertificate,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    usersData,
    formik,
    isLoading,
  };
};
