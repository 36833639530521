import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IOptions } from 'src/shared/components/generic-table/types';
import { CouponsTableActionButtons } from '../components/action-buttons';
import moment from 'moment';
import { DATA_TYPES } from 'src/shared/costants/constants';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Manat from 'src/assets/icon/manat';
import Percent from 'src/assets/icon/precent';
import { ICoupon } from '../types';

export const useCouponsTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('coupons.title'),
      href: '/dashboard/coupons',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: ICoupon) => item.code,
      },
      {
        id: 'amount',
        align: 'left',
        label: t('table.amount'),
        extructor: (item: ICoupon) => (
          <Typography display={'flex'} alignItems={'center'} gap={0.5}>
            {item.amount} {item.isPercent ? <Percent /> : <Manat />}
          </Typography>
        ),
      },
      {
        id: 'isRent',
        align: 'left',
        label: t('table.isRent'),
        extructor: (item: ICoupon) =>
          item.isRent && <CheckBoxIcon color="success" />,
      },
      {
        id: 'expirationTime',
        align: 'center',
        label: t('table.endDate'),
        extructor: (item: ICoupon) =>
          moment(item.expirationTime).format(DATA_TYPES.mdy),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: ICoupon) => {
          return <CouponsTableActionButtons couponId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
