import { Link as RouterLink } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Button } from '@mui/material';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import { usePermissionsTable } from '../hooks/use-permissions-table';
import { usePermissions } from '../hooks/use-permissions';
import { useTranslation } from 'react-i18next';

export const PermissionsContainer = () => {
  const { data } = usePermissions();

  const { t } = useTranslation();

  const [columns, options, routes] = usePermissionsTable();

  return (
    <PageWrapper
      routes={routes}
      title={t('permissions.title')}
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/permissions/add-permission"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addPermission')}
        </Button>
      }
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
