import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  CardMedia,
  Tooltip,
} from '@mui/material';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAddInventory } from '../hooks/use-add-inventory';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { AddInventoryImgesModal } from './add-inventory-images-modal';
import FileResizer from 'react-image-file-resizer';
import { useAddFileUpload } from '../hooks/use-add-file-upload';
import { deleteFileUpload } from '../services';
import { useDeleteFileUpload } from '../hooks/use-delete-file-upload';

export const AddInventoryForm = function () {
  const { addFileUploadMutate, isUpdatingFile, imagesArr, setImagesArr } =
    useAddFileUpload();
  const { deleteFileUploadMutate } = useDeleteFileUpload();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openInventoryImagesModal, setOpenInventoryImagesModal] =
    useState(false);
  const [selectedInventoryIndex, setSelectedInventoryIndex] = useState(0);

  const addUploadImage = (e: any) => {
    const image = e.target.files[0];

    FileResizer.imageFileResizer(
      image,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        addFileUploadMutate({ fileStr: uri, folder: 'inventories' });
      },
      'base64',
      200,
      200,
    );
  };

  // const deleteUploadImage = (e: any) => {
  //   const image = e.target.files[0];

  //   FileResizer.imageFileResizer(
  //     image,
  //     300,
  //     300,
  //     'JPEG',
  //     100,
  //     0,
  //     (uri) => {
  //       deleteFileUploadMutate({ fileStr: uri });
  //     },
  //     'base64',
  //     200,
  //     200,
  //   );
  // };

  const { t } = useTranslation();
  const {
    formik,
    isAddingInventory,
    images,
    setImages,
    imageName,
    setImageName,
  } = useAddInventory();
  const conditions = [
    {
      value: 'good',
      name: t('options.bad'),
    },
    {
      value: 'bad',
      name: t('options.good'),
    },
    {
      value: 'great',
      name: t('options.great'),
    },
  ];

  const handlePrev = () => {
    setSelectedInventoryIndex((prevIndex) =>
      prevIndex === 0 ? formik.values.images.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setSelectedInventoryIndex((prevIndex) =>
      prevIndex === formik.values.images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handleImageClick = (i) => {
    setIsModalOpen(true);
    setSelectedInventoryIndex(i);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleRemove = (index) => {
    const updatedImages = imagesArr.filter((image, i) => {
      if (i !== index) {
        return i !== index;
      }
      return deleteFileUploadMutate(image.public_id);
    });
    setImagesArr(updatedImages);

    formik.setFieldValue('images', updatedImages);
  };

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.model')}
              name="model"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.brand')}
              name="brand"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.type')}
              name="type"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.color')}
              name="color"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmSelect
              formik={formik}
              label={t('inputs.labels.condition')}
              name="condition"
              options={conditions || []}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.size')}
              name="size"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.description')}
              name="description"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={t('inputs.labels.forSell')}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue('forSell', e.target.checked)
                  }
                  checked={formik.values.forSell}
                />
              }
            />
            <FormControlLabel
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
              label={t('inputs.labels.forRent')}
              control={
                <Checkbox
                  onChange={(e) =>
                    formik.setFieldValue('forRent', e.target.checked)
                  }
                  checked={formik.values.forRent}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              justifyContent={{
                xs: 'center',
                sx: 'flex-start',
                md: 'flex-start',
              }}
              gap={2}
              sx={{ width: '100%' }}
            >
              {imagesArr.map((image, i) => (
                <Box
                  key={i}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Card
                    sx={{
                      maxWidth: 170,
                      minWidth: 170,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <CardMedia
                      onClick={() => handleImageClick(i)}
                      sx={{ height: 120, cursor: 'pointer' }}
                      image={image.url}
                    />
                  </Card>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                      cursor: 'pointer',
                      background: '#fff',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                    }}
                  >
                    <CancelIcon color="error" onClick={() => handleRemove(i)} />
                  </Box>
                </Box>
              ))}
              <Tooltip arrow title={t('inventories.addImage.tooltip')}>
                <Card
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                    width: '100%',
                    height: '120px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenInventoryImagesModal(true)}
                >
                  <AddIcon sx={{ fontSize: '80px' }} color="secondary" />
                </Card>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <LoadingButton
            type="submit"
            loading={isAddingInventory}
            variant="contained"
            fullWidth
            color="primary"
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {t('buttons.addInventory')}
          </LoadingButton>
        </Grid>
      </Box>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        overlayClassName="custom-overlay-class"
      >
        {/* {formik.values.images.length > 1 && (
          <>
            <button className="prev" onClick={handlePrev}>
              <BsChevronLeft />
            </button>
            <button className="next" onClick={handleNext}>
              <BsChevronRight />
            </button>
          </>
        )} */}
        {/* <img src={formik.values.images[selectedInventoryIndex]} /> */}
      </Modal>
      <AddInventoryImgesModal
        // deleteUploadImage={deleteUploadImage}
        formik={formik}
        addUploadImage={addUploadImage}
        openInventoryImagesModal={openInventoryImagesModal}
        setOpenInventoryImagesModal={setOpenInventoryImagesModal}
        images={images}
        // setImages={setImages}
        imageName={imageName}
        setImageName={setImageName}
      />
    </>
  );
};
