import {
  Grid,
  Box,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import Manat from 'src/assets/icon/manat';
import { ITinyUser } from 'src/modules/users/types';
import { IEvent } from 'src/modules/events/types';

export const TransactionForm = ({
  data,
  eventsData,
  formik,
  isAddingTransaction,
  isEditingTransaction,
  usersData,
}) => {
  const { t } = useTranslation();

  const categoryData = [
    {
      value: 'other',
      name: t('other'),
    },
  ];

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.name')}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.amount')}
              name="amount"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              endAdornment={
                <InputAdornment position="end">
                  <Manat />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <OmSelect
              formik={formik}
              label={t('inputs.labels.category')}
              name="category"
              options={categoryData}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth={true}
              options={usersData || []}
              disablePortal
              value={
                formik.values.spender
                  ? usersData?.find(
                      (user: ITinyUser) => user.id === formik.values.spender,
                    )
                  : null
              }
              onChange={(_e, newValue: ITinyUser) => {
                formik.setFieldValue('spender', newValue ? newValue.id : '');
              }}
              getOptionLabel={(option: ITinyUser) => {
                return `${option.fullName} (${option.code})`;
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.labels.spender')}
                />
              )}
            />
            {!!formik.errors.spender && formik.touched.spender && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943',
                }}
              >
                {formik.errors.spender}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth={true}
              options={eventsData || []}
              disablePortal
              value={
                formik.values.relatedEvent
                  ? eventsData?.find(
                      (event: IEvent) =>
                        event.id === formik.values.relatedEvent,
                    )
                  : null
              }
              onChange={(_e, newValue: IEvent) => {
                formik.setFieldValue(
                  'relatedEvent',
                  newValue ? newValue.id : '',
                );
              }}
              getOptionLabel={(option: IEvent) => {
                return `${option.title} ${option.id}`;
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.labels.relatedEvent')}
                />
              )}
            />
            {!!formik.errors.relatedEvent && formik.touched.relatedEvent && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: '#FF1943',
                }}
              >
                {formik.errors.relatedEvent}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.description')}
              name="description"
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <SubmitButton
              load={isAddingTransaction || isEditingTransaction}
              sx={{ width: { xs: '100%', md: 200 }, mt: 2 }}
            >
              {data ? t('buttons.edit') : t('buttons.add')}
            </SubmitButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
