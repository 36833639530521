import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addCategory } from '../services';

export const useAddCategory = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.inputs.category')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      type: 'income',
    },
    onSubmit: (values) => {
      mutate(values);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading } = useMutation('add-category', addCategory, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return { formik, isLoading };
};
