import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  styled,
} from '@mui/material';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import Header from './header';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`,
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`,
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
    z-index: 5;
`,
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`,
);

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`,
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`,
);

// const graduatesData = [
//   {
//     id: 1,
//     fullname: 'Şəbnəm Süleymanlı',
//     image:
//       'https://www.timeacademy.az/static/ab729dda4ecea27a46c2eeb10c9c8942/e557c/shabnam_suleymanli_alumni.webp',
//     result: '8.0',
//     link: 'https://www.timeacademy.az/alumni',
//     styles: {
//       width: 80,
//       height: 80,
//       left: 0,
//       top: 40,
//     },
//   },
//   {
//     id: 2,
//     fullname: 'Şahin Quliyev',
//     image:
//       'https://www.timeacademy.az/static/e2c0dc6e4c5d4803f40c548a6a979e4d/e557c/shahin_quliyev_alumni.webp',
//     result: '7.5',
//     link: 'https://www.timeacademy.az/alumni',
//     styles: {
//       width: 90,
//       height: 90,
//       left: 80,
//       top: 0,
//     },
//   },
//   {
//     id: 3,
//     fullname: 'Çinarə Kazımlı',
//     image:
//       'https://www.timeacademy.az/static/fdd1a6a313b2458e8554be39de429404/e9589/chinara_kazimli.webp',
//     result: '7.0',
//     link: 'https://www.timeacademy.az/alumni',
//     styles: {
//       width: 110,
//       height: 110,
//       top: -162,
//       left: 170,
//     },
//   },
//   {
//     id: 4,
//     fullname: 'Namiq Məmmədhüseyn',
//     image:
//       'https://www.timeacademy.az/static/5db6722f8dc73524b9a361d2bf0bcb9f/e9589/namiq_mammadhuseyn_alumni.webp',
//     result: '7.0',
//     link: 'https://www.timeacademy.az/alumni',
//     styles: {
//       width: 70,
//       height: 70,
//       top: -162,
//       left: 259,
//     },
//   },
// ];

function LoginCover() {
  const { method } = useAuth() as any;
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <Helmet>
        <title>Login - Cover</title>
      </Helmet>
      <Header />
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Box mt={6}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('tours.title')}
                </TypographyH1>
                {/* <Slider {...settings}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: 300,
                      height: 200
                    }}
                  >
                    {graduatesData.map(
                      ({ id, fullname, image, result, link, styles }) => (
                        <Tooltip
                          key={id}
                          arrow
                          placement="top"
                          title={fullname}
                        >
                          <Box
                            sx={{
                              ...styles,
                              position: 'relative'
                            }}
                          >
                            <CardImg
                              sx={{
                                width: '100%',
                                height: '100%'
                              }}
                            >
                              <a href={link} target="_blank" rel="noreferrer">
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                  }}
                                  alt="Şəbnəm Süleymanlı"
                                  src={image}
                                />
                              </a>
                            </CardImg>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                position: 'absolute',
                                bgcolor: '#62c92b',
                                color: 'white',
                                bottom: 0,
                                right: 0,
                                fontSize: '11px',
                                px: '6px',
                                py: '1.5px',
                                borderRadius: 1
                              }}
                            >
                              {result}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      width: 300,
                      height: 200
                    }}
                  >
                    {graduatesData.map(
                      ({ id, fullname, image, result, link, styles }) => (
                        <Tooltip
                          key={id}
                          arrow
                          placement="top"
                          title={fullname}
                        >
                          <Box
                            sx={{
                              ...styles,
                              position: 'relative'
                            }}
                          >
                            <CardImg
                              sx={{
                                width: '100%',
                                height: '100%'
                              }}
                            >
                              <a href={link} target="_blank" rel="noreferrer">
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                  }}
                                  alt="Şəbnəm Süleymanlı"
                                  src={image}
                                />
                              </a>
                            </CardImg>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                position: 'absolute',
                                bgcolor: '#62c92b',
                                color: 'white',
                                bottom: 0,
                                right: 0,
                                fontSize: '11px',
                                px: '6px',
                                py: '1.5px',
                                borderRadius: 1
                              }}
                            >
                              {result}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )
                    )}
                  </Box>
                </Slider> */}
                {/* <Typography
                  variant="subtitle1"
                  sx={{
                    my: 3,
                  }}
                >
                  {t(
                    'Our graduates continue their studies in prestigious universities of different countries of the world.',
                  )}
                </Typography> */}
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4,
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t('login.signIn')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  {t('login.info')}
                </Typography>
              </Box>
              {method === 'JWT' && <JWTLogin />}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
