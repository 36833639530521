import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getEvents } from '../services';
import { IEventsRoot } from '../types';

export const useEvents = () => {
  const { data } = useQuery('events', getEvents, {
    select: ({ data }: { data: IEventsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
