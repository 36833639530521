export default function Precent() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 300 200"
      >
        <path
          d="M204.8 129.172c-8.633 0-15.43 3.671-20.391 11.014-4.862 7.343-7.293 17.563-7.293 30.66 0 12.899 2.431 23.07 7.293 30.512 4.961 7.343 11.758 11.014 20.391 11.014 8.434 0 15.082-3.671 19.944-11.014 4.961-7.442 7.442-17.612 7.442-30.512 0-12.998-2.481-23.169-7.442-30.512-4.862-7.442-11.51-11.163-19.944-11.163m0-18.902c15.677 0 28.13 5.457 37.358 16.372S256 152.291 256 170.846s-4.664 33.29-13.991 44.205c-9.228 10.816-21.631 16.223-37.209 16.223-15.876 0-28.428-5.408-37.656-16.223-9.228-10.915-13.842-25.65-13.842-44.205 0-18.654 4.614-33.389 13.842-44.205 9.327-10.915 21.879-16.372 37.656-16.372M51.2 19.628c-8.533 0-15.281 3.721-20.242 11.163-4.862 7.343-7.293 17.464-7.293 30.363 0 13.098 2.431 23.318 7.293 30.66s11.609 11.014 20.242 11.014 15.38-3.671 20.242-11.014c4.961-7.342 7.442-17.563 7.442-30.66 0-12.8-2.481-22.921-7.442-30.363S59.734 19.628 51.2 19.628M185.6.725h23.814L70.4 231.274H46.586L185.6.725m-134.4 0c15.677 0 28.18 5.458 37.507 16.372 9.327 10.816 13.991 25.501 13.991 44.056 0 18.754-4.664 33.538-13.991 44.353-9.228 10.816-21.73 16.223-37.507 16.223s-28.279-5.408-37.507-16.223C4.565 94.592 0 79.808 0 61.153c0-18.456 4.614-33.141 13.842-44.056S35.523.726 51.2.725"
          fill="rgba(0, 0, 0, 0.6)"
        />
      </svg>
    </>
  );
}
