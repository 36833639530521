import { useContext } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useTranslation } from 'react-i18next';
import { useDeleteTask } from '../hooks/use-delete-task';

export const TasksButtons = ({ taskId }) => {
  const { dispatch } = useContext(ConfirmationModalContext);
  const { mutate } = useDeleteTask();
  const { t } = useTranslation();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('actionButtons.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/tasks/${taskId}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.edit')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/tasks/edit/${taskId}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.delete')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    mutate(taskId);
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: 'modal.delete',
                  isOpen: true,
                  buttonMessage: 'actionButtons.delete',
                  color: 'error',
                },
              });
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
