import { Typography, Switch } from '@mui/material';
import { ITag } from 'src/modules/tags/types';

export const ManageTagsActionButtons = ({
  tag,
  formik,
}: {
  tag: ITag;
  formik: any;
}) => {
  const handleToggleTag = () => {
    let { tags } = formik.values;

    if (tags.includes(tag.label)) {
      tags = tags.filter((tagStr: string) => {
        return tagStr !== tag.label;
      });
    } else {
      tags = [...tags, tag.label];
    }

    formik.setFieldValue('tags', tags);
  };

  const isChecked = formik.values.tags?.includes(tag.label);

  return (
    <Typography noWrap>
      <Switch checked={isChecked} onChange={handleToggleTag} />
    </Typography>
  );
};
