import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
import type { Student } from 'src/models/student';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: Student | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  can: (permissionCode: string) => boolean;
  updateImage: (profileImage) => void;
  register: (
    name: string,
    surname: string,
    fatherName: string,
    email: string,
    passwordConfirm: string,
    password: string,
    dateOfBirth: string,
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: Student | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: Student;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: Student;
  };
};

type UpdateImageAction = {
  type: 'UPDATE_IMAGE';
  payload: {
    profileImage: string;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateImageAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const setSession = (token: string | null): void => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE_IMAGE: (state: AuthState, action: UpdateImageAction): AuthState => ({
    ...state,
    user: {
      ...state.user,
      profileImage: action.payload.profileImage,
    },
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  can: () => true,
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateImage: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const token = window.localStorage.getItem('token');

        if (token) {
          setSession(token);

          const response = await axios.get<{ user: Student }>(
            '/api/v1/auth/get-user',
          );
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await axios.post<{ token: string; user: Student }>(
      '/api/v1/auth/login',
      {
        email,
        password,
      },
    );

    const { token, user } = response.data;

    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateImage = (profileImage: string) => {
    dispatch({
      type: 'UPDATE_IMAGE',
      payload: {
        profileImage,
      },
    });
  };

  const register = async (
    name: string,
    surname: string,
    fatherName: string,
    email: string,
    passwordConfirm: string,
    password: string,
    dateOfBirth: string,
  ): Promise<void> => {
    const response = await axios.post<{ token: string; user: Student }>(
      '/api/v1/auth/register',
      {
        name,
        surname,
        fatherName,
        email,
        passwordConfirm,
        password,
        dateOfBirth,
      },
    );
    const { token, user } = response.data;

    window.localStorage.setItem('token', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  function can(permissionCode) {
    return state.user.permissions.includes(permissionCode);
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        updateImage,
        can,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
