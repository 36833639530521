import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useCategories } from '../hooks/use-categories';
import { useCategoriesTable } from '../hooks/use-categories-table';
import GenericTable from 'src/shared/components/generic-table';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const CategoriesContainer = () => {
  const { t } = useTranslation();

  const { data } = useCategories();

  const [columns, options, routes] = useCategoriesTable();

  return (
    <PageWrapper
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/categories/add-category"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addCategory')}
        </Button>
      }
      routes={routes}
      title={t('categories.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
