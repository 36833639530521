import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddCategoryForm } from '../components/add-category-form';

export const AddCategoryContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('categories.title'),
      href: '/dashboard/categories',
    },
    {
      label: t('categories.addCategory'),
      href: '/dashboard/categories/add-category',
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('categories.addCategory')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddCategoryForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
