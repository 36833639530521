import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import {
  createAdditionalInfo,
  editAdditionalInfo,
  getAdditionalInfo,
} from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { IAdditionalInfo } from '../types';

export const useAdditionalInfoForm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { additionalInfoId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const { data } = useQuery(
    ['get-additional-info', additionalInfoId],
    () => {
      if (additionalInfoId) {
        return getAdditionalInfo(additionalInfoId);
      }
    },
    {
      select: ({ data }): IAdditionalInfo => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const validationSchema = Yup.object().shape({
    text: Yup.string().required(t('yup.inputs.name')),
  });

  const formik = useFormik({
    initialValues: {
      text: data?.text || '',
      description: data?.description || '',
    },
    onSubmit: (values) => {
      if (values) {
        if (additionalInfoId) {
          editAdditionalInfoMutate({ additionalInfoId, data: values });
        } else {
          mutate(values);
        }
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading: isAddingAdditionalInfo } = useMutation(
    'add-additional-info',
    createAdditionalInfo,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const {
    mutate: editAdditionalInfoMutate,
    isLoading: isEditingAdditionalInfo,
  } = useMutation('edit-additional-info', editAdditionalInfo, {
    onSuccess: (e: any) => {
      if (e.status === 200) {
        handleMessage(true, 'Process was successful', 'success');
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return {
    data,
    formik,
    isAddingAdditionalInfo,
    isEditingAdditionalInfo,
  };
};
