import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Manat from 'src/assets/icon/manat';
import { IRepeatingTransaction } from '../types';

export const RepeatingTransformDetailsForm = ({
  data,
}: {
  data: IRepeatingTransaction;
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.name')}: {data?.title}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.createdBy')}: {data?.createdBy.fullName}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.category')}: {data?.category}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.recipient')}: {data?.recipient.fullName}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.spender')}: {data?.spender.fullName}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {t('inputs.labels.amount')}: {data?.amount}
              <Manat />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.paymentMethod')}: {data?.paymentMethod}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.period')}: {data?.period}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.description')}: {data?.description}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
