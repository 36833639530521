import { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useTranslation } from 'react-i18next';
import { getTinyUsers } from 'src/modules/users/services';
import { queryError } from 'src/shared/utils/query-error';
import { getEvents } from 'src/modules/events/services';
import { IEventsRoot } from 'src/modules/events/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addTransaction, editTransaction, getTransaction } from '../services';
import { addTransactionMapper } from '../mappers';
import { TTinyUsersRoot } from 'src/modules/users/types';
import { ITransaction } from '../types';

export const useTransactionForm = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const { transactionId } = useParams();

  const transactionType =
    (location.pathname.includes('add-expense') && 'expense') ||
    (location.pathname.includes('add-transaction') && 'income');

  const { handleMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: eventsData } = useQuery('events', getEvents, {
    select: ({ data }: { data: IEventsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data } = useQuery(
    ['get-transaction', transactionId],
    () => {
      if (transactionId) {
        return getTransaction(transactionId);
      }
    },
    {
      select: ({ data }): ITransaction => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const validationAddTransaction = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.name')),
    amount: Yup.number().required(t('yup.inputs.amount')),
    relatedEvent: Yup.string().required(t('yup.inputs.relatedEvent')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      amount: data?.amount || '',
      category: data?.category || 'other',
      description: data?.description || '',
      spender: data?.spender.id || '',
      relatedEvent: (data?.relatedEvent && data?.relatedEvent.id) || '',
    },
    onSubmit: (values) => {
      if (values) {
        const addTransactionBody = addTransactionMapper({
          values,
          transactionType,
          data,
        });

        if (transactionId) {
          editTransactionMutate({ transactionId, data: addTransactionBody });
        } else {
          mutate(addTransactionBody);
        }
      }
    },
    validationSchema: validationAddTransaction,
    enableReinitialize: true,
  });

  const { mutate, isLoading: isAddingTransaction } = useMutation(
    'add-transaction',
    addTransaction,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate('/dashboard/transactions');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const { mutate: editTransactionMutate, isLoading: isEditingTransaction } =
    useMutation('edit-transaction', editTransaction, {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    });

  return {
    data,
    formik,
    usersData,
    eventsData,
    isAddingTransaction,
    isEditingTransaction,
  };
};
