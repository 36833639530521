import { Box, Grid } from '@mui/material';
import { OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddAction } from '../hooks/use-add-action';

export const AddActionForm = () => {
  const { t } = useTranslation();

  const { formik, isLoading } = useAddAction();

  const typeData = [
    {
      value: 'event',
      name: t('event.title'),
    },
    {
      value: 'participant',
      name: t('events.participant.title'),
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.beforeEventDays')}
            name="beforeEventDays"
            type="number"
            onWheel={(e: any) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.type')}
            name="relatedTo"
            options={typeData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.labels.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <SubmitButton load={isLoading} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.add')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
