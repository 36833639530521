import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addTask } from 'src/modules/tasks/services';
import { queryError } from 'src/shared/utils/query-error';
import { addNewTaskForParticipantsMapper } from '../mappers';
import { getTinyUsers } from 'src/modules/users/services';
import { getEvent } from '../services';
import { getActions } from 'src/modules/actions/services';
import { TTinyUsersRoot } from 'src/modules/users/types';
import { IEvent, IEventParticipant } from '../types';
import { ITask } from 'src/modules/tasks/types';
import { TActionsRoot } from 'src/modules/actions/types';

export const useAddNewTask = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eventId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    assignedTo: Yup.string().when('title', {
      is: (value) => value && value.length > 0,
      then: Yup.string().required(t('yup.inputs.responsiblePersonRequired')),
      otherwise: Yup.string(),
    }),
    deadline: Yup.string().when('title', {
      is: (value) => value && value.length > 0,
      then: Yup.string().required(t('yup.inputs.endDate')),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      event: eventId || '',
      deadline: '',
      assignedTo: '',
      participants: [],
      tasks: [],
    },
    onSubmit: (values) => {
      const hasTitle = values.title.length > 0;
      const hasTasks = values.tasks.length > 0;

      if (hasTitle || hasTasks) {
        const updatedTasks = hasTitle
          ? [
              ...values.tasks,
              {
                title: values.title,
                assignedTo: values.assignedTo,
                deadline: values.deadline,
              },
            ]
          : values.tasks.map((task: ITask) => ({
              ...task,
              deadline: values.deadline,
            }));

        const addNewTaskForParticipantsBody = addNewTaskForParticipantsMapper({
          ...values,
          tasks: updatedTasks,
        });

        mutate(addNewTaskForParticipantsBody);
      } else {
        handleMessage(true, 'Add a new task', 'error');
      }
    },
    validationSchema,
    enableReinitialize: true,
  });

  const { data } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: eventData } = useQuery(
    ['get-event', eventId],
    () => {
      return getEvent(eventId);
    },
    {
      select: ({ data }): IEvent => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data: actionsData } = useQuery('get-actions', getActions, {
    select: ({ data }: { data: TActionsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading } = useMutation(
    'add-new-task-to-participants',
    addTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.refetchQueries('get-event');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  useEffect(() => {
    if (eventData) {
      const participantIds = eventData.participants.map(
        (participant: IEventParticipant) => participant.id,
      );

      formik.setFieldValue('participants', [...participantIds]);
    }
  }, [eventData]);

  return { formik, data, actionsData, eventId, isLoading };
};
