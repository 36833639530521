import React, { useEffect, useState } from 'react';
import cuid from 'cuid';
import type { FC, ChangeEvent } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

import {
  Box,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LoadingCircle from '../Loading-circle';

import TableHeader from './components/table-header';
import { IGenericTableColumns, IOptions } from './types';
import BulkActions from 'src/content/management/Users/BulkActions';

interface GenericTableProps {
  data: any[];
  columns: IGenericTableColumns;
  select?: boolean;
  loading?: boolean;
  checkBox?: boolean;
  search?: boolean;
  searchWith?: string | null;
  searchFilters?: string[];
  filterTitle?: string;
  options?: IOptions;
}
const applyFilters = (
  data: any[],
  query: string,
  selectedFilter: string,
  searchWith,
  filterTitle,
) => {
  if (selectedFilter === 'all') {
    if (query && searchWith) {
      return data.filter((datas) =>
        datas[searchWith].toLowerCase().includes(query.toLowerCase()),
      );
    }

    return data;
  } else {
    const newData = data.filter(
      (datas) => datas[filterTitle.toLowerCase()] === selectedFilter,
    );
    if (query && searchWith) {
      return newData.filter((datas) =>
        datas[searchWith].toLowerCase().includes(query.toLowerCase()),
      );
    }

    return newData;
  }
};

const applyPagination = (
  filteredData: any[],
  page: number,
  limit: number,
): any[] => {
  return filteredData?.slice(page * limit, page * limit + limit);
};

const GenericTable: FC<GenericTableProps> = ({
  data,
  columns,
  select = false,
  search = false,
  checkBox = false,
  searchWith = '',
  filterTitle = '',
  options = {
    defaultLimit: 10,
  },
}) => {
  const [selectedItems, setSelectedInvoices] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(options.defaultLimit);
  const [query, setQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<any>('all');

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllRow = (event: ChangeEvent<HTMLInputElement>): void => {
    if (query.trim().length > 0) {
      const filteredIds = filteredPaginateData(paginatedData);

      setSelectedInvoices(
        event.target.checked
          ? filteredIds.filter((id) => !selectedItems.includes(id))
          : selectedItems.filter((id) => !filteredIds.includes(id)),
      );
    } else {
      setSelectedInvoices(
        event.target.checked ? data?.map((invoice) => invoice.id) : [],
      );
    }
  };

  const filteredPaginateData = (paginatedData) => {
    if (paginatedData) {
      return paginatedData
        .filter((row) => {
          const searchTerm = query;
          const lowercaseTitle = row.title.toLowerCase();
          const lowercaseSearchTerm = searchTerm.toLowerCase();
          return (
            lowercaseTitle.includes(lowercaseSearchTerm) &&
            row.title.trim() !== ''
          );
        })
        .map((row) => row.id);
    }
  };

  const handleSelectOneInvoice = (invoiceId: string): void => {
    if (!selectedItems.includes(invoiceId)) {
      setSelectedInvoices((prevSelected) => [...prevSelected, invoiceId]);
    } else {
      setSelectedInvoices((prevSelected) =>
        prevSelected.filter((id) => id !== invoiceId),
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
    setSelectedInvoices([]);
  };

  const filteredData: any = applyFilters(
    data,
    query,
    selectedFilter,
    searchWith,
    filterTitle,
  );
  const paginatedData = applyPagination(filteredData, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeRow =
    selectedItems.length > 0 && selectedItems.length < data?.length;
  const selectedAllRow = selectedItems.length === data?.length;

  if (!data) {
    return (
      <Box
        sx={{
          minHeight: '600px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingCircle />
      </Box>
    );
  }

  const filteredPaginatedData = paginatedData.filter((row) => {
    const searchTerm = query.trim().toLowerCase();
    const lowercaseTitle = row.title?.toLowerCase();
    return lowercaseTitle?.includes(searchTerm) && row.title.trim() !== '';
  });

  return (
    <>
      {search && (
        <Box p={2}>
          {!selectedBulkActions && (
            <TextField
              sx={{
                m: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t('Search by name, email or username...')}
              value={query}
              size="small"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          )}
          {selectedBulkActions && (
            <BulkActions
              selectedItems={selectedItems}
              setQuery={setQuery}
              setSelectedInvoices={setSelectedInvoices}
            />
          )}
        </Box>
      )}

      <Divider />
      <TableHeader
        selectedAllRow={selectedAllRow}
        selectedSomeRow={selectedSomeRow}
        handleSelectAllRow={handleSelectAllRow}
        selectedBulkActions={selectedBulkActions}
        pagelength={data.length}
        selectedItems={selectedItems.length}
        select={select}
        search={search}
        handleSearch={handleSearch}
        searchWith={searchWith}
      />
      <Divider />
      {paginatedData.length !== 0 ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {checkBox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllRow}
                        indeterminate={selectedSomeRow}
                        onChange={handleSelectAllRow}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    return (
                      <TableCell
                        key={cuid()}
                        align={column.align}
                        padding="normal"
                        sx={{ textTransform: 'none' }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {search ? (
                  filteredPaginatedData?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography>{t('noResultFound')}</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredPaginatedData.map((row) => (
                      <TableRow
                        hover
                        key={cuid()}
                        selected={selectedItems.includes(row.id)}
                      >
                        {checkBox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedItems.includes(row.id)}
                              onChange={(event) =>
                                handleSelectOneInvoice(row.id)
                              }
                              value={selectedItems.includes(row.id)}
                            />
                          </TableCell>
                        )}
                        {columns.map(
                          ({
                            id,
                            align,
                            render,
                            extructor = (item) => item,
                          }) => (
                            <TableCell align={align} key={id}>
                              <Typography noWrap variant="subtitle2">
                                {render
                                  ? render(extructor(row))
                                  : extructor(row)}
                              </Typography>
                            </TableCell>
                          ),
                        )}
                      </TableRow>
                    ))
                  )
                ) : (
                  paginatedData.map((row) => (
                    <TableRow
                      hover
                      key={cuid()}
                      selected={selectedItems.includes(row.id)}
                    >
                      {checkBox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems.includes(row.id)}
                            onChange={(event) => handleSelectOneInvoice(row.id)}
                            value={selectedItems.includes(row.id)}
                          />
                        </TableCell>
                      )}
                      {columns.map(
                        ({ id, align, render, extructor = (item) => item }) => (
                          <TableCell align={align} key={id}>
                            <Typography noWrap variant="subtitle2">
                              {render ? render(extructor(row)) : extructor(row)}
                            </Typography>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={filteredData.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Box>
        </>
      ) : (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t('Data not found!')}
        </Typography>
      )}
    </>
  );
};

export default GenericTable;
