import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddTourForm } from '../components/add-tour-form';
import { useTranslation } from 'react-i18next';

export const AddTourContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tours.title'),
      href: '/dashboard/tours',
    },
    {
      label: t('tours.addTour'),
      href: '/dashboard/tours/new-event',
    },
  ];

  return (
    <>
      <PageWrapper routes={routes} title={t('tours.title')}>
        <Helmet>
          <title>{t('tours.addTour')}</title>
        </Helmet>
        <Grid container sx={{ p: 3 }}>
          <AddTourForm />
        </Grid>
      </PageWrapper>
    </>
  );
};
