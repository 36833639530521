import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getRepeatingTransactions = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/repeating-transactions`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getRepeatingTransaction = async (repeatingTransactionId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/repeating-transactions/${repeatingTransactionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addRepeatingTransaction = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/repeating-transactions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteRepeatingTransaction = async (repeatingTransactionId: string): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/repeating-transactions/${repeatingTransactionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editRepeatingTransaction = async ({ repeatingTransactionId, data }): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/repeating-transactions/${repeatingTransactionId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};