const azJSON = {
  users: {
    title: 'istifadəçilər',
    addUser: 'istifadəçi əlavə et',
  },
  user: {
    title: 'istifadəçi',
    addUser: 'istifadəçi əlavə et',
  },
  coupons: {
    title: 'kuponlar',
    addCoupon: 'kupon əlavə et',
    couponCode: 'kupon kodu',
  },
  certificates: {
    title: 'sertifikatlar',
    addCertificate: 'sertifikat əlavə et',
    certificateCode: 'sertifikat kodu',
  },
  equipments: {
    title: 'avadanlıqlar',
    addEquipment: 'avadanlıq əlavə et',
    equipmentName: 'avadanlıq adı',
  },
  inventories: {
    title: 'ehtiyatlar',
    addInventory: 'ehtiyat əlavə et',
    inventoryName: 'ehtiyat adı',
    addImage: {
      tooltip: 'şəkil əlavə et',
    },
  },
  rentals: {
    title: 'icarələr',
    addRental: 'icarə əlavə et',
    rentalCode: 'icarə kodu',
  },
  additionalInfos: {
    title: 'əlavə məlumat',
    addAdditionalInfo: 'əlavə məlumat əlavə et',
  },
  services: {
    title: 'xidmətlər',
    addService: 'xidmət əlavə et',
  },
  events: {
    title: 'tədbirlər',
    person: {
      tooltip: 'başqası tərəfindən tamamla',
    },
    info: {
      tooltip: 'ətraflı məlumat',
    },
    groupDetails: {
      newLesson: 'new lesson',
    },
    participants: {
      title: 'iştirakçılar',
    },
    participant: {
      title: 'iştirakçı',
    },
    addActionToEvent: 'tədbirə aid',
    addTaskToEvent: 'tədbirə tapşırıq əlavə et',
    addTaskToParticipant: 'iştirakçılara aid',
    activitiesOnTheTour: 'tur üzrə fəaliyyətlər',
  },
  event: {
    title: 'tədbir',
    addEvent: 'tədbir əlavə et',
    name: 'tədbirin adı',
    guides: 'bələdçilər',
  },
  eventDashboard: {
    maxPossibleRevenue: 'mümkün maks. ödənişlərin cəmi',
    supposedPaymentTotal: 'gözlənilən ödənişlərin cəmi',
    leftPaymentTotal: 'qalan ödənişlərin cəmi',
    generalCirculation: 'edilmiş ödənişlərin cəmi',
    registered: 'iştirakçıların sayı',
    numberSeatsRemaining: 'qalan yerlərin sayı',
    paid: 'ödəniş etmiş iştirakçıların sayı',
    paymentIsPending: 'ödəniş etməmiş iştirakçıların sayı',
  },
  labelStatus: {
    notPaid: 'ödənməyib',
    paid: 'ödənib',
    partially: 'qismən',
  },
  general: {
    title: 'ümumi',
  },
  equipment: {
    title: 'avadanlıq',
  },
  tours: {
    title: 'turlar',
    addTour: 'tur əlavə et',
    eventTitle: 'tədbirin başlığı',
  },
  students: {
    title: 'tələbələr',
  },
  groups: {
    title: 'qruplar',
  },
  transactions: {
    title: 'əməliyyatlar',
  },
  transaction: {
    title: 'əməliyyat',
  },
  actions: {
    title: 'fəaliyyətlər',
    actionName: 'fəaliyyət adı',
    addAction: 'fəaliyyət əlavə et',
  },
  action: {
    title: 'fəaliyyət',
  },
  tasks: {
    title: 'tapşırıqlar',
    addTask: 'tapşırıq əlavə et',
    taskName: 'tapşırıq adı',
    newTask: 'yeni tapşırıq',
  },
  task: {
    title: 'tapşırıq',
  },
  repeatingTransactions: {
    title: 'təkrar əməliyyat',
    addRepeatingTransaction: 'təkrar əməliyyat əlavə et',
  },
  internalTransactions: {
    title: 'daxili əməliyyatlar',
  },
  statsPage: {
    title: 'statistika',
    internalBalance: 'daxili balans',
  },
  popover: {
    title: 'profil',
    signOut: 'çıxış et',
    lessonMode: 'tələbə modu',
  },
  pages: {
    home: 'ana səhifə',
    students: 'tələbələr',
    studentDetails: 'tələbə haqqında',
    groups: 'qruplar',
    transactions: 'əməliyyatlar',
    internalTransactions: 'daxili əməliyyatlar',
  },
  heading: {
    general: 'Ümumi',
    management: 'İdarəetmə',
  },
  accounting: {
    title: 'Mühasibat uçotu',
    links: {
      transactions: 'Əməliyyatlar',
      internalTransactions: 'Daxili əməliyyatlar',
      stats: 'Statistika',
    },
  },
  profile: {
    title: 'Profil',
    changeCover: 'Örtüyü dəyişdirin',
    tabs: {
      myAccount: 'mənim hesabım',
      aboutMe: 'haqqımda',
    },
  },
  addExpense: {
    title: 'xərc əlavə et',
  },
  addTransaction: {
    title: 'əməliyyat əlavə et',
  },
  inputs: {
    labels: {
      changePassword: 'Parolu dəyişdirin',
      changeEmail: 'E-poçtu dəyişdirin',
      passwordCurrent: 'Hazırkı şifrə',
      password: 'Şifrə',
      passwordConfirm: 'Şifrəni təsdiqləyin',
      email: 'E-poçt',
      name: 'ad',
      surname: 'Soyad',
      fatherName: 'Ata adı',
      gender: 'Cins',
      fullName: 'Tam adı',
      dateOfBirth: 'Doğum tarixi',
      numberOne: '1-ci nömrə',
      numberTwo: '2-ci nömrə',
      date: 'Tarix',
      university: 'Universitet',
      interests: 'Maraqlar',
      price: 'məbləğ',
      permissionName: 'icazə adı',
      description: 'ətraflı',
      search: 'axtar',
      code: 'kod',
      slug: 'kod (slug)',
      tag: 'etiket adı',
      category: 'kateqoriya adı',
      amount: 'məbləğ',
      type: 'tipi',
      beforeEventDays: 'fəaliyyət günlərindən əvvəl',
      action: 'fəaliyyət adı',
      task: 'tapşırıq adı',
      condition: 'vəziyyəti',
      forSell: 'satış üçün',
      forRent: 'icarə üçün',
      inventoryType: 'inventory type',
      eventTitle: 'tədbirin başlığı',
      aboutTour: 'tur haqqında',
      additionalInfo: 'əlavə qeydlər',
      country: 'ölkə',
      days: 'gün',
      daysToDeadline: 'son tarixə qədər gün',
      difficulty: 'çətinlik',
      equipmentName: 'avadanlıq adı',
      brand: 'marka',
      model: 'model',
      size: 'ölçü',
      color: 'rəng',
      quantity: 'say',
      discount: 'endirim',
      purchasePrice: 'alış qiyməti',
      sellingPrice: 'satış qiyməti',
      rentPrice: 'icarə qiyməti',
      inventoryName: 'ehtiyat adı',
      rentalCode: 'icarə kodu',
      totalCost: 'ümumi xərc',
      startDate: 'başlama tarixi',
      endDate: 'bitmə tarixi',
      registrationEndDate: 'qeydiyyatın bitmə tarixi',
      finishDate: 'bitirmə tarixi',
      customer: 'müştəri',
      referal: 'referans',
      exacutedBy: 'yerinə yetirən',
      user: 'istifadəçi',
      users: 'istifadəçilər',
      certificateCode: 'sertifikat kodu',
      createdBy: 'əlavə etdi',
      allUsers: 'bütün iştirakçılar',
      whoCompletesTheTask: 'tapşırığı yerinə yetirən',
      searchUser: 'istifadəçini axtar',
      rentalDate: 'icarə tarixi',
      inventories: 'ehtiyatlar',
      recipient: 'alıcı',
      spender: 'xərcləyən',
      paymentMethod: 'ödəniş üsulu',
      period: 'dövr',
      duration: 'müddəti',
      groupSize: 'qrupun ölçüsü',
      relatedEvent: 'əlaqədar tədbir',
      phoneNumber: 'telefon nömrəsi',
      tour: 'tur',
      permissions: 'icazələr',
      assignedTo: 'məsul şəxs',
      participant: 'iştirakçı',
      event: 'tədbir',
      eventLanguages: 'dil',
      isPercent: 'faizdir',
      isRent: 'icarədir',
      precent: 'faiz',
      allEvents: 'bütün tədbirlər',
      events: 'tədbirlər',
      couponCode: 'kupon kodu',
      numbersIsForgein: 'istifadəçi nömrəsi xaricidir',
      generateCouponCode: 'kupon kodunu redaktə et',
      addResponsiblePerson: 'məsul şəxs əlavə et',
      roles: 'rollar',
      note: 'qeyd',
      filter: 'filter',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'təsdiq et',
    add: 'əlavə et',
    addEvent: 'tədbir əlavə et',
    addUser: 'istifadəçi əlavə et',
    delete: 'sil',
    cancel: 'ləğv et',
    archive: 'arxiv et',
    addPermission: 'icazə əlavə et',
    edit: 'redaktə et',
    addTag: 'etiket əlavə et',
    closeWindow: 'pəncərəni bağla',
    addExpense: 'xərc əlavə et',
    addTransaction: 'əməliyyat əlavə et',
    addAction: 'fəaliyyət əlavə et',
    addNewAction: 'yeni fəaliyyət əlavə et',
    addTask: 'tapşırıq əlavə et',
    addNewTask: 'yeni tapşırıq əlavə et',
    addNewTaskToParticipants: 'iştirakçılara yeni tapşırıq əlavə et',
    addTour: 'yeni tur əlavə et',
    addEquipment: 'yeni avadanlıq əlavə et',
    addNewEventTask: 'tədbirə tapşırıq əlavə et',
    addInventory: 'ehtiyat əlavə et',
    addRental: 'icarə əlavə et',
    addCertificate: 'sertifikat əlavə et',
    addRepeatingTransaction: 'təkrar əməliyyat əlavə et',
    addCategory: 'kateqoriya əlavə et',
    addCoupon: 'kupon əlavə et',
    addAdditionalInfo: 'əlavə məlumat əlavə et',
    addService: 'xidmət əlavə et',
    managePermissions: 'icazələri idarə et',
    manageTags: 'etiketləri idarə et',
  },
  yup: {
    passwordCurrent: 'cari şifrə tələb olunur',
    password: {
      required: 'şifrə tələb olunur',
      min: 'şifrə 6 simvoldan az ola bilməz.',
    },
    passwordConfirm: {
      required: 'təsdiq şifrəsi tələb olunur.',
      correctly: 'təkrarlanan şifrəyə uyğun gəlmir',
    },
    email: {
      required: 'e-poçt tələb olunur',
      correctly: 'e-poçt düzgün daxil edilməyib',
    },
    couponCode: {
      min: 'kupon kodu min 10 simvoldan ibarət olmalıdır',
    },
    spacesNotAllowed: 'boşluğa və nöqtəyə icazə verilmir',
    inputs: {
      name: 'ad tələb olunur',
      surname: 'soyad tələb olunur',
      fatherName: 'ata adı tələb olunur',
      gender: 'cins tələb olunur',
      university: 'iniversitet tələb olunur',
      date: 'tarix tələb olunur',
      price: 'məbləğ tələb olunur',
      participant: 'iştirakçı tələb olunur',
      permissionName: 'icazə adı tələb olunur',
      description: 'ətraflı məlumat tələb olunur',
      permissionSlug: 'kod (slug) tələb olunur',
      amount: 'məbləğ tələb olunur',
      tag: 'etiket tələb olunur',
      person: 'istifadəçi tələb olunur',
      beforeEventDays: 'gün tələb olunur',
      task: 'tapşırıq adı tələb olunur',
      action: 'fəaliyyət adı tələb olunur',
      eventTitle: 'tədbirin başlığı tələb olunur',
      brand: 'marka tələb olunur',
      quantity: 'say tələb olunur',
      discount: 'endirim tələb olunur',
      purchasePrice: 'alış qiyməti tələb olunur',
      sellingPrice: 'satış qiyməti tələb olunur',
      rentPrice: 'icarə qiyməti tələb olunur',
      customer: 'müştəri tələb olunur',
      totalCost: 'ümumi xərc tələb olunur',
      totalCostMin: 'məbləğ minimum 1 olmalıdır',
      startDate: 'başlama tarixi tələb olunur',
      endDate: 'bitmə tarixi tələb olunur',
      exacutedBy: 'yerinə yeriən tələb olunur',
      spender: 'xərcləyən tələb olunur',
      recipient: 'alıcı tələb olunur',
      duration: 'müddət tələb olunur',
      groupSize: 'qrupun ölçüsü tələb olunur',
      tour: 'tur tələb olunur',
      category: 'kateqoriya tələb olunur',
      precent: 'fazi tələb olunur',
      minPercent: 'minimum faiz 0 ola bilər',
      maxPercent: 'maksimum faiz 100 ola bilər',
      events: 'tədbir tələb olunur',
      relatedEvent: 'əlaqədar tədbir tələb olunur',
      guides: 'bələdçi tələb olunur',
      numberNotvalid: 'telefon nömrəsi etibarlı deyil',
      couponCode: 'kupon kodu tələb olunur',
      responsiblePersonRequired: 'məhsul şəxs tələb olunur',
    },
  },
  login: {
    signIn: 'Daxil Ol',
    info: 'hesabınıza daxil olmaq üçün aşağıdakı xanaları doldurun',
    lostPassword: 'şifrənizi unutmusunuz?',
    signUp: 'qeydiyyatdan keçin',
  },
  recoverPassword: {
    title: 'şifrəni bərpa edin',
    info: 'şifrənizi yeniləmək üçün istifadə olunan e-poçtu daxil edin',
    sendPassword: 'yeni şifrəni göndər',
    signInAgain: 'yenidən daxil olmaq istəyirsiniz?',
    clickHere: 'bura klikləyin',
  },
  languageSwitcher: {
    title: 'dil',
    tooltip: 'dili dəyiş',
  },
  modal: {
    delete: 'silmək istədiyinizdən əminsiniz?',
    archive: 'arxiv etmək istədiyinizdən əminsiniz?',
    amountAfterDiscount: 'endirimdən sonrakı məbləğ',
    addAmount: 'məbləğ əlavə et',
    makePayment: 'ödəniş et',
    allTasksOfParticipant: 'iştirakçıya ayrılan tapşırıqlar',
    addNewAction: 'yeni fəaliyyət əlavə et',
    addNewTask: 'yeni tapşırıq əlavə et',
    addNewParticipantTask: 'iştirakçılara yeni tapşırıq əlavə et',
    addNewEventTask: 'tədbirə tapşırıq əlavə et',
    details: 'ətraflı məlumat',
    addPhoto: 'şəkil əlavə et',
    addInfoToTask: 'tapşırığa məlumatları əlavə et',
    customizeTasks: 'tapşırıqları fərdiləşdir',
  },
  table: {
    name: 'ad',
    fullName: 'tam adı',
    category: 'kateqoriya',
    type: 'tipi',
    amount: 'məbləğ',
    description: 'ətraflı',
    actions: 'əməliyyat',
    code: 'kod',
    tasks: 'tapşırıqlar',
    beforeEventDays: 'fəaliyyət günlərindən əvvəl',
    brand: 'marka',
    model: 'model',
    color: 'rəng',
    size: 'ölçü',
    quantity: 'say',
    discount: 'endirim',
    purchasePrice: 'alış qiyməti',
    sellingPrice: 'satış qiyməti',
    rentPrice: 'icarə qiyməti',
    totalCost: 'ümumi xərc',
    user: 'istifadəçi',
    created: 'əlavə etdi',
    rentalDate: 'icarə tarixi',
    customer: 'müştəri',
    recipient: 'alıcı',
    spender: 'xərcləyən',
    phoneNumber: 'telefon nömrəsi',
    event: 'tədbir',
    participant: 'iştirakçı',
    assignedTo: 'məsul şəxs',
    deadline: 'son tarix',
    endDate: 'bitmə tarixi',
    slug: 'kod (slug)',
    isRent: 'icarədir',
    paymentStatus: 'ödəniş statusu',
  },
  permissions: {
    title: 'icazələr',
    premissionName: 'icazə adı',
    premissonSlug: 'kod (slug)',
    premissonDescription: 'açıqlama',
    addPermission: 'icazə əlavə et',
  },
  managePermissions: {
    title: 'icazələri idarə edin',
  },
  manageTags: {
    title: 'etiketləri idarə edin',
  },
  categories: {
    title: 'kateqoriyalar',
    categoryName: 'kateqoriya adı',
    addCategory: 'kateqoriya əlavə et',
  },
  appManager: {
    title: 'proqram meneceri',
  },
  tags: {
    title: 'etiketlər',
    tagName: 'etiket adı',
    tagDescription: 'açıqlama',
    addTag: 'etiket əlavə et',
  },
  actionButtons: {
    view: 'ətraflı',
    delete: 'sil',
    archive: 'arxiv',
    discount: 'endirim et',
    addAmount: 'məbləğ əlavə et',
    edit: 'redaktə et',
    confirm: 'təsdiq et',
    allTasks: 'ümumi tapşırıqlar',
    add: 'əlavə et',
    selectUser: 'iştirakçı seç',
    endDate: 'bitmə tarixi',
    getCouponCode: 'kupon kodu yarat və ya yenilə',
  },
  noResultFound: 'nəticə taplımadı',
  timeIsUp: 'vaxt bitmişdir!',
  daysLeft: 'gün qalıb',
  cash: 'nağd',
  online: 'onlayn',
  income: 'gəlir',
  expense: 'xərc',
  oneMonthPeriod: '1 aydan 1',
  threeMonthPeriod: '3 aydan 1',
  female: 'qadın',
  male: 'kişi',
  unknown: 'naməlum',
  other: 'başqa',
  messages: {
    noData: 'məlumat yoxdur',
    archiveInventory: 'inventari arxiv et',
    successfulProcess: 'process was successfull',
    success: 'success',
    unknownError: 'unknown error',
  },

  tooltips: {
    view: 'ətraflı',
    edit: 'redaktə',
    archive: 'arxiv',
  },
  options: {
    bad: 'pis',
    good: 'yaxsi',
    great: 'əla',
  },
};

export default azJSON;
