import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const updateUserTags = async ({
  userId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/users/${userId}/tags`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
