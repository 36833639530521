import { Box, Grid, Typography, Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ITinyUser } from 'src/modules/users/types';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';

export const EditCertificateForm = ({
  formik,
  isEditingCertificate,
  usersData,
}) => {
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={usersData || []}
            disablePortal
            onChange={(_e, newValue: ITinyUser) => {
              formik.setFieldValue('user', newValue ? newValue.id : '');
            }}
            value={
              usersData && formik.values.user
                ? usersData.find(
                    (option: ITinyUser) => option.id === formik.values.user,
                  )
                : null
            }
            getOptionLabel={(option: ITinyUser) => {
              return `${option.fullName} (${option.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.user')}
              />
            )}
          />
          {!!formik.errors.user && formik.touched.user && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.user}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmDateTimePicker
            inputFormat="dd/MM/yyyy"
            formik={formik}
            label={t('inputs.labels.startDate')}
            name="startDate"
          />
          {!!formik.errors.startDate && formik.touched.startDate && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.startDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmDateTimePicker
            inputFormat="dd/MM/yyyy"
            formik={formik}
            label={t('inputs.labels.finishDate')}
            name="finishDate"
          />
          {!!formik.errors.finishDate && formik.touched.finishDate && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.finishDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.labels.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <SubmitButton
          load={isEditingCertificate}
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t('buttons.edit')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
