import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { AdditionalContext } from '../context/additional-info-context';
import { getAdditionalInfos } from '../services';
import { TAdditionalInfosRoot } from '../types';

export const useAdditionalInfos = () => {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const { page, limit } = useContext(AdditionalContext);

  const { data } = useQuery({
    queryKey: ['get-additional-infos', page, limit, title],
    queryFn: getAdditionalInfos,
    select: ({ data }: { data: TAdditionalInfosRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
