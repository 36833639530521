import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';

import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addAction } from '../services';
import { AddActionMapper } from '../mappers/add-action-mapper';

export const useAddAction = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.action')),
    beforeEventDays: Yup.string().required(t('yup.inputs.beforeEventDays')),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      beforeEventDays: 2,
      description: '',
      relatedTo: 'event',
    },
    onSubmit: (values) => {
      const actionBody = AddActionMapper(values);

      if (actionBody) {
        addNewAction(actionBody);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate: addNewAction, isLoading } = useMutation(
    'add-action',
    addAction,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return { formik, isLoading };
};
