import { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { amountAfterDicountService } from '../services';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const useAddDiscount = () => {
  const { t } = useTranslation();

  const { participantId, eventId } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { handleMessage } = useContext(AlertContext);

  const validationDiscount = Yup.object().shape({
    amountAfterDicount: Yup.string().required(t('yup.inputs.amount')),
  });

  const formik = useFormik({
    initialValues: {
      amountAfterDicount: '',
    },
    onSubmit: (values) => {
      if (values.amountAfterDicount.trim() !== '') {
        addDiscount({
          eventId: eventId,
          userId: participantId,
          amountAfterDicount: values.amountAfterDicount,
        });
      }
    },
    validationSchema: validationDiscount,
    enableReinitialize: true,
  });

  const { mutate: addDiscount, isLoading } = useMutation(
    'add-discount',
    amountAfterDicountService,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
          queryClient.invalidateQueries('get-event');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    formik,
    isLoading,
  };
};
