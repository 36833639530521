import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AdditionalInfoForm } from '../components/additional-info-form';
import { useAdditionalInfoForm } from '../hooks/use-additional-info-form';

export const AdditionalInfoContainer = () => {
  const { t } = useTranslation();

  const { data, formik, isAddingAdditionalInfo, isEditingAdditionalInfo } =
    useAdditionalInfoForm();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('additionalInfos.title'),
      href: '/dashboard/additional-infos',
    },
    {
      label: data ? data?.text : t('additionalInfos.addAdditionalInfo'),
      href: data
        ? `/dashboard/additionalInfos/${data?.id}`
        : '/dashboard/additionalInfos/add-additional-info',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data ? data?.text : t('additionalInfos.addAdditionalInfo')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AdditionalInfoForm
            data={data}
            formik={formik}
            isAddingAdditionalInfo={isAddingAdditionalInfo}
            isEditingAdditionalInfo={isEditingAdditionalInfo}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
