import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import GenericTable from 'src/shared/components/generic-table';
import { useToursTable } from '../hooks/use-tours-table';
import { useTours } from '../hooks/use-tours';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const ToursContainer = () => {
  const { t } = useTranslation();

  const { data } = useTours();

  const [columns, options, routes] = useToursTable();

  return (
    <PageWrapper
      action={
        <Button
          component={RouterLink}
          to={`/dashboard/tours/add-tour`}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addTour')}
        </Button>
      }
      routes={routes}
      title={t('tours.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
