import { useState } from 'react';
import { AdditionalContext } from '../context/additional-info-context';

function AdditionalContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('5');

  return (
    <AdditionalContext.Provider value={{ page, limit, setPage, setLimit }}>
      {children}
    </AdditionalContext.Provider>
  );
}

export default AdditionalContextProvider;
