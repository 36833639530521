import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';
import { addPermission } from '../services';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export const useAddPermission = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.inputs.permissionName')),
    description: Yup.string().required(t('yup.inputs.description')),
    slug: Yup.string().required(t('yup.inputs.permissionSlug')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      slug: '',
    },
    onSubmit: (values) => {
      addNewPermission(values);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate: addNewPermission } = useMutation(
    'add-permission',
    addPermission,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return { formik };
};
