import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addTask, getParticipants } from '../services';
import { getTinyUsers } from 'src/modules/users/services';
import { queryError } from 'src/shared/utils/query-error';
import { getEvents } from 'src/modules/events/services';
import { addNewTaskMapper } from '../mappers';
import { IEventsRoot } from 'src/modules/events/types';
import { TTinyUsersRoot } from 'src/modules/users/types';
import { IParticipant, TParticipantRoot } from '../types';

export const useAddTask = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.task')),
    deadline: Yup.string().required(t('yup.inputs.endDate')),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      event: '',
      deadline: '',
      assignedTo: '',
      participants: [],
    },
    onSubmit: (values) => {
      if (values.title.length > 0) {
        const addNewTaskBody = addNewTaskMapper(values);

        addNewTask(addNewTaskBody);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { data: eventsData } = useQuery('events', getEvents, {
    select: ({ data }: { data: IEventsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      return data.data;
    },
    onError: queryError,
  });

  const { mutate: addNewTask } = useMutation('add-task', addTask, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  const { data: participantsData, isLoading } = useQuery(
    ['get-participants', formik.values.event],
    () => {
      if (formik.values.event !== '') {
        return getParticipants(formik.values.event);
      }
    },
    {
      select: ({ data }: { data: TParticipantRoot }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
      enabled: formik.values.event !== '',
    },
  );

  useEffect(() => {
    if (participantsData) {
      const participantIds = participantsData.map(
        (pObj: IParticipant) => pObj.userId.id,
      );
      if (participantIds) {
        formik.setFieldValue('participants', [...participantIds]);
      }
    }
  }, [participantsData]);

  return { formik, eventsData, usersData, participantsData, isLoading };
};
