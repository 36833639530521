import { Box, Grid } from '@mui/material';
import { OmInput } from 'src/shared/components';
import { useAddTag } from '../hooks/use-add-tag';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';

export const AddTagForm = () => {
  const { formik } = useAddTag();
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.tag')}
            name="label"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.labels.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <SubmitButton load={false} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.add')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
