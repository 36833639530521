import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { EditTourForm } from '../components/edit-tour-form';
import { useEditTour } from '../hooks/use-edit-tour';

export const EditTourContainer = () => {
  const { t } = useTranslation();

  const { formik, data, isEditingTour } = useEditTour();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: t('tours.title'),
      href: '/dashboard/tours',
    },
    {
      label: `${data?.title ? data?.title : ''}`,
      href: `/dashboard/tours/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.title ? data?.title : ''}`}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <EditTourForm formik={formik} isEditingTour={isEditingTour} />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
