import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { createInventory } from '../services';

export const useAddInventory = function () {
  const { t } = useTranslation();
  const { handleMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [images, setImages] = useState([]);
  const [imageName, setImageName] = useState('');

  const { mutate: inventoryFormMutate, isLoading: isAddingInventory } =
    useMutation('inventory-form', createInventory, {
      onSuccess: (e: any) => {
        if (e.status === 200 || e.status === 201) {
          handleMessage(
            true,
            t('messages.successfulProcess'),
            t('messages.success'),
          );
          queryClient.invalidateQueries('inventories-get-all');
          navigate('/dashboard/inventories');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg =
          error?.response?.data?.message || t('messages.unknownError');
        handleMessage(true, errorMsg, 'error');
      },
    });

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      description: 'hello',
      quantity: 10,
      forRent: false,
      forSell: false,
      images: ['/path/to/image'],
      inventoryType: '64aea111d41ad40014caa7b8',
      condition: 'good',
      brand: 'hello',
      model: 'hello',
      color: 'hello',
      type: 'hello',
      size: 'hello',
    },
    onSubmit: (values) => {
      inventoryFormMutate(values);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return {
    formik,
    isAddingInventory,
    images,
    setImages,
    imageName,
    setImageName,
  };
};
