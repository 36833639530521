import { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { editTask, getTask } from 'src/modules/tasks/services';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { ITask } from 'src/modules/tasks/types';

export const useEditTaskOfParticipant = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { taskId } = useParams();

  const navigate = useNavigate();

  const { data } = useQuery(
    ['get-task', taskId],
    () => {
      if (taskId) {
        return getTask(taskId);
      }
    },
    {
      select: ({ data }): ITask => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { mutate, isLoading: isUpdating } = useMutation(
    'edit-task-of-participant',
    editTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationTitle = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.task')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
    },
    onSubmit: (values) => {
      mutate({ taskId, data: values });
    },
    validationSchema: validationTitle,
    enableReinitialize: true,
  });

  return {
    formik,
    mutate,
    isUpdating,
  };
};
