import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { EditTaskForm } from '../components/edit-task-form';
import { useEditTask } from '../hooks/use-edit-task';

export const EditTaskContainer = () => {
  const {
    formik,
    data,
    isEditingTask,
    eventsData,
    usersData,
    participantsData,
    isLoading,
  } = useEditTask();

  const { t } = useTranslation();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: t('tasks.title'),
      href: '/dashboard/tasks',
    },
    {
      label: `${data?.title ? data?.title : ''}`,
      href: `/dashboard/tasks/${data?.id}`,
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.title ? data?.title : ''}`}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <EditTaskForm
            formik={formik}
            isEditingTask={isEditingTask}
            eventsData={eventsData}
            usersData={usersData}
            participantsData={participantsData}
            isLoading={isLoading}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
