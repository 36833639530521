import { AddRental } from '../pages/add-rental';
import { EditRental } from '../pages/edit-rental';
import { RentalDetails } from '../pages/rental-details';
import { Rentals } from '../pages/rentals';

const rentalsRouter = {
  path: 'rentals',
  children: [
    {
      path: '',
      element: <Rentals />,
    },
    {
      path: ':rentalId',
      element: <RentalDetails />,
    },
    {
      path: 'edit/:rentalId',
      element: <EditRental />,
    },
    {
      path: 'add-rental',
      element: <AddRental />,
    },
  ],
};

export default rentalsRouter;
