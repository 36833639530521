import { useMemo } from 'react';
import { IOptions } from 'src/shared/components/generic-table/types';

import { useTranslation } from 'react-i18next';
import { EventsTableActionButtons } from '../components/action-buttons';
import { IEvent } from '../types';

export const useEventsTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('events.title'),
      href: '/dashboard/events',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: IEvent) => item.code,
      },
      {
        id: 'title',
        align: 'left',
        label: t('table.name'),
        extructor: (item: IEvent) => item.title,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IEvent) => {
          return <EventsTableActionButtons eventId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
