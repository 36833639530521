import { useState } from 'react';
import { UsersContext } from '../context/users-context';

function UsersContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('5');

  return (
    <UsersContext.Provider value={{ page, limit, setPage, setLimit }}>
      {children}
    </UsersContext.Provider>
  );
}

export default UsersContextProvider;
