import { useMemo } from 'react';
import { IOptions } from 'src/shared/components/generic-table/types';

import { useTranslation } from 'react-i18next';
import UsersTableActionButtons from '../components/action-buttons';
import { IUser } from '../types';

export const useUsersTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
    pageLimits: [5, 25, 50],
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('users.title'),
      href: '/dashboard/users',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: IUser) => item.code,
      },
      {
        id: 'title',
        align: 'left',
        label: t('table.fullName'),
        extructor: (item: IUser) => item.fullName,
      },
      {
        id: 'number',
        align: 'left',
        label: t('table.phoneNumber'),
        extructor: (item: IUser) =>
          item.phoneNumbers.map((number) => number).join(', '),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IUser) => {
          return <UsersTableActionButtons userId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
