import { Grid, Card, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const UserActionButtons = () => {
  const { t } = useTranslation();

  const { userId } = useParams();

  return (
    <Grid item xs={12} sx={{ mb: 5 }}>
      <Card
        sx={{
          p: 3,
          gap: 1.5,
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Button
          component={RouterLink}
          to={`/dashboard/users/manage-permissions/${userId}`}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.managePermissions')}
        </Button>
        <Button
          component={RouterLink}
          to={`/dashboard/users/manage-tags/${userId}`}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.manageTags')}
        </Button>
        <Button
          component={RouterLink}
          to={`/dashboard/users/edit-user/${userId}`}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.edit')}
        </Button>
      </Card>
    </Grid>
  );
};
