import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { deleteRepeatingTransaction } from '../services';

export const useDeleteRepeatingTransaction = () => {
  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    'delete-repeating-transaction',
    deleteRepeatingTransaction,
    {
      onSuccess: (e: any) => {
        if (e.status === 204) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.refetchQueries('get-repeating-transactions');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );
  return {
    mutate,
  };
};
