import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useAdditionalInfoDetails } from '../hooks/use-additional-info-details';
import { AdditionalInfoDetailsForm } from '../components/additional-info-details-form';

export const AdditionalInfoDetailsContainer = () => {
  const { data, isLoading } = useAdditionalInfoDetails();

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('additionalInfos.title'),
      href: '/dashboard/additional-infos',
    },
    {
      label: data?.text ? data?.text : '',
      href: `/dashboard/additional-infos/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('additionalInfos.title')}
    >
      <Helmet>
        <title>{`${t('additionalInfos.title')} | ${
          data?.text ? data?.text : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container>
            <AdditionalInfoDetailsForm data={data} />
          </Grid>
        </Card>
      )}
    </PageWrapper>
  );
};
