import { useState } from 'react';
import {
  Box,
  Checkbox,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IEvent, IEventParticipant } from '../types';
import { ParticipantActionButtons } from './participant-action-buttons';
import { useToggleParticipantTask } from '../hooks/use-toggle-participant-task';
import Label from 'src/shared/components/Label';
import { ITask } from 'src/modules/tasks/types';

export const ParticipantsTable = ({ data }: { data: IEvent }) => {
  const { isUpdating, mutate } = useToggleParticipantTask();
  const [findTask, setFindTask] = useState<ITask>();
  const [findParticipant, setFindParticipant] = useState<IEventParticipant>();

  const { t } = useTranslation();

  const { participants } = data;

  const handleChecked = (taskId: string, participant: IEventParticipant) => {
    const foundTask = participant?.tasks.find(
      (item: ITask) => item.id === taskId,
    );

    const participantId = participant.id;

    const foundParticipant = participants?.find(
      (item: IEventParticipant) => item.id === participantId,
    );

    if (foundTask && !isUpdating) {
      setFindTask(foundTask);
      setFindParticipant(foundParticipant);
      mutate({
        taskId,
        executedBy: foundParticipant.id,
      });
    }
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ textTransform: 'lowercase', width: '0px' }} />
            <TableCell sx={{ textTransform: 'lowercase' }}>
              {t('table.fullName')}
            </TableCell>
            <TableCell sx={{ textTransform: 'lowercase' }}>
              {t('table.paymentStatus')}
            </TableCell>
            <TableCell align="center" sx={{ textTransform: 'lowercase' }}>
              {t('table.tasks')}
            </TableCell>
            <TableCell align="right" sx={{ textTransform: 'lowercase' }}>
              {t('table.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {participants?.map((participant: IEventParticipant, i) => {
            let status = '';
            let colorClass = '';

            if (participant.paid === 0) {
              status = `${t('labelStatus.notPaid')} (${participant.paid}/${
                participant.supposedToPay
              })`;
              colorClass = 'error';
            } else if (
              participant.paid > 0 &&
              participant.paid < participant.supposedToPay
            ) {
              status = `${t('labelStatus.partially')} (${participant.paid}/${
                participant.supposedToPay
              })`;
              colorClass = 'warning';
            } else if (participant.paid >= participant.supposedToPay) {
              status = `${t('labelStatus.paid')} (${participant.paid}/${
                participant.supposedToPay
              })`;
              colorClass = 'success';
            }

            return (
              <TableRow
                key={participant.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell scope="row">{i + 1}</TableCell>
                <TableCell scope="row">{participant.fullName}</TableCell>
                <TableCell scope="row">
                  <Label color={colorClass}>{status}</Label>
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    component="form"
                  >
                    {participant?.tasks?.map((task: ITask) => {
                      return (
                        <Box
                          sx={{
                            opacity:
                              task.toggleReason === 'Done' ? '60%' : '100%',
                          }}
                          key={task.id}
                        >
                          <FormControlLabel
                            label={''}
                            control={
                              isUpdating &&
                              findTask &&
                              findParticipant.id === participant.id &&
                              findTask.id === task.id ? (
                                <Box sx={{ p: 1 }}>
                                  <CircularProgress size={24} />
                                </Box>
                              ) : (
                                <Tooltip title={task.title}>
                                  <Checkbox
                                    onChange={() =>
                                      handleChecked(task.id, participant)
                                    }
                                    checked={task.toggleReason === 'Done'}
                                  />
                                </Tooltip>
                              )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <ParticipantActionButtons
                    participantId={participant.id}
                    eventId={data.id}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
