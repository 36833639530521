import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAdditionalInfo } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';

export const useDeleteAdditionalInfo = () => {
  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    'delete-additional-info',
    deleteAdditionalInfo,
    {
      onSuccess: (e: any) => {
        if (e.status === 204) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.refetchQueries('get-additional-infos');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    mutate,
  };
};
