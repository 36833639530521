import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getTags = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tags`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTag = async (tagId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tags/${tagId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editTag = async ({ tagId, data }): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/tags/${tagId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addTag = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/tags`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteTag = async (tagId: string): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/tags/${tagId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
