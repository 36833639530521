import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useCertificate } from '../hooks/use-certificate';
import { CertificateDetailsForm } from '../components/certificate-details-form';

export const CertificateDetailsContainer = () => {
  const { data, isLoading } = useCertificate();

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('certificates.title'),
      href: '/dashboard/certificates',
    },
    {
      label: data?.code ? data?.code : '',
      href: `/dashboard/certificates/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('certificates.certificateCode')}: ${
        data?.code ? data?.code : ''
      }`}
    >
      <Helmet>
        <title>{`${t('certificates.certificateCode')} | ${
          data?.code ? data?.code : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container>
            <CertificateDetailsForm data={data} />
          </Grid>
        </Card>
      )}
    </PageWrapper>
  );
};
