import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getUsers } from '../services';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { UsersContext } from '../context/users-context';
import { TTinyUsersRoot } from '../types';

export const useUsers = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const { page, limit } = useContext(UsersContext);

  const { data } = useQuery({
    queryKey: ['get-users', page, limit, name],
    queryFn: getUsers,
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return {
          users: data?.data,
          results: data?.results,
          total: data?.total,
        };
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
