import { useMemo } from 'react';
import { IOptions } from 'src/shared/components/generic-table/types';

import { useTranslation } from 'react-i18next';
import CategoriesTableActionButtons from '../components/action-buttons';
import { ICategory } from '../types';

export const useCategoriesTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('categories.title'),
      href: '/dashboard/categories',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (category: ICategory) => category.name,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (category: ICategory) => {
          return <CategoriesTableActionButtons categoryId={category.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
