import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddRepeatingTransactionForm } from '../components/add-repeating-transaction-form';

export const AddRepeatingTransactionContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('repeatingTransactions.title'),
      href: '/dashboard/repeating-transactions',
    },
    {
      label: t('repeatingTransactions.addRepeatingTransaction'),
      href: '/dashboard/repeating-transactions/add-repeating-transaction',
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('repeatingTransactions.addRepeatingTransaction')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddRepeatingTransactionForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
