import { Typography, Switch } from '@mui/material';
import { IPermission } from 'src/modules/permissions/types';

export const ManagePermissionsActionButtons = ({
  permission,
  formik,
}: {
  permission: IPermission;
  formik: any;
}) => {
  const handleTogglePermission = () => {
    let { permissions } = formik.values;

    if (permissions.includes(permission.slug)) {
      permissions = permissions.filter((perStrg: string) => {
        return perStrg !== permission.slug;
      });
    } else {
      permissions = [...permissions, permission.slug];
    }

    formik.setFieldValue('permissions', permissions);
  };

  const isChecked = formik.values.permissions?.includes(permission.slug);

  return (
    <Typography noWrap>
      <Switch checked={isChecked} onChange={handleTogglePermission} />
    </Typography>
  );
};
