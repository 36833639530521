import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import SubmitButton from 'src/shared/components/Submit-button';
import { OmInput, OmDateTimePicker, OmSelect } from 'src/shared/components';
import { useAddEventForm } from '../hooks/use-add-event-form';
import Manat from 'src/assets/icon/manat';
import { Edit } from '@mui/icons-material';
import { IGuide } from '../types';
import { ITour, ITourAction } from 'src/modules/tours/types';
import { ITask } from 'src/modules/tasks/types';

export const AddEventForm = () => {
  const { t } = useTranslation();

  const { formik, isCreating, guidesData, toursData, foundTour, isLoading } =
    useAddEventForm();

  const eventLanguagesData = [
    {
      value: 'az',
      name: 'az',
    },
    {
      value: 'en',
      name: 'en',
    },
  ];

  const handleCheckboxChange = (action: ITourAction, checked: boolean) => {
    if (checked) {
      formik.setFieldValue('tasks', [
        ...formik.values.tasks,
        {
          title: action.title,
          relatedTo: action.relatedTo,
        },
      ]);
    } else {
      formik.setFieldValue(
        'tasks',
        formik.values.tasks.filter((t) => t.title !== action.title),
      );
    }
  };

  // const handleAutocompleteChange = (_e, newValue) => {
  //   if (newValue) {
  //     formik.setFieldValue('assignedTo', newValue.id);

  //     const updatedTasks = formik.values.tasks.map((task) => ({
  //       ...task,
  //       assignedTo: newValue.id,
  //     }));
  //     formik.setFieldValue('tasks', updatedTasks);
  //   }
  // };

  const tourActions = foundTour.flatMap((tour: ITour) => tour.actions);

  return (
    <Grid item xs={12}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={toursData || []}
                  disablePortal
                  disabled={false}
                  onChange={(_e, newValue: ITour) => {
                    formik.setFieldValue('tour', newValue ? newValue.id : '');
                  }}
                  getOptionLabel={(option: ITour) => {
                    return `${option.title}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={t('inputs.labels.tour')}
                    />
                  )}
                />
                {!!formik.errors.tour && formik.touched.tour && (
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      color: '#FF1943',
                    }}
                  >
                    {formik.errors.tour}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <OmInput
                  formik={formik}
                  label={t('inputs.labels.eventTitle')}
                  name="eventTitle"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <OmInput
                  formik={formik}
                  label={t('inputs.labels.price')}
                  name="pricePerPerson"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  endAdornment={
                    <InputAdornment position="end">
                      <Manat />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <OmSelect
                  formik={formik}
                  label={t('inputs.labels.eventLanguages')}
                  name="eventLanguages"
                  options={eventLanguagesData}
                />
              </Grid>
              <Grid item xs={12}>
                <OmInput
                  formik={formik}
                  label={t('inputs.labels.duration')}
                  name="duration"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                />
              </Grid>
              <Grid item xs={12}>
                <OmInput
                  formik={formik}
                  label={t('inputs.labels.groupSize')}
                  name="groupSize"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                />
              </Grid>
              <Grid item xs={12}>
                <OmDateTimePicker
                  inputFormat="dd/MM/yyyy"
                  formik={formik}
                  label={t('inputs.labels.date')}
                  name="date"
                />
                {!!formik.errors.date && formik.touched.date && (
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      color: '#FF1943',
                    }}
                  >
                    {formik.errors.date}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple={true}
                  options={guidesData || []}
                  disablePortal
                  disabled={isCreating}
                  onChange={(_e, newValue: any) => {
                    formik.setFieldValue(
                      'guides',
                      newValue.map((item: IGuide) => item.id),
                    );
                  }}
                  getOptionLabel={(option: IGuide) => option.fullName}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={t('event.guides')}
                    />
                  )}
                />
                {!!formik.errors.guides && formik.touched.guides && (
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      color: '#FF1943',
                    }}
                  >
                    {formik.errors.guides}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {
            <Grid item xs={12} sm={12} md={6}>
              {isLoading ? (
                <CircularProgress size={22} />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" textAlign={'center'}>
                      {t('events.activitiesOnTheTour')}
                    </Typography>
                  </Grid>
                  {tourActions.length > 0 ? (
                    ['event', 'participant'].map((relatedTo) => (
                      <Grid key={relatedTo} item xs={12} sm={12} md={6}>
                        <Typography variant="h5">
                          {relatedTo === 'event'
                            ? t('events.addActionToEvent')
                            : t('events.addTaskToParticipant')}
                        </Typography>
                        {foundTour?.map((tour: ITour) =>
                          tour.actions
                            .filter(
                              (action: ITourAction) =>
                                action.relatedTo === relatedTo,
                            )
                            .map((action: ITourAction) => {
                              return (
                                <Grid key={action.id} item xs={12}>
                                  <Box
                                    key={action.id}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <FormControlLabel
                                      label={action.title}
                                      control={
                                        <Checkbox
                                          checked={formik.values.tasks.some(
                                            (t: ITask) =>
                                              t.title === action.title &&
                                              t.relatedTo === action.relatedTo,
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              action,
                                              e.target.checked,
                                            )
                                          }
                                        />
                                      }
                                    />
                                    <Tooltip
                                      title={t('actionButtons.add')}
                                      arrow
                                    >
                                      <IconButton
                                        disabled={true}
                                        component={RouterLink}
                                        to={`/dashboard/events/new-event/${action.id}`}
                                        color="primary"
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              );
                            }),
                        )}
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="body1">
                        {t('messages.noData')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          }
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <SubmitButton
            load={isCreating}
            sx={{ width: { xs: '100%', md: 200 }, mt: 2 }}
          >
            {t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
      {/* <CustomizeEventTask guidesData={guidesData} formik={formik} /> */}
    </Grid>
  );
};
