import { Box, Grid } from '@mui/material';
import { OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddCategory } from '../hooks/use-add-category';

export const AddCategoryForm = () => {
  const { t } = useTranslation();

  const { formik, isLoading } = useAddCategory();

  const typeCategory = [
    {
      value: 'income',
      name: t('income'),
    },
    {
      value: 'expense',
      name: t('expense'),
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.type')}
            name="type"
            options={typeCategory}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
      >
        <SubmitButton load={isLoading} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.add')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
