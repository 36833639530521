import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Manat from 'src/assets/icon/manat';
import Precent from 'src/assets/icon/precent';
import { ICoupon } from '../types';

export const CouponDetailsForm = ({ data }: { data: ICoupon }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.couponCode')}: {data?.code}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.createdBy')}: {data?.createdBy.fullName}
              </Box>
            </Grid>
            {data?.isRent && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #c4c4c4',
                    padding: '12px 10px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  {t('inputs.labels.isRent')}: <CheckBoxIcon color="success" />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                {t('inputs.labels.amount')}:
                {data?.isPercent ? (
                  <Typography display={'flex'} alignItems={'center'} gap={0.5}>
                    {data?.amount}
                    <Precent />
                  </Typography>
                ) : (
                  <Typography display={'flex'} alignItems={'center'} gap={0.5}>
                    {data?.amount}
                    <Manat />
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  border: '1px solid #c4c4c4',
                  padding: '12px 10px',
                  borderRadius: '10px',
                }}
              >
                {t('inputs.labels.description')}: {data?.description}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
