import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { addArchiveEvent } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';

export const useAddEventArchive = () => {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();

  const { mutate: addEventArchive } = useMutation(
    'add-archive-event',
    addArchiveEvent,
    {
      onSuccess: (e: any) => {
        if (e.status === 204) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.invalidateQueries('events');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    addEventArchive,
  };
};
