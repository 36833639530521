import { AddInventory } from '../pages/add-inventory';
import { Inventories } from '../pages/inventories';

const inventoriesRouter = {
  path: 'inventories',
  children: [
    {
      path: '',
      element: <Inventories />,
      children: [],
    },
    {
      path: 'add-inventory',
      element: <AddInventory />,
      children: [],
    },
  ],
};

export default inventoriesRouter;
