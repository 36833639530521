import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddTagForm } from '../components/add-tag-form';
import { useTranslation } from 'react-i18next';

export const AddTagContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tags.title'),
      href: '/dashboard/tags',
    },
    {
      label: t('tags.addTag'),
      href: '/dashboard/add-permission',
    },
  ];
  return (
    <PageWrapper isPaper={false} routes={routes} title={t('tags.addTag')}>
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddTagForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
