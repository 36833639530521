import { AddCertificate } from '../pages/add-certificate';
import { CertificateDetails } from '../pages/certificate-details';
import { Certificates } from '../pages/certificates';
import { EditCertificate } from '../pages/edit-certificate';

const certificatesRouter = {
  path: 'certificates',
  children: [
    {
      path: '',
      element: <Certificates />,
    },
    {
      path: ':certificateId',
      element: <CertificateDetails />,
    },
    {
      path: 'add-certificate',
      element: <AddCertificate />,
    },
    {
      path: 'edit/:certificateId',
      element: <EditCertificate />,
    },
  ],
};

export default certificatesRouter;
