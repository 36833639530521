import { Coupon } from '../pages/coupon';
import { CouponDetails } from '../pages/coupon-details';
import { Coupons } from '../pages/coupons';

const couponsRouter = {
  path: 'coupons',
  children: [
    {
      path: '',
      element: <Coupons />,
    },
    {
      path: ':couponId',
      element: <CouponDetails />,
    },
    {
      path: 'add-coupon',
      element: <Coupon />,
    },
    {
      path: 'edit/:couponId',
      element: <Coupon />,
    },
  ],
};

export default couponsRouter;
