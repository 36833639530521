import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { editTour, getTour } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ITour } from '../types';

export const useEditTour = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { tourId } = useParams();

  const navigate = useNavigate();

  const { data } = useQuery(
    ['get-tour', tourId],
    () => {
      if (tourId) {
        return getTour(tourId);
      }
    },
    {
      select: ({ data }): ITour => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { mutate, isLoading: isEditingTour } = useMutation(
    'edit-tour',
    editTour,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.tour')),
  });

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      aboutTour: data?.aboutTour || '',
      additionalInfo: data?.additionalInfo || [''],
      country: data?.country || '',
      daysToDeadline: data?.daysToDeadline || '',
      description: data?.description || '',
      difficulty: data?.difficulty || '',
      district: data?.district || ' ',
      duration: {
        nights: data?.duration.nights || '',
        days: data?.duration.days || '',
      },
      equipment: data?.equipment || [],
      highestPoint: data?.highestPoint || '',
      location: data?.location || '',
      maxGroupSize: data?.maxGroupSize || '',
      mountainRange: data?.mountainRange || ' ',
      pricePerPerson: data?.pricePerPerson || '',
      placesToSee: data?.placesToSee || [],
      prices: data?.prices || [],
      refundConditions: data?.refundConditions || [],
      tourSchedule: data?.tourSchedule || [],
      tourThumb: data?.tourThumb || '',
      tourType: data?.tourType || '',
    },
    onSubmit: (values) => {
      mutate({ tourId, data: values });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return {
    data,
    formik,
    isEditingTour,
  };
};
