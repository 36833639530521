import { User } from '../pages/user';
import { UserDetails } from '../pages/user-details';
import { Users } from '../pages/users';

const usersRouter = {
  path: 'users',
  children: [
    {
      path: '',
      element: <Users />,
    },
    {
      path: ':userId',
      element: <UserDetails />,
    },
    {
      path: 'new-user',
      element: <User />,
    },
    {
      path: 'edit-user/:userId',
      element: <User />,
    },
  ],
};

export default usersRouter;
