import { Outlet } from 'react-router';
import { InventoriesContainer } from '../containers/inventories-container';

export function Inventories() {
  return (
    <>
      <InventoriesContainer />
      <Outlet />
    </>
  );
}
