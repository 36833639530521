import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteTour } from '../hooks/use-delete-tour';

export const TourTableButtons = ({ tourId }) => {
  const { dispatch } = useContext(ConfirmationModalContext);
  const { mutate } = useDeleteTour();

  const { t } = useTranslation();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('actionButtons.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/tours/${tourId}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.edit')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/tours/edit/${tourId}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.archive')} arrow>
          <IconButton onClick={() => {}} color="secondary">
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.delete')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    mutate(tourId);
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: 'modal.delete',
                  isOpen: true,
                  buttonMessage: 'actionButtons.delete',
                  color: 'error',
                },
              });
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
