import { Grid, Box, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Manat from 'src/assets/icon/manat';
import moment from 'moment';
import { DATA_TYPES } from 'src/shared/costants/constants';
import Label from 'src/shared/components/Label';
import { IRental } from '../types';

export const RentalDetailsForm = ({ data }: { data: IRental }) => {
  const { t } = useTranslation();

  const getRentalDateLabel = () => {
    const roundedDays = (
      (new Date(data?.endDate).getTime() - Date.now()) /
      86400000
    ).toFixed(0);

    switch (true) {
      case roundedDays === '0':
        return (
          <Label color={'warning'}>
            {roundedDays} {t('daysLeft')}
          </Label>
        );
      case roundedDays <= '0':
        return (
          <Label color={'error'}>
            {roundedDays} {t('timeIsUp')}
          </Label>
        );
      default:
        return (
          <Label color={'success'}>
            {roundedDays} {t('daysLeft')}
          </Label>
        );
    }
  };
  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.rentalCode')}: {data?.code}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.customer')}:{' '}
              <IconButton
                component={RouterLink}
                to={`/dashboard/users/${data?.customer.id}`}
                color="primary"
                sx={{ fontSize: '14px', textDecoration: 'underline' }}
              >
                {data?.customer.fullName}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.exacutedBy')}:{' '}
              <IconButton
                component={RouterLink}
                to={`/dashboard/users/${data?.exacutedBy.id}`}
                color="primary"
                sx={{ fontSize: '14px', textDecoration: 'underline' }}
              >
                {data?.exacutedBy.fullName}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.referal')}:{' '}
              <IconButton
                component={RouterLink}
                to={`/dashboard/users/${data?.referal.id}`}
                color="primary"
                sx={{ fontSize: '14px', textDecoration: 'underline' }}
              >
                {data?.referal.fullName}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.startDate')}:{' '}
              {moment(data?.startDate).format(DATA_TYPES.mdy)}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.endDate')}:{' '}
              {moment(data?.endDate).format(DATA_TYPES.mdy)}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.inventories')}:{' '}
              {data?.inventories.map((inventory) => inventory.title).join(', ')}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.rentalDate')}: {getRentalDateLabel()}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {t('inputs.labels.totalCost')}: {data?.totalCost} <Manat />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
