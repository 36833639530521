import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptions } from 'src/shared/components/generic-table/types';
import { AdditionalInfosTableActionButtons } from '../components/action-buttons';
import { IAdditionalInfo } from '../types';

export const useAdditionalInfosTable = (): [any, any, any] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 25,
    pageLimits: [5, 25, 50],
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('additionalInfos.title'),
      href: '/dashboard/additionalInfos',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'text',
        align: 'left',
        label: t('table.name'),
        extructor: (item : IAdditionalInfo) => item.text,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item : IAdditionalInfo) => {
          return (
            <AdditionalInfosTableActionButtons additionalInfoId={item.id} />
          );
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
