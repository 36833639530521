import { Transaction } from '../pages/transaction';
import { Transactions } from '../pages/transactions';
import { TransactionDetails } from '../pages/transaction-details';

const transactionsRouter = {
  path: 'transactions',
  children: [
    {
      path: '',
      element: <Transactions />,
      children: [],
    },
    {
      path: ':transactionId',
      element: <TransactionDetails />,
    },
    {
      path: 'add-transaction',
      element: <Transaction />,
    },
    {
      path: 'add-expense',
      element: <Transaction />,
    },
    {
      path: 'edit/:transactionId',
      element: <Transaction />,
    },
  ],
};

export default transactionsRouter;
