import { Box, Grid, Typography, Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IEvent } from 'src/modules/events/types';
import { ITinyUser } from 'src/modules/users/types';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { IParticipant } from '../types';

export const EditTaskForm = ({
  formik,
  isEditingTask,
  eventsData,
  usersData,
  participantsData,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            fullWidth={true}
            options={eventsData || []}
            disablePortal
            value={
              eventsData && formik.values.event
                ? eventsData.find(
                    (event: IEvent) => event.id === formik.values.event,
                  )
                : null
            }
            onChange={(_e, newValue: IEvent) => {
              formik.setFieldValue('event', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: IEvent) => {
              return `${option.title} ${option.id}`;
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} label={t('event.title')} />
            )}
          />
          {!!formik.errors.event && formik.touched.event && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.event}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            fullWidth={true}
            options={usersData || []}
            disablePortal
            value={
              usersData && formik.values.assignedTo
                ? usersData.find(
                    (user: ITinyUser) => user.id === formik.values.assignedTo,
                  )
                : null
            }
            onChange={(_e, newValue: ITinyUser) => {
              formik.setFieldValue('assignedTo', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: ITinyUser) => {
              return `${option.fullName} (${option.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.whoCompletesTheTask')}
              />
            )}
          />
          {!!formik.errors.assignedTo && formik.touched.assignedTo && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.assignedTo}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            fullWidth={true}
            options={participantsData || []}
            disablePortal
            disabled={isLoading}
            onChange={(_e, newValue: IParticipant) => {
              formik.setFieldValue(
                'participant',
                newValue ? newValue.userId.id : '',
              );
            }}
            getOptionLabel={(option: IParticipant) => {
              return `${option.userId.fullName} (${option.userId.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.participant')}
              />
            )}
          />
          {!!formik.errors.participant && formik.touched.participant && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.participant}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmDateTimePicker
            inputFormat="dd/MM/yyyy"
            formik={formik}
            label={t('inputs.labels.endDate')}
            name="deadline"
          />
          {!!formik.errors.deadline && formik.touched.deadline && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.deadline}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <SubmitButton
          load={isEditingTask}
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t('buttons.edit')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
