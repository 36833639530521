import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { TagActionButtons } from '../components/action-buttons';
import { useTranslation } from 'react-i18next';
import { ITag } from '../types';

export const useTagsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tags.title'),
      href: '/dashboard/tags',
    },
  ];
  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: t('table.name'),
        align: 'left',
        label: t('table.name'),
        extructor: (tag: ITag) => tag.label,
      },
      {
        id: t('table.description'),
        align: 'left',
        label: t('table.description'),
        extructor: (tag: ITag) => tag.description,
      },
      {
        id: t('table.actions'),
        align: 'right',
        label: t('table.actions'),
        extructor: (tag: ITag) => {
          return <TagActionButtons tagId={tag.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
