import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getEquipments = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/equipments`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addEquipment = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/equipments`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getEquipment = async (
  equipmentId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/equipments/${equipmentId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteEquipment = async (
  equipmentId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/equipments/${equipmentId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editEquipment = async ({
  equipmentId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/equipments/${equipmentId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
