import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useActions } from '../hooks/use-actions';
import { useActionsTable } from '../hooks/use-actions-table';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const ActionsContainer = () => {
  const { t } = useTranslation();

  const { data } = useActions();

  const [columns, options, routes] = useActionsTable();

  return (
    <PageWrapper
      routes={routes}
      title={t('actions.title')}
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/actions/add-action"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addAction')}
        </Button>
      }
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
