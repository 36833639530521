import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';

export const EditTourForm = ({ formik, isEditingTour }) => {
  const { t } = useTranslation();

  const typeData = [
    {
      value: 'event',
      name: t('event.title'),
    },
    {
      value: 'participant',
      name: t('events.participant.title'),
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.beforeEventDays')}
            name="beforeEventDays"
            type="number"
            onWheel={(e: any) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.type')}
            name="type"
            options={typeData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.labels.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <SubmitButton
          load={isEditingTour}
          sx={{ width: { xs: '100%', md: 200 } }}
        >
          {t('buttons.edit')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
