import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getAdditionalInfos = async (query): Promise<AxiosResponse> => {
  const [, page, limit, title] = query.queryKey;

  const queryObj: any = {};

  if (page) {
    queryObj.page = Number(page) + 1;
  }
  if (limit) {
    queryObj.limit = limit;
  }
  if (title) {
    queryObj.text = title;
  }

  const queryString = '?' + new URLSearchParams(queryObj).toString();

  try {
    return await axios.get(`/api/v1/additional-infos${queryString}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getAdditionalInfo = async (
  additionalInfoId,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/additional-infos/${additionalInfoId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createAdditionalInfo = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/additional-infos`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editAdditionalInfo = async ({
  additionalInfoId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(
      `/api/v1/additional-infos/${additionalInfoId}`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const deleteAdditionalInfo = async (
  additionalInfoId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/additional-infos/${additionalInfoId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
