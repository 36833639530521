import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddEquipmentForm } from '../components/add-equipment-form';

export const AddEquipmentContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('equipments.title'),
      href: '/dashboard/equipments',
    },
    {
      label: t('equipments.addEquipment'),
      href: '/dashboard/equipments/add-equipment',
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('equipments.addEquipment')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddEquipmentForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
