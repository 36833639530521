import {
  Box,
  Grid,
  InputAdornment,
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';
import { OmInput, OmSelect } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddRepeatingTransaction } from '../hooks/use-add-repeating-transaction';
import Manat from 'src/assets/icon/manat';
import { ITinyUser } from 'src/modules/users/types';

export const AddRepeatingTransactionForm = () => {
  const { formik, isLoading, usersData } = useAddRepeatingTransaction();
  const { t } = useTranslation();

  const paymentMethod = [
    {
      value: 'cash',
      name: t('cash'),
    },
    {
      value: 'online',
      name: t('online'),
    },
  ];

  const typeTransaction = [
    {
      value: 'income',
      name: t('income'),
    },
    {
      value: 'expense',
      name: t('expense'),
    },
  ];

  const typePeriod = [
    {
      value: '* * */30 * *',
      name: t('oneMonthPeriod'),
    },
    {
      value: '* * */90 * *',
      name: t('threeMonthPeriod'),
    },
  ];

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.amount')}
            name="amount"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            endAdornment={
              <InputAdornment position="end">
                <Manat />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.paymentMethod')}
            name="paymentMethod"
            options={paymentMethod || []}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.type')}
            name="type"
            options={typeTransaction || []}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={usersData || []}
            disablePortal
            disabled={false}
            value={
              formik.values.spender
                ? usersData?.find((user) => user.id === formik.values.spender)
                : null
            }
            onChange={(_e, newValue: ITinyUser) => {
              formik.setFieldValue('spender', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: ITinyUser) => {
              return `${option.fullName} (${option.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.spender')}
              />
            )}
          />
          {!!formik.errors.spender && formik.touched.spender && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.spender}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={usersData || []}
            disablePortal
            disabled={false}
            value={
              formik.values.recipient
                ? usersData?.find(
                    (user: ITinyUser) => user.id === formik.values.recipient,
                  )
                : null
            }
            onChange={(_e, newValue: ITinyUser) => {
              formik.setFieldValue('recipient', newValue ? newValue.id : '');
            }}
            getOptionLabel={(option: ITinyUser) => {
              return `${option.fullName} (${option.code})`;
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t('inputs.labels.recipient')}
              />
            )}
          />
          {!!formik.errors.recipient && formik.touched.recipient && (
            <Typography
              sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
            >
              {formik.errors.recipient}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmSelect
            formik={formik}
            label={t('inputs.labels.period')}
            name="period"
            options={typePeriod || []}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OmInput
            multiline={true}
            rows={3}
            formik={formik}
            label={t('inputs.labels.description')}
            name="description"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <SubmitButton load={isLoading} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.add')}
        </SubmitButton>
      </Grid>
    </Box>
  );
};
