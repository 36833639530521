import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { CouponForm } from '../components/coupon-form';
import { useCouponForm } from '../hooks/use-coupon-form';

export const CouponContainer = () => {
  const { t } = useTranslation();

  const {
    data,
    eventsData,
    formik,
    isAddingCoupon,
    isGettingEvents,
    isEditingCoupon,
    isGettingCouponCode,
  } = useCouponForm();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('coupons.title'),
      href: '/dashboard/coupons',
    },
    {
      label: data ? data?.code : t('coupons.addCoupon'),
      href: data
        ? `/dashboard/coupons/${data?.id}`
        : '/dashboard/coupons/add-coupon',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data ? data?.code : t('coupons.addCoupon')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <CouponForm
            data={data}
            eventsData={eventsData}
            formik={formik}
            isAddingCoupon={isAddingCoupon}
            isGettingEvents={isGettingEvents}
            isEditingCoupon={isEditingCoupon}
            isGettingCouponCode={isGettingCouponCode}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
