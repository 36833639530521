const enJSON = {
  users: {
    title: 'users',
    addUser: 'add user',
  },
  user: {
    title: 'user',
  },
  coupons: {
    title: 'coupons',
    addCoupon: 'add coupon',
    couponCode: 'kupon kodu',
  },
  certificates: {
    title: 'certificates',
    addCertificate: 'add certificate',
    certificateCode: 'certificate code',
  },
  equipments: {
    title: 'equipments',
    addEquipment: 'add equipment',
    equipmentName: 'equipment name',
  },
  inventories: {
    title: 'inventories',
    addInventory: 'add inventory',
    inventoryName: 'inventory name',
    addImage: {
      tooltip: 'add image',
    },
  },
  rentals: {
    title: 'rentals',
    addRental: 'add rental',
    rentalCode: 'rental code',
  },
  additionalInfos: {
    title: 'əlavə məlumat',
    addAdditionalInfo: 'əlavə məlumat əlavə et',
  },
  services: {
    title: 'xidmətlər',
    addService: 'xidmət əlavə et',
  },
  homework: {
    title: 'homework',
  },
  events: {
    title: 'events',
    person: {
      tooltip: 'complete by someone else',
    },
    info: {
      tooltip: 'details',
    },
    groupDetails: {
      newLesson: 'new lesson',
    },
    participants: {
      title: 'participaninventoriests',
    },
    participant: {
      title: 'participant',
    },
    addActionToEvent: 'action to event',
    addTaskToEvent: 'add task to event',
    addTaskToParticipant: 'task to participant',
    activitiesOnTheTour: 'activities on the tour',
  },
  event: {
    title: 'event',
    addEvent: 'add event',
    name: 'event name',
    guides: 'guides',
  },
  general: {
    title: 'general',
  },
  equipment: {
    title: 'equipment',
  },
  tours: {
    title: 'tours',
    addTour: 'add tour',
    eventTitle: 'tədbirin başlığı',
  },
  addExpense: {
    title: 'add expense',
  },
  transactions: {
    title: 'transactions',
  },
  transaction: {
    title: 'transaction',
  },
  actions: {
    title: 'actions',
    actionName: 'action name',
  },
  action: {
    title: 'action',
  },
  tasks: {
    title: 'tasks',
    addTask: 'add task',
    taskName: 'task name',
    newTask: 'new task',
  },
  task: {
    title: 'task',
  },
  repeatingTransactions: {
    title: 'repeating transactions',
    addRepeatingTransaction: 'add repeating transactions',
  },
  internalTransactions: {
    title: 'internal transactions',
  },
  internalTransactionDetails: {
    title: 'internal transaction details',
  },
  statsPage: {
    title: 'stats',
    internalBalance: 'internal balance',
  },
  popover: {
    title: 'profile',
    signOut: 'sign out',
    lessonMode: 'lesson mode',
  },
  addTransaction: {
    title: 'add transaction',
  },
  pages: {
    home: 'home',
  },
  lessons: {
    newLesson: {
      newLesson: 'newLesson',
      lessonDetails: 'lesson details',
      homeworkWillBeAddedLater: 'homework will be added later',
      students: {
        students: 'students',
        fullName: 'full name',
        actions: 'actions',
        studentsNotFound: 'students not found',
      },
      homework: {
        homework: 'homework',
        title: 'title',
        link: 'link',
        percentage: 'percentage',
        actions: 'actions',
        homeworkNotFound: 'homework not found',
      },
    },
  },
  heading: {
    general: 'general',
    management: 'management',
  },
  accounting: {
    title: 'accounting',
    invoiceDetails: {
      title: 'invoice',
      issuedOn: 'issued on',
      issuedBy: 'issued by',
      teacherBalanceBeforePayment: 'balance before payment',
      teacherBalanceAfterPayment: 'balance after payment',
    },
  },
  profile: {
    title: 'profile',
    changeCover: 'change cover',
    tabs: {
      myAccount: 'my account',
      aboutMe: 'about me',
    },
  },
  inputs: {
    labels: {
      changePassword: 'change password',
      changeEmail: 'change email',
      currentPassword: 'current password',
      password: 'password',
      passwordConfirm: 'password confirm',
      email: 'email',
      name: 'name',
      filter: 'filter',
      surname: 'surname',
      gender: 'gender',
      fullName: 'full name',
      numberOne: 'number one',
      numberTwo: 'number two',
      university: 'university',
      interests: 'interests',
      dateOfBirth: 'date of birth',
      fatherName: 'father name',
      number: 'number',
      biography: 'biography',
      price: 'price',
      permissionName: 'permission name',
      description: 'description',
      search: 'search',
      code: 'code',
      slug: 'code (slug)',
      tag: 'tag name',
      category: 'category',
      amount: 'amount',
      type: 'type',
      beforeEventDays: 'before event days',
      action: 'action name',
      task: 'task name',
      eventTitle: 'tədbirin başlığı',
      aboutTour: 'about tour',
      additionalInfo: 'additional info',
      country: 'country',
      days: 'days',
      daysToDeadline: 'days to deadline',
      difficulty: 'çətinlik',
      equipmentName: 'equipment name',
      brand: 'brand',
      model: 'model',
      size: 'size',
      color: 'color',
      condition: 'condition',
      forSell: 'for sell',
      forRent: 'for rent',
      inventoryType: 'inventory type',
      quantity: 'quantity',
      discount: 'discount',
      purchasePrice: 'purchase price',
      sellingPrice: 'selling price',
      rentPrice: 'rent price',
      inventoryName: 'inventory name',
      rentalCode: 'rental code',
      totalCost: 'total cost',
      startDate: 'start date',
      endDate: 'end date',
      registrationEndDate: 'registration end date',
      finishDate: 'bitirmə tarixi',
      customer: 'customer',
      referal: 'referal',
      exacutedBy: 'which fulfills',
      user: 'user',
      certificateCode: 'certificate code',
      createdBy: 'created',
      allUsers: 'all users',
      whoCompletesTheTask: 'who completes the task',
      searchUser: 'search the user',
      rentalDate: 'rental date',
      inventories: 'inventories',
      recipient: 'recipient',
      spender: 'spender',
      paymentMethod: 'payment method',
      period: 'period',
      duration: 'duration',
      groupSize: 'the group size',
      relatedEvent: 'related event',
      phoneNumber: 'phone number',
      tour: 'tour',
      permissions: 'permissions',
      assignedTo: 'responsible person',
      participant: 'participant',
      event: 'event',
      eventLanguages: 'language',
      isPercent: 'is percent',
      isRent: 'is rent',
      precent: 'precent',
      allEvents: 'all events',
      events: 'events',
      couponCode: 'kupon kodu',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'confirm',
    importExcelFile: 'import excel file',
    add: 'add',
    addEvent: 'add event',
    addUser: 'add user',
    addHomework: 'add homework',
    delete: 'delete',
    cancel: 'cancel',
    archive: 'archive',
    addPermission: 'add permission',
    edit: 'edit',
    addTag: 'add tag',
    closeWindow: 'close the window',
    addExpense: 'add expense',
    addTransaction: 'add transaction',
    addAction: 'add action',
    addNewAction: 'add new action',
    addTask: 'add task',
    addNewTask: 'add new task',
    addNewTaskToParticipants: 'add new task to participants',
    addTour: 'add new tour',
    addEquipment: 'add new equipment',
    addNewEventTask: 'add a task to the event',
    addInventory: 'add inventory',
    addRental: 'add rental',
    addCertificate: 'add certificate',
    addRepeatingTransaction: 'add repeating transaction',
    addCategory: 'add category',
    addCoupon: 'add coupon',
    addAdditionalInfo: 'add additional info',
    addService: 'add service',
  },
  yup: {
    currentPassword: 'current password is required',
    password: {
      required: 'password is required',
      min: 'password is required',
    },
    passwordConfirm: {
      required: 'confirm password is required.',
      correctly: 'does not match the repeat password',
    },
    email: {
      required: 'email is required',
      correctly: 'email not entered correctly',
    },
    inputs: {
      name: 'name is required',
      surname: 'surname is required',
      gender: 'gender is required',
      university: 'university is required',
      subject: 'subject is required',
      room: 'room is required',
      lessonStarting: 'lesson starting is required',
      lessonEnding: 'lesson ending is required',
      duration: 'duration is required',
      title: 'title is required',
      percentage: 'percentage is required',
      link: 'link is required',
      biography: 'biography is required',
      fatherName: 'father name is required',
      dateOfBirth: 'date of birth is required',
      price: 'price is required',
      participant: 'participant is required',
      permissionName: 'permission name is required',
      description: 'permission description is required',
      permissionSlug: 'permission code (slug) is required',
      amount: 'amount is required',
      task: 'task name is required',
      tag: 'tag is required',
      action: 'action name is required',
      eventTitle: 'tour name is required',
      brand: 'brand is required',
      quantity: 'quantity is required',
      discount: 'discount is required',
      purchasePrice: 'purchasePrice is required',
      sellingPrice: 'sellingPrice is required',
      rentPrice: 'rentPrice is required',
      customer: 'customer is required',
      totalCost: 'total cost is required',
      totalCostMin: 'the amount must be a minimum of 1',
      startDate: 'start date is required',
      endDate: 'end date is required',
      exacutedBy: 'which fulfills is required',
      spender: 'spender is required',
      recipient: 'recipient is required',
      groupSize: 'the group size is required',
      tour: 'tour is required',
      category: 'category is required',
      precent: 'precent is required',
      minPercent: 'the minimum percentage can be 0',
      maxPercent: 'the maximum percentage can be 100',
      events: 'events is required',
      relatedEvent: 'related event is required',
    },
  },

  subject: 'subject',
  room: 'room',
  lessonStarting: 'lesson starting',
  lessonEnding: 'lesson ending',
  duration: 'duration',
  description: 'description',
  title: 'title',
  percentage: 'percentage',
  link: 'link',
  comments: 'comments',

  login: {
    signIn: 'Sign In',
    info: 'fill in the fields below to sign into your account.',
    lostPassword: 'lost password ?',
    signUp: 'sign up here',
  },
  recoverPassword: {
    title: 'recover password',
    info: 'enter the email used for registration to reset your password',
    sendPassword: 'send me a new password',
    signInAgain: 'want to try to sign in again?',
    clickHere: 'click here',
  },
  languageSwitcher: {
    title: 'language',
    tooltip: 'switch language',
  },
  amount: 'amount',
  modal: {
    delete: 'are you sure you want to delete?',
    archive: 'are you sure you want to archive?',
    amountAfterDiscount: 'amount after discount',
    addAmount: 'add amount',
    makePayment: 'make payment',
    allTasksOfParticipant: 'all tasks of participant',
    addNewAction: 'add new action',
    addNewTask: 'add new task',
    addNewParticipantTask: 'add a task for participants',
    addNewEventTask: 'add a task to the event',
    details: 'details',
    addPhoto: 'add photo',
    addInfoToTask: 'add information to the task',
  },
  table: {
    name: 'name',
    category: 'category',
    type: 'type',
    amount: 'amount',
    description: 'description',
    fullName: 'full name',
    actions: 'actions',
    code: 'code',
    tasks: 'tasks',
    beforeEventDays: 'before event days',
    brand: 'brand',
    model: 'model',
    color: 'color',
    size: 'size',
    quantity: 'quantity',
    discount: 'discount',
    purchasePrice: 'purchase price',
    sellingPrice: 'selling price',
    rentPrice: 'rent price',
    totalCost: 'total cost',
    user: 'user',
    created: 'created',
    rentalDate: 'rental date',
    customer: 'customer',
    recipient: 'recipient',
    spender: 'spender',
    phoneNumber: 'phone number',
    event: 'tədbir',
    participant: 'participant',
    assignedTo: 'responsible person',
    deadline: 'deadline',
    endDate: 'end date',
    slug: 'code (slug)',
    isRent: 'icarədir',
  },
  permissions: {
    title: 'permissions',
    premissionName: 'permission name',
    premissonSlug: 'code (slug)',
    premissonDescription: 'description',
    addPermission: 'add permission',
  },
  managePermissions: {
    title: 'manage permissions',
  },
  manageTags: {
    title: 'manage tags',
  },
  categories: {
    title: 'categories',
    categoryName: 'category name',
    addCategory: 'add category',
  },
  appManager: {
    title: 'app manager',
  },
  tags: {
    title: 'tags',
    tagName: 'tag name',
    tagDescription: 'description',
    addTag: 'add tag',
  },
  actionButtons: {
    view: 'view',
    delete: 'delete',
    archive: 'archive',
    discount: 'discount',
    addAmount: 'amount',
    edit: 'edit',
    confirm: 'confirm',
    allTasks: 'all tasks',
    add: 'add',
    selectUser: 'select user',
    endDate: 'end date',
  },
  noResultFound: 'no result found',
  timeIsUp: 'time is up!',
  daysLeft: 'days left',
  cash: 'cash',
  online: 'online',
  income: 'income',
  expense: 'expense',
  oneMonthPeriod: '1 in 1 month',
  threeMonthPeriod: '1 in 3 months',
  female: 'female',
  male: 'male',
  unknown: 'unknown',
  other: 'other',
  messages: {
    noData: 'no data',
    archiveInventory: 'archive inventory',
    successfulProcess: 'process was successfull',
    success: 'success',
    unknownError: 'unknown error',
  },
  tooltips: {
    view: 'view',
    edit: 'edit',
    archive: 'archive',
  },
  options: {
    bad: 'bad',
    good: 'good',
    great: 'great',
  },
};

export default enJSON;
