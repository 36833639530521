import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getAdditionalInfo } from '../services';
import { useParams } from 'react-router';
import { IAdditionalInfo } from '../types';

export const useAdditionalInfoDetails = () => {
  const { additionalInfoId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-additional-info', additionalInfoId],
    () => {
      if (additionalInfoId) {
        return getAdditionalInfo(additionalInfoId);
      }
    },
    {
      select: ({ data }): IAdditionalInfo => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
