import { useLocation } from 'react-router-dom';
import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { TransactionForm } from '../components/transaction-form';
import { useTransactionForm } from '../hooks/use-transaction-form';

export const TransactionContainer = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const {
    data,
    eventsData,
    formik,
    isAddingTransaction,
    isEditingTransaction,
    usersData,
  } = useTransactionForm();

  const transactionRoute =
    (location.pathname.includes('add-expense') && 'addExpense.title') ||
    (location.pathname.includes('add-transaction') && 'addTransaction.title');

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('transactions.title'),
      href: '/dashboard/transactions',
    },
    {
      label: data ? data?.code : transactionRoute ? t(transactionRoute) : '',
      href: '/dashboard/transactions',
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={data ? data?.code : transactionRoute ? t(transactionRoute) : ''}
    >
      <Card sx={{ p: 3 }}>
        <Grid container>
          <TransactionForm
            data={data}
            eventsData={eventsData}
            formik={formik}
            isAddingTransaction={isAddingTransaction}
            isEditingTransaction={isEditingTransaction}
            usersData={usersData}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
