import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { OmInput } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';

export const AdditionalInfoForm = ({
  data,
  formik,
  isAddingAdditionalInfo,
  isEditingAdditionalInfo,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.name')}
              name="text"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.description')}
              name="description"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <SubmitButton
            load={isAddingAdditionalInfo || isEditingAdditionalInfo}
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {data ? t('buttons.edit') : t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
    </>
  );
};
