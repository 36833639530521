import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { CategoryDetailsForm } from '../components/category-details-form';
import { useCategory } from '../hooks/use-category';

export const CategoryDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useCategory();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('categories.title'),
      href: '/dashboard/categories',
    },
    {
      label: data?.name ? data?.name : '',
      href: `/dashboard/categories/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('categories.categoryName')}: ${data?.name ? data?.name : ''}`}
    >
      <Helmet>
        <title>{`${t('categories.categoryName')} | ${
          data?.name ? data?.name : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <CategoryDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
