import { Button, Grid, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const EventAllButtons = () => {
  const { t } = useTranslation();

  const { eventId } = useParams();

  return (
    <>
      <Grid item xs={12} sm={12} md={6}>
        <Card
          sx={{
            p: 3,
            gap: 1.5,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Button
            component={RouterLink}
            to={`/dashboard/events/${eventId}/add-new-task`}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('buttons.addNewTaskToParticipants')}
          </Button>
        </Card>
      </Grid>
    </>
  );
};
