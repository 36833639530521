import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { TInventoriesRoot } from '../types';
import { getInventories } from '../services';

export const useInventories = function () {
  const { data, isLoading } = useQuery('inventories-get-all', getInventories, {
    select: ({ data }: { data: TInventoriesRoot }) => {
      if (data?.status === 'success') {
        return { data: data?.data, total: data?.total };
      }
    },
    onError: queryError,
  });
  return { data, isLoading };
};
