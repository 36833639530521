import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { editTag, getTag } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ITag } from '../types';

export const useEditTag = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { tagId } = useParams();

  const navigate = useNavigate();

  const { data } = useQuery(
    ['get-tag', tagId],
    () => {
      if (tagId) {
        return getTag(tagId);
      }
    },
    {
      select: ({ data }): ITag => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { mutate, isLoading: isEditingTag } = useMutation('edit-tag', editTag, {
    onSuccess: (e: any) => {
      if (e.status === 200) {
        handleMessage(true, 'Process was successful', 'success');
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(t('yup.inputs.tag')),
  });

  const formik = useFormik({
    initialValues: {
      label: data?.label || '',
      description: data?.description || '',
    },
    onSubmit: (values) => {
      mutate({ tagId, data: values });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  return {
    data,
    formik,
    isEditingTag,
  };
};
