import { Link } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useEventsTable } from '../hooks/use-events-table';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../hooks/use-events';

export const EventsContainer = () => {
  const { t } = useTranslation();

  const { data } = useEvents();

  const [columns, options, routes] = useEventsTable();

  return (
    <PageWrapper
      action={
        <Link
          to="/dashboard/events/new-event"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addEvent')}</Button>
        </Link>
      }
      routes={routes}
      title={t('events.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
