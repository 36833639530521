import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerUserToEvent } from '../services';
import { useContext } from 'react';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useAddParticipant = () => {
  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const queryClient = useQueryClient();

  const { eventId } = useParams();

  const validationSchema = Yup.object().shape({
    userIds: Yup.array().required(t('yup.inputs.participant')),
  });

  const formik = useFormik({
    initialValues: {
      userIds: [],
    },
    onSubmit: (values) => {
      if (values.userIds.length > 0) {
        addParticipant({ userIds: values.userIds, eventId });
      } else {
        handleMessage(true, t('yup.inputs.participant'), 'error');
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate: addParticipant, isLoading: isCreating } = useMutation(
    'register-user-to-event',
    registerUserToEvent,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          queryClient.refetchQueries('get-event');
          formik.resetForm();
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    formik,
    isCreating,
  };
};
