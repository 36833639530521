import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { useTranslation } from 'react-i18next';
import { ManagePermissionsActionButtons } from '../components/action-buttons';
import { IPermission } from 'src/modules/permissions/types';

export const useManagePermissionsTable = (
  formik,
): [IGenericTableColumns, IOptions, IBreadcrumbRoutes] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('managePermissions.title'),
      href: '/dashboard/users/manage-permissions',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: t('table.name'),
        align: 'left',
        label: t('table.name'),
        extructor: (permission: IPermission) => permission.name,
      },
      {
        id: t('table.actions'),
        align: 'right',
        label: t('table.actions'),
        extructor: (permission: IPermission) => {
          return (
            <ManagePermissionsActionButtons
              permission={permission}
              formik={formik}
            />
          );
        },
      },
    ];
    return columns;
  }, [formik, t]);

  return [columns, options, routes];
};
