import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import SubmitButton from 'src/shared/components/Submit-button';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import { useAddTourForm } from '../hooks/use-add-tour-form';

export const AddTourForm = () => {
  const { t } = useTranslation();

  const { formik } = useAddTourForm();

  const addNewAdditionalNotes = () => {
    formik.setFieldValue('additionalInfo', [
      ...formik.values.additionalInfo,
      '',
    ]);
  };

  const removeAdditionalNotes = (index: number) => {
    const newAdditionalNotes = [...formik.values.additionalInfo];
    newAdditionalNotes.splice(index, 1);
    formik.setFieldValue('additionalInfo', newAdditionalNotes);
  };

  return (
    <Grid item xs={12}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.tourName')}
              name="title"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.country')}
              name="country"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.days')}
              name="days"
              type="number"
              onWheel={(e: any) => e.target.blur()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.daysToDeadline')}
              name="daysToDeadline"
              type="number"
              onWheel={(e: any) => e.target.blur()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.difficulty')}
              name="difficulty"
            />
          </Grid>
          <Grid container item spacing={2} xs={12}>
            {formik.values.additionalInfo.map((item, i: number) => (
              <Grid item xs={12} key={i} sx={{ position: 'relative' }}>
                <OmInput
                  formik={formik}
                  label={t('inputs.labels.additionalInfo')}
                  name={`additionalInfo[${i}]`}
                />
                {i > 0 && (
                  <Box sx={{ position: 'absolute', top: '5px', right: '-7px' }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => removeAdditionalNotes(i)}
                      sx={{
                        minWidth: '10px !important',
                        height: '10px !important',
                        p: '10px !important',
                      }}
                    >
                      x
                    </Button>
                  </Box>
                )}
              </Grid>
            ))}
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 2, width: '100%' }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={addNewAdditionalNotes}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OmInput
              formik={formik}
              label={t('inputs.labels.aboutTour')}
              name="aboutTour"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <SubmitButton
            load={false}
            sx={{ width: { xs: '100%', md: 200 }, mt: 2 }}
          >
            {t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
    </Grid>
  );
};
