import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import { useServices } from '../hooks/use-services';
import { useServicesTable } from '../hooks/use-services-table';

export const ServicesContainer = () => {
  const { t } = useTranslation();

  const { data } = useServices();

  const [columns, options, routes] = useServicesTable();

  return (
    <PageWrapper
      action={
        <Link
          to="/dashboard/services/add-service"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addService')}</Button>
        </Link>
      }
      routes={routes}
      title={t('services.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
