import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Manat from 'src/assets/icon/manat';
import Label from 'src/shared/components/Label';
import { ITransaction } from '../types';

export const TransactionDetailsDorm = ({ data }: { data: ITransaction }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.name')}: {data?.title}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.category')}: {data?.category}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.type')}:{' '}
              <Label color={data?.type === 'income' ? 'success' : 'error'}>
                {data?.type}
              </Label>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {t('inputs.labels.amount')}:{' '}
              <Typography
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={0.5}
              >
                {data?.amount}
                <Manat />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.description')}: {data?.description}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
