import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useRepeatingTransaction } from '../hooks/use-repeating-transaction';
import { RepeatingTransformDetailsForm } from '../components/repeating-transforms-details-form';

export const RepeatingTransactionDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useRepeatingTransaction();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('repeatingTransactions.title'),
      href: '/dashboard/repeating-transactions',
    },
    {
      label: data?.title ? data?.title : '',
      href: `/dashboard/repeating-transactions/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('repeatingTransactions.title')}: ${
        data?.title ? data?.title : ''
      }`}
    >
      <Helmet>
        <title>{`${t('repeatingTransactions.title')} | ${
          data?.title ? data?.title : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <RepeatingTransformDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
