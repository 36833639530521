import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddPermissionForm } from '../components/add-permission-form';
import { useTranslation } from 'react-i18next';

export const AddPermissionContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('permissions.title'),
      href: '/dashboard/permissions',
    },
    {
      label: t('permissions.addPermission'),
      href: '/dashboard/add-permission',
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('permissions.addPermission')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddPermissionForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
