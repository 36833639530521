import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createEvent, getGuides } from '../services';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { getTours } from 'src/modules/tours/services';
import { addNewEventMapper } from '../mappers';
import { TGuideRoot } from '../types';
import AuthContext from 'src/contexts/JWTAuthContext';
import { ITour, ITourAction, TToursRoot } from 'src/modules/tours/types';

export const useAddEventForm = () => {
  const { t } = useTranslation();
  const [foundTour, setFoundTour] = useState([]);

  const { handleMessage } = useContext(AlertContext);
  const {
    user: { id: userId },
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const { data: guidesData } = useQuery('get-guides', getGuides, {
    select: ({ data }: { data: TGuideRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: toursData, isLoading } = useQuery('get-tours', getTours, {
    select: ({ data }: { data: TToursRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate: createNewEvent, isLoading: isCreating } = useMutation(
    'create-event',
    createEvent,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          formik.resetForm();
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationTour = Yup.object().shape({
    tour: Yup.string().required(t('yup.inputs.tour')),
    eventTitle: Yup.string().required(t('yup.inputs.eventTitle')),
    pricePerPerson: Yup.number().required(t('yup.inputs.price')),
    groupSize: Yup.number().required(t('yup.inputs.groupSize')),
    duration: Yup.number().required(t('yup.inputs.duration')),
    date: Yup.string().required(t('yup.inputs.date')),
    guides: Yup.array().min(1, t('yup.inputs.guides')),
    // assignedTo: Yup.string().required(t('yup.inputs.participant')),
    // deadline: Yup.string().required(t('yup.inputs.endDate')),
  });

  const formik = useFormik({
    initialValues: {
      tour: '',
      eventTitle: '',
      date: '',
      pricePerPerson: process.env.REACT_APP_ENV === 'development' ? '40' : '',
      guides: [],
      tasks: [],
      assignedTo: '',
      deadline: process.env.REACT_APP_ENV === 'development' ? '7' : '',
      groupSize: process.env.REACT_APP_ENV === 'development' ? '18' : '',
      eventLanguages: ['az'],
      duration: process.env.REACT_APP_ENV === 'development' ? '1' : '',
    },
    onSubmit: (values) => {
      const addNewEventBody = addNewEventMapper(values, userId);
      createNewEvent(addNewEventBody);
    },
    validationSchema: validationTour,
  });

  useEffect(() => {
    if (formik.values.tour) {
      const foundTour = toursData.filter(
        (tour: ITour) => tour.id === formik.values.tour,
      );
      return setFoundTour(foundTour);
    } else {
      setFoundTour([]);
    }
  }, [formik.values.tour]);

  useEffect(() => {
    if (foundTour) {
      const tourActions = foundTour.flatMap((tour: ITour) => tour.actions);
      const tasks = tourActions.map((task: ITourAction) => ({
        title: task.title,
        relatedTo: task.relatedTo,
        beforeEventDays: task.beforeEventDays,
      }));
      formik.setFieldValue('tasks', tasks);
    } else {
      setFoundTour([]);
    }
  }, [foundTour]);

  return {
    guidesData,
    toursData,
    foundTour,
    isLoading,
    isCreating,
    formik,
  };
};
