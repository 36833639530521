import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { deleteTask } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';

export const useDeleteTask = () => {
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();

  const { mutate } = useMutation('delete-task', deleteTask, {
    onSuccess: (e: any) => {
      if (e.status === 204) {
        handleMessage(true, 'Process was successful', 'success');
        queryClient.refetchQueries('get-tasks');
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return {
    mutate,
  };
};
