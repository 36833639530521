import { useState } from 'react';

import {
  Typography,
  Box,
  Avatar,
  Card,
  Grid,
  useTheme,
  styled,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Manat from 'src/assets/icon/manat';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
        color:  ${theme.colors.alpha.trueWhite[100]};
        width: ${theme.spacing(5.5)};
        height: ${theme.spacing(5.5)};
  `,
);

function StatsCart({
  avatarTheme,
  icon,
  title,
  count,
  hiddenCount = false,
  manatIcon = false,
}: {
  avatarTheme: any;
  icon: any;
  title: string;
  count: number;
  hiddenCount?: boolean;
  manatIcon?: boolean;
}) {
  const theme = useTheme();
  const [showData, setShowData] = useState<boolean>(true);

  const handlePersonal = () => {
    setShowData(!showData);
  };

  return (
    <Grid item xs={12} sm={6} md={3} xl={2}>
      <Card
        sx={{
          px: 3,
          pb: 6,
          pt: 3,
        }}
      >
        <Box display="flex" alignItems="center">
          <AvatarWrapper
            sx={{
              background: `${avatarTheme}`,
            }}
          >
            {icon}
          </AvatarWrapper>
          <Typography
            sx={{
              ml: 1.5,
              fontSize: `${theme.typography.pxToRem(15)}`,
              fontWeight: 'bold',
            }}
            variant="subtitle2"
            component="div"
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            ml: -2,
            pt: 2,
            pb: 1.5,
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              pl: 1,
              fontSize: `${theme.typography.pxToRem(35)}`,
              marginRight: '10px',
            }}
            variant="h1"
          >
            {hiddenCount
              ? showData
                ? '******'
                : Number(count).toFixed(2)
              : Number(count)}{' '}
            {hiddenCount && !showData && manatIcon && <Manat />}
          </Typography>

          {hiddenCount && (
            <IconButton onClick={handlePersonal}>
              {showData ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
        </Box>
      </Card>
    </Grid>
  );
}

export default StatsCart;
