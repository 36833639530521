import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getCategories = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/categories`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getCategory = async (
  categoryId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/categories/${categoryId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addCategory = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/categories`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteCategory = async (
  categoryId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/categories/${categoryId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editCategory = async ({
  categoryId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/categories/${categoryId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
