import { DesktopDatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

export const OmDateTimePicker = ({ formik, label, name, inputFormat }) => {
  return (
    <DesktopDatePicker
      value={formik.values[name]}
      onChange={(newDate) => {
        formik.setFieldValue(name, newDate);
      }}
      label={label}
      inputFormat={inputFormat}
      renderInput={(params) => (
        <TextField
          {...params}
          {...formik.getFieldProps(name)}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          margin="none"
          variant="outlined"
          fullWidth
          autoComplete="off"
        />
      )}
    />
  );
};
