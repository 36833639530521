import { Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddInventoryForm } from '../components/add-inventory-form';

export const AddInventoryContainer = function () {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('inventories.title'),
      href: '/dashboard/inventories',
    },
    {
      label: t('inventories.addInventory'),
      href: '/dashboard/parents/add-inventory',
    },
  ];

  return (
    <PageWrapper routes={routes} title={t('inventories.addInventory')}>
      <Card sx={{ p: 3 }}>
        <Grid container>
          <AddInventoryForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
