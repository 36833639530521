export const addNewTaskMapper = (values) => {
  return {
    event: values.event,
    participants: values.participants,
    relatedTo: 'participant',
    tasks: [
      {
        title: values.title,
        deadline: values.deadline,
        assignedTo: values.assignedTo,
      },
    ],
  };
};

export const editTaskMapper = (values) => {
  return {
    title: values.title,
    event: values.event,
    relatedTo: 'participant',
    deadline: values.deadline,
    assignedTo: values.assignedTo,
    participant: values.participant,
  };
};
