import { useMemo } from 'react';
import { Typography } from '@mui/material';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { useTranslation } from 'react-i18next';
import Manat from 'src/assets/icon/manat';
import { RepeatingTransactionsActionButtons } from '../components/action-buttons';
import { IRepeatingTransaction } from '../types';

export const useRepeatingTransactionsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('repeatingTransactions.title'),
      href: '/dashboard/repeating-transactions',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (item: IRepeatingTransaction) => item.title,
      },
      {
        id: 'recipient',
        align: 'left',
        label: t('table.recipient'),
        extructor: (item: IRepeatingTransaction) => item.recipient.fullName,
      },
      {
        id: 'spender',
        align: 'left',
        label: t('table.spender'),
        extructor: (item: IRepeatingTransaction) => item.spender.fullName,
      },
      {
        id: 'amount',
        align: 'left',
        label: t('table.amount'),
        extructor: (item: IRepeatingTransaction) => (
          <Typography display="flex" alignItems="center" gap={0.5}>
            {item.amount}
            <Manat />
          </Typography>
        ),
      },
      {
        id: 'table.actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item) => {
          return (
            <RepeatingTransactionsActionButtons
              repeatingTransactionId={item.id}
            />
          );
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
