import { Card, Grid, CircularProgress } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useEditRepeatingTransaction } from '../hooks/use-edit-repeating-transaction';
import { EditRepeatingTransactionForm } from '../components/edit-repeating-transaction-form';

export const EditRepeatingTransactionContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading, formik, usersData, isEditingRepTransaction } =
    useEditRepeatingTransaction();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: t('repeatingTransactions.title'),
      href: '/dashboard/repeating-transactions',
    },
    {
      label: `${data?.title ? data?.title : ''}`,
      href: `/dashboard/rentals/${data?.id}`,
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.title ? data?.title : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid item xs={12}>
            <EditRepeatingTransactionForm
              formik={formik}
              usersData={usersData}
              isEditingRepTransaction={isEditingRepTransaction}
            />
          </Grid>
        </Card>
      )}
    </PageWrapper>
  );
};
