import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useActions } from '../hooks/use-actions';
import { useTranslation } from 'react-i18next';
import { EditActionForm } from '../components/edit-action-form';
import { useEditAction } from '../hooks/use-edit-action';

export const EditActionContainer = () => {
  const { data, formik, isEditingAction } = useEditAction();

  const { t } = useTranslation();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: t('actions.title'),
      href: '/dashboard/actions',
    },
    {
      label: `${data?.title ? data?.title : ''}`,
      href: `/dashboard/actions/${data?.id}`,
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.title ? data?.title : ''}`}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <EditActionForm formik={formik} isEditingAction={isEditingAction} />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
