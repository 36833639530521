import { useContext, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Modal,
  IconButton,
  Button,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAllTasksOfParticipant } from '../hooks/use-all-tasks-of-participant';
import { IEventParticipant } from '../types';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteTasksOfParticipant } from '../hooks/use-delete-task-of-participant';
import { ITask } from 'src/modules/tasks/types';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 700,
  maxHeight: 700,
  overflow: 'auto',
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

export const AllTasksOfParticipant = () => {
  const [findTask, setFindTask] = useState<ITask>();
  const { dispatch } = useContext(ConfirmationModalContext);

  const { mutate: deleteTask } = useDeleteTasksOfParticipant();

  const { data, isUpdating, mutate, eventId, participantId } =
    useAllTasksOfParticipant();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const participant = data?.participants.find(
    (p: IEventParticipant) => p.id === participantId,
  );

  const handleChecked = (taskId: string) => {
    if (participant && !isUpdating) {
      const foundTask = participant.tasks.find(
        (item: ITask) => item.id === taskId,
      );

      if (foundTask) {
        setFindTask(foundTask);
        mutate({
          executedBy: foundTask.id,
          taskId,
        });
      }
    }
  };

  const handleClose = () => navigate(-1);

  return (
    <>
      <Modal
        keepMounted
        open={true}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form">
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('modal.allTasksOfParticipant')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {participant?.tasks.map((task: ITask) => {
                return (
                  <Box
                    key={task.id}
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={1}
                  >
                    <Box
                      sx={{
                        opacity: task.toggleReason === 'Done' ? '60%' : '100%',
                      }}
                    >
                      <FormControlLabel
                        label={task.title}
                        control={
                          isUpdating && findTask && findTask.id === task.id ? (
                            <Box sx={{ p: 1 }}>
                              <CircularProgress size={24} />
                            </Box>
                          ) : (
                            <Checkbox
                              onChange={() => handleChecked(task.id)}
                              checked={task.toggleReason === 'Done'}
                            />
                          )
                        }
                      />
                    </Box>
                    <Box display={'flex'}>
                      <Tooltip title={t('actionButtons.delete')} arrow>
                        <IconButton
                          onClick={() => {
                            dispatch({
                              type: 'open',
                              payload: {
                                confirm: () => {
                                  deleteTask(task.id);
                                  dispatch({
                                    type: 'close',
                                  });
                                },
                                message: 'modal.delete',
                                isOpen: true,
                                buttonMessage: 'actionButtons.delete',
                                color: 'error',
                              },
                            });
                          }}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('actionButtons.edit')} arrow>
                        <IconButton
                          component={RouterLink}
                          to={`/dashboard/events/${eventId}/all-tasks-of-participant/${participantId}/edit/${task.id}`}
                          color="primary"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })}
              <Button
                component={RouterLink}
                to={`/dashboard/events/${eventId}/all-tasks-of-participant/${participantId}/add-new-task`}
                variant="contained"
                color="success"
                startIcon={<AddTwoToneIcon fontSize="small" />}
                sx={{ width: '100%', mt: 1 }}
              >
                {t('buttons.addNewTask')}
              </Button>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ width: '100%', mt: 1 }}
          >
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {t('buttons.closeWindow')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
