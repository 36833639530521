import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getCoupons = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/coupons`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getCoupon = async (couponId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/coupons/${couponId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
export const getCouponCode = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/coupons/code`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createCoupon = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/coupons`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editCoupon = async ({
  couponId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/coupons/${couponId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteCoupon = async (
  couponId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/coupons/${couponId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
