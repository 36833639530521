import { ITour } from '../types';

export const addTourFormMapper = (values: ITour) => {
  return {
    title: values.title,
    country: values.country,
    days: values.duration.days,
    daysToDeadline: values.daysToDeadline,
    difficulty: values.difficulty,
    aboutTour: values.aboutTour,
    additionalInfo: values.additionalInfo,
  };
};
