import { useContext } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import DiscountIcon from '@mui/icons-material/Discount';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useDeleteParticipant } from '../hooks/use-delete-participant';

export const ParticipantActionButtons = ({ participantId, eventId }) => {
  const { dispatch } = useContext(ConfirmationModalContext);

  const { mutate } = useDeleteParticipant();

  const { t } = useTranslation();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('actionButtons.allTasks')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/events/${eventId}/all-tasks-of-participant/${participantId}`}
            color="primary"
          >
            <ChecklistIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('actionButtons.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/users/${participantId}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('modal.makePayment')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/events/${eventId}/amount/${participantId}`}
            color="secondary"
          >
            <AttachMoneyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.discount')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/events/${eventId}/discount/${participantId}`}
            color="secondary"
          >
            <DiscountIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.delete')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    mutate({ eventId, participantId });
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: 'modal.delete',
                  isOpen: true,
                  buttonMessage: 'buttons.delete',
                  color: 'error',
                },
              });
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
