import { ManageTags } from '../pages/manage-tags';

const manageTagsRouter = {
  path: 'users/manage-tags/:userId',
  children: [
    {
      path: '',
      element: <ManageTags />,
    },
  ],
};

export default manageTagsRouter;
