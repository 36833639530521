import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  () => `
        text-decoration: none;
`,
);

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ width: '100px', height: '80px' }}>
        <LogoWrapper to="/">
          <img
            src={process.env.REACT_APP_LOGO_URL}
            alt="logo"
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          />
        </LogoWrapper>
      </Box>
    </Box>
  );
}

export default AppInit;
