import { useMemo } from 'react';
import { IOptions } from 'src/shared/components/generic-table/types';
import { useTranslation } from 'react-i18next';
import { CertificatesTableActionButtons } from '../components/action-buttons';
import { ICertificate } from '../types';

export const useCertificatesTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('certificates.title'),
      href: '/dashboard/certificates',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: ICertificate) => item.code,
      },
      {
        id: 'created',
        align: 'center',
        label: t('table.created'),
        extructor: (item: ICertificate) => item.createdBy.fullName,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: ICertificate) => {
          return <CertificatesTableActionButtons certificateId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
