import { ManagePermissions } from '../pages/manage-permissions';

const managePermissionsRouter = {
  path: 'users/manage-permissions/:userId',
  children: [
    {
      path: '',
      element: <ManagePermissions />,
    },
  ],
};

export default managePermissionsRouter;
