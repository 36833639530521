import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';

import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addTask } from 'src/modules/tasks/services';
import { queryError } from 'src/shared/utils/query-error';
import { addNewTaskForTourMapper } from '../mappers';
import { getTinyUsers } from 'src/modules/users/services';
import { getActions } from 'src/modules/actions/services';
import { TTinyUsersRoot } from 'src/modules/users/types';
import { TActionsRoot } from 'src/modules/actions/types';

export const useAddNewTaskForTour = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { handleMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    assignedTo: Yup.string().when('title', {
      is: (value) => value && value.length > 0,
      then: Yup.string().required(t('yup.inputs.responsiblePersonRequired')),
      otherwise: Yup.string(),
    }),
    deadline: Yup.string().when('title', {
      is: (value) => value && value.length > 0,
      then: Yup.string().required(t('yup.inputs.endDate')),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      deadline: '',
      assignedTo: '',
      event: eventId || '',
      tasks: [],
    },
    onSubmit: (values) => {
      const hasTitle = values.title.length > 0;
      const hasTasks = values.tasks.length > 0;

      if (hasTitle || hasTasks) {
        const updatedTasks = hasTitle
          ? [
              ...formik.values.tasks,
              {
                title: values.title,
                assignedTo: values.assignedTo,
                deadline: values.deadline,
              },
            ]
          : formik.values.tasks.map((task) => ({
              ...task,
              deadline: values.deadline,
            }));

        const addNewTaskForTourBody = addNewTaskForTourMapper({
          ...values,
          tasks: updatedTasks,
        });

        mutate(addNewTaskForTourBody);
      } else {
        handleMessage(true, 'Add a new task', 'error');
      }
    },
    validationSchema,
    enableReinitialize: true,
  });

  const { data } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { data: actionsData } = useQuery('get-actions', getActions, {
    select: ({ data }: { data: TActionsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const mutationOptions = {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        queryClient.refetchQueries('get-event');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  };
  const { mutate, isLoading } = useMutation(
    'add-new-task-for-tour',
    addTask,
    mutationOptions,
  );

  return { formik, actionsData, data, eventId, isLoading };
};
