export const userFormMapper = (values) => {
  const phoneNumbers = [];

  if (values.numberOne) {
    phoneNumbers.push(
      !values.isForgeinOne ? `994${values.numberOne}` : values.numberOne,
    );
  }

  if (values.numberTwo) {
    phoneNumbers.push(
      !values.isForgeinTwo ? `994${values.numberTwo}` : values.numberTwo,
    );
  }

  return {
    name: values.name,
    surname: values.surname,
    email: values.email,
    dateOfBirth: values.dateOfBirth,
    phoneNumbers,
    fatherName: values.fatherName,
    roles: values.roles,
    socialMediaProfiles: {
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
    },
    note: values.note,
  };
};
