import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';
import { ITour } from '../types';

export const getTours = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tours`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTour = async (tourId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/tours/${tourId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addTour = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/tours`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteTour = async (tourId: string): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/tours/${tourId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editTour = async ({ tourId, data }): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/tours/${tourId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
