import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { EditTagForm } from '../components/edit-tag-form';
import { useTranslation } from 'react-i18next';
import { useEditTag } from '../hooks/use-edit-tag';

export const EditTagContainer = () => {
  const { formik, data, isEditingTag } = useEditTag();
  const { t } = useTranslation();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: t('tags.title'),
      href: '/dashboard/tags',
    },
    {
      label: `${data?.label ? data?.label : ''}`,
      href: `/dashboard/tags/${data?.id}`,
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.label ? data?.label : ''}`}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <EditTagForm formik={formik} isEditingTag={isEditingTag} />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
