import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useRental } from '../hooks/use-rental';
import { RentalDetailsForm } from '../components/rental-details-form';

export const RentalDetailsContainer = () => {
  const { data, isLoading } = useRental();

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('rentals.title'),
      href: '/dashboard/rentals',
    },
    {
      label: data?.code ? data?.code : '',
      href: `/dashboard/rentals/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('rentals.rentalCode')}: ${data?.code ? data?.code : ''}`}
    >
      <Helmet>
        <title>{`${t('rentals.rentalCode')} | ${
          data?.code ? data?.code : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Grid container sx={{ p: 3 }}>
          <RentalDetailsForm data={data} />
        </Grid>
      )}
    </PageWrapper>
  );
};
