import { Link as RouterLink } from 'react-router-dom';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useTasks } from '../hooks/use-tasks';
import { useTasksTable } from '../hooks/use-tasks-table';

export const TasksContainer = () => {
  const { t } = useTranslation();

  const { data } = useTasks();

  const [columns, options, routes] = useTasksTable();

  return (
    <PageWrapper
      routes={routes}
      title={t('tasks.title')}
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/tasks/add-task"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addTask')}
        </Button>
      }
    >
      <GenericTable
        search={true}
        checkBox={true}
        data={data}
        columns={columns}
        options={options}
      />
    </PageWrapper>
  );
};
