import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getCertificates = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/certificates`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getCertificate = async (certificateId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/certificates/${certificateId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createCertificate = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/certificates`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editCertificate = async ({
  certificateId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/certificates/${certificateId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteCertificate = async (
  certificateId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/certificates/${certificateId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
