import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getCertificate } from '../services';
import { useParams } from 'react-router';
import { ICertificate } from '../types';

export const useCertificate = () => {
  const { certificateId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-certificate', certificateId],
    () => {
      if (certificateId) {
        return getCertificate(certificateId);
      }
    },
    {
      select: ({ data }): ICertificate => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
