import React, { useState } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GenericTable from 'src/shared/components/generic-table';
import { useInventories } from '../hooks/use-inventories';
import { useInventoriesTable } from '../hooks/use-inventories-table';
import InventorySearch from '../components/inventory-search';

export const InventoriesContainer = () => {
  const { t } = useTranslation();
  const { data } = useInventories();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [columns, options, routes] = useInventoriesTable();
  const handleFilter = () => {
    setIsOpen((s) => !s);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('inventories.title')}
      action={
        <Link
          to="/dashboard/inventories/add-inventory"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addInventory')}</Button>
        </Link>
      }
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>{t('inputs.labels.filter')}</Typography>
          {!isOpen ? (
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
          )}
        </Box>
        {isOpen && <InventorySearch />}
      </Card>
      <Card>
        <GenericTable data={data?.data} columns={columns} options={options} />
      </Card>
    </PageWrapper>
  );
};
