import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getTransaction } from '../services';
import { ITransaction } from '../types';

export const useTransaction = () => {
  const { transactionId } = useParams();

  const { data } = useQuery(
    ['get-transaction', transactionId],
    () => {
      if (transactionId) {
        return getTransaction(transactionId);
      }
    },
    {
      select: ({ data }): ITransaction => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
  };
};
