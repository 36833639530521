import { useContext } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import { ConfirmationModalContext } from "src/shared/contexts/confirmation-modal";
import { useAddEventArchive } from "../hooks/use-add-event-archive";
import { useDeleteEvent } from "../hooks/use-delete-event";

export const EventsTableActionButtons = ({ eventId }) => {
  const { dispatch } = useContext(ConfirmationModalContext);

  const { t } = useTranslation();

  const { addEventArchive } = useAddEventArchive();

  const { mutate } = useDeleteEvent();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t("actionButtons.view")} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/events/${eventId}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("actionButtons.archive")} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: "open",
                payload: {
                  confirm: () => {
                    addEventArchive(eventId);
                    dispatch({
                      type: "close",
                    });
                  },
                  message: "modal.archive",
                  isOpen: true,
                  buttonMessage: "buttons.archive",
                  color: "success",
                },
              });
            }}
            color="secondary"
          >
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("actionButtons.delete")} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: "open",
                payload: {
                  confirm: () => {
                    mutate(eventId);
                    dispatch({
                      type: "close",
                    });
                  },
                  message: "modal.delete",
                  isOpen: true,
                  buttonMessage: "buttons.delete",
                  color: "error",
                },
              });
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </>
  );
};
