import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { AddEventForm } from '../components/add-event-form';

export const AddEventContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('events.title'),
      href: '/dashboard/events',
    },
    {
      label: t('event.addEvent'),
      href: '/dashboard/events/new-event',
    },
  ];

  return (
    <PageWrapper routes={routes} title={t('event.addEvent')}>
      <Helmet>
        <title>{t('event.addEvent')}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <AddEventForm />
      </Grid>
    </PageWrapper>
  );
};
