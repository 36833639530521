import { Outlet } from 'react-router-dom';
import { EventDetailsContainer } from '../containers/event-details-container';

export const TourDetails = () => {
  return (
    <>
      <Outlet />
      <EventDetailsContainer />
    </>
  );
};
