import { TaskModal } from '../components/task-modal';
import { TaskModalDetails } from '../components/task-modal-details';
import { AddAmount } from '../pages/add-amount';
import { AddDiscount } from '../pages/add-discount';
import { AddEvent } from '../pages/add-event';
import { AddNewEventTask } from '../pages/add-new-event-task';
import { AddNewTask } from '../pages/add-new-task';
import { AddNewTaskToParticipant } from '../pages/add-new-task-to-participant';
import { AllTasksOfParticipant } from '../pages/all-tasks-of-participant';
import { EditTaskOfParticipant } from '../pages/edit-task-of-participant';
import { TourDetails } from '../pages/event-details';
import { Events } from '../pages/events';

const eventsRouter = {
  path: 'events',
  children: [
    {
      path: '',
      element: <Events />,
    },
    {
      path: ':eventId',
      element: <TourDetails />,
      children: [
        {
          path: 'all-tasks-of-participant/:participantId',
          element: <AllTasksOfParticipant />,
        },
        {
          path: 'all-tasks-of-participant/:participantId/edit/:taskId',
          element: <EditTaskOfParticipant />,
        },
        {
          path: 'all-tasks-of-participant/:participantId/add-new-task',
          element: <AddNewTaskToParticipant />,
        },
        {
          path: 'discount/:participantId',
          element: <AddDiscount />,
        },
        {
          path: 'amount/:participantId',
          element: <AddAmount />,
        },
        {
          path: 'task/:taskId',
          element: <TaskModal />,
        },
        {
          path: 'details/:taskId',
          element: <TaskModalDetails />,
        },
        {
          path: 'add-new-event-task',
          element: <AddNewEventTask />,
        },
        {
          path: 'add-new-task',
          element: <AddNewTask />,
        },
      ],
    },
    {
      path: 'new-event',
      element: <AddEvent />,
    },
  ],
};

export default eventsRouter;
