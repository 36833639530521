import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../hooks/use-event';
import PersonIcon from '@mui/icons-material/Person';
import { AddParticipant } from '../components/add-participant';
import { ParticipantsTable } from '../components/participants-table';
import { EventDashboard } from '../components/event-dashboard';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useToggleTask } from '../hooks/use-toggle-task';
import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteEventTask } from '../hooks/use-delete-event-task';
import AuthContext from 'src/contexts/JWTAuthContext';
import { ITask } from 'src/modules/tasks/types';

export const EventDetailsContainer = () => {
  const { t } = useTranslation();
  const [findTask, setFindTask] = useState<ITask>();
  const { dispatch } = useContext(ConfirmationModalContext);

  const { user } = useContext(AuthContext);

  const { mutate: deleteTask } = useDeleteEventTask();

  const { data, isLoading, eventId } = useEvent();

  const { mutate, isUpdating } = useToggleTask();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('events.title'),
      href: '/dashboard/events',
    },
    {
      label: data?.title,
      href: `/dashboard/events/${data?.title}`,
    },
  ];

  const handleChecked = (taskId: string) => {
    const foundTask = data?.tasks.find((item) => item.id === taskId);

    if (foundTask && !isUpdating) {
      setFindTask(foundTask);

      mutate({
        taskId,
        executedBy: user.id,
      });
    }
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('event.title')}: ${data?.title ? data?.title : ''}`}
    >
      {isLoading ? (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '400px',
          }}
        >
          <CircularProgress size={30} />
        </Card>
      ) : (
        <>
          <EventDashboard data={data} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        border: '1px solid #c4c4c4',
                        padding: '12px 10px',
                        borderRadius: '10px',
                      }}
                    >
                      {t('event.name')}:{' '}
                      <Typography
                        variant="caption"
                        style={{
                          color: '#333',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                          fontSize: '15px',
                        }}
                      >
                        {data?.title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    {
                      <Box
                        sx={{
                          border: '1px solid #c4c4c4',
                          padding: '12px 10px',
                          borderRadius: '10px',
                        }}
                      >
                        {t('event.guides')}:{' '}
                        <Typography
                          variant="caption"
                          style={{
                            color: '#333',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                            fontSize: '15px',
                          }}
                        >
                          {data?.guides
                            .map((guide, i) => guide.fullName)
                            .join(', ')}
                        </Typography>
                      </Box>
                    }
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box component="form">
                  {data?.tasks.map((task) => {
                    return (
                      <Box
                        key={task.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            opacity:
                              task.toggleReason === 'Done' ? '60%' : '100%',
                          }}
                        >
                          <FormControlLabel
                            label={task.title}
                            control={
                              isUpdating &&
                              findTask &&
                              findTask.id === task.id ? (
                                <Box sx={{ p: 1 }}>
                                  <CircularProgress size={24} />
                                </Box>
                              ) : (
                                <Checkbox
                                  onChange={() => handleChecked(task.id)}
                                  checked={task.toggleReason === 'Done'}
                                />
                              )
                            }
                          />
                        </Box>
                        <Box>
                          {task.toggleReason !== 'Done' && (
                            <Tooltip title={t('events.person.tooltip')}>
                              <IconButton
                                component={RouterLink}
                                to={`/dashboard/events/${eventId}/task/${task.id}`}
                                color="primary"
                              >
                                <PersonIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title={t('events.info.tooltip')}>
                            <IconButton
                              component={RouterLink}
                              to={`/dashboard/events/${data.id}/details/${task.id}`}
                            >
                              <InfoOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('actionButtons.delete')} arrow>
                            <IconButton
                              onClick={() => {
                                dispatch({
                                  type: 'open',
                                  payload: {
                                    confirm: () => {
                                      deleteTask(task.id);
                                      dispatch({
                                        type: 'close',
                                      });
                                    },
                                    message: 'modal.delete',
                                    isOpen: true,
                                    buttonMessage: 'actionButtons.delete',
                                    color: 'error',
                                  },
                                });
                              }}
                              color="error"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    );
                  })}
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      component={RouterLink}
                      to={`/dashboard/events/${eventId}/add-new-event-task`}
                      variant="contained"
                      startIcon={<AddTwoToneIcon fontSize="small" />}
                      sx={{ mt: 1.5 }}
                    >
                      {t('buttons.addNewEventTask')}
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Card sx={{ p: 3 }}>
                <AddParticipant />
              </Card>
            </Grid>
          </Grid>
          {data?.participants.length > 0 && <ParticipantsTable data={data} />}
        </>
      )}
    </PageWrapper>
  );
};
