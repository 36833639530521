import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
// import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

import { ConfirmationModalContext } from 'src/shared/contexts/confirmation-modal';
import { useDeleteUser } from '../hooks/use-delete-user';
import { useArchiveUser } from '../hooks/use-archive-user';
import EditIcon from '@mui/icons-material/Edit';

const UsersTableActionButtons = ({ userId }) => {
  const { dispatch } = useContext(ConfirmationModalContext);

  const { t } = useTranslation();

  // const { mutate } = useDeleteUser();

  const { addUserArchive } = useArchiveUser();

  return (
    <>
      <Typography noWrap>
        <Tooltip title={t('actionButtons.view')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/users/${userId}`}
            color="primary"
          >
            <LaunchTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('actionButtons.edit')} arrow>
          <IconButton
            component={RouterLink}
            to={`/dashboard/users/edit-user/${userId}`}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('actionButtons.archive')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    addUserArchive(userId);
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: 'modal.archive',
                  isOpen: true,
                  buttonMessage: 'buttons.archive',
                  color: 'success',
                },
              });
            }}
            color="secondary"
          >
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={t('actionButtons.delete')} arrow>
          <IconButton
            onClick={() => {
              dispatch({
                type: 'open',
                payload: {
                  confirm: () => {
                    mutate(userId);
                    dispatch({
                      type: 'close',
                    });
                  },
                  message: 'modal.delete',
                  isOpen: true,
                  buttonMessage: 'actionButtons.delete',
                  color: 'error',
                },
              });
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </Typography>
    </>
  );
};

export default UsersTableActionButtons;
