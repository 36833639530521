import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { useTranslation } from 'react-i18next';
import { TasksButtons } from '../components/task-buttons';
import moment from 'moment';
import { DATA_TYPES } from 'src/shared/costants/constants';
import { ITask } from '../types';

export const useTasksTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tasks.title'),
      href: '/dashboard/tasks',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (task: ITask) => task.title,
      },
      {
        id: 'event',
        align: 'left',
        label: t('table.event'),
        extructor: (task: ITask) => task.event && task.event.title,
      },
      {
        id: 'participant',
        align: 'left',
        label: t('table.participant'),
        extructor: (task: ITask) =>
          task.participant && task.participant.fullName,
      },
      {
        id: 'assignedTo',
        align: 'left',
        label: t('table.assignedTo'),
        extructor: (task: ITask) => task.assignedTo && task.assignedTo.fullName,
      },
      {
        id: 'deadline',
        align: 'left',
        label: t('table.deadline'),
        extructor: (task: ITask) =>
          moment(task.deadline).format(DATA_TYPES.mdy),
      },
      {
        id: t('table.actions'),
        align: 'right',
        label: t('table.actions'),
        extructor: (task: ITask) => {
          return <TasksButtons taskId={task.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
