import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageWrapper from 'src/shared/components/page-wrapper';
import { TransactionDetailsDorm } from '../components/transaction-details-form';
import { useTranslation } from 'react-i18next';
import { useTransaction } from '../hooks/use-transaction';

export const TransactionDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useTransaction();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('transactions.title'),
      href: '/dashboard/transactions',
    },
    {
      label: data?.title,
      href: `/dashboard/transactions/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('transaction.title')}: ${data?.title ? data?.title : ''}`}
    >
      <Helmet>
        <title>
          {`${t('transaction.title')} | ${data?.title ? data?.title : ''}`}
        </title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <TransactionDetailsDorm data={data} />
      </Grid>
    </PageWrapper>
  );
};
