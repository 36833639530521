import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getRentals = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/rentals`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getRental = async (rentalId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/rentals/${rentalId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createRental = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/rentals`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editRental = async ({
  rentalId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/rentals/${rentalId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteRental = async (
  rentalId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/rentals/${rentalId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
