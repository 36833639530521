import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { AddCertificateForm } from '../components/add-certificate-form';

export const AddCertificateContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('certificates.title'),
      href: '/dashboard/certificates',
    },
    {
      label: t('certificates.addCertificate'),
      href: '/dashboard/certificates/add-certificate',
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('certificates.addCertificate')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddCertificateForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
