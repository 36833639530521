import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getRepeatingTransactions } from '../services';
import {  TRepeatingTransactionsRoot } from '../types';

export const useRepeatingTransactions = () => {
  const { data, isLoading } = useQuery(
    'get-repeating-transactions',
    getRepeatingTransactions,
    {
      select: ({ data }: { data: TRepeatingTransactionsRoot }) => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
