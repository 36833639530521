import { Link } from 'react-router-dom';
import PageWrapper from 'src/shared/components/page-wrapper';
import GenericTable from 'src/shared/components/generic-table';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCertificates } from '../hooks/use-certificates';
import { useCertificatesTable } from '../hooks/use-certificates-table';

export const CertificatesContainer = () => {
  const { t } = useTranslation();

  const { data } = useCertificates();

  const [columns, options, routes] = useCertificatesTable();

  return (
    <PageWrapper
      action={
        <Link
          to="/dashboard/certificates/add-certificate"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">{t('buttons.addCertificate')}</Button>
        </Link>
      }
      routes={routes}
      title={t('certificates.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
