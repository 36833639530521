import { ActionDetails } from '../pages/action-details';
import { Actions } from '../pages/actions';
import { AddAction } from '../pages/add-action';
import { EditAction } from '../pages/edit-action';

const actionsRouter = {
  path: 'actions',
  children: [
    {
      path: '',
      element: <Actions />,
      children: [],
    },
    {
      path: ':actionId',
      element: <ActionDetails />,
    },
    {
      path: 'add-action',
      element: <AddAction />,
    },
    {
      path: 'edit/:actionId',
      element: <EditAction />,
    },
  ],
};

export default actionsRouter;
