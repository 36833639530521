import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { TagDetailsForm } from '../components/tag-details-form';
import { useTag } from '../hooks/use-tag';

export const TagDetailsContainer = () => {
  const { t } = useTranslation();

  const { data } = useTag();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tags.title'),
      href: '/dashboard/tags',
    },
    {
      label: data?.label ? data?.label : '',
      href: `/dashboard/tags/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      routes={routes}
      title={`${t('tags.tagName')}: ${data?.label ? data?.label : ''}`}
    >
      <Helmet>
        <title>{`${t('tags.tagName')} | ${
          data?.label ? data?.label : ''
        }`}</title>
      </Helmet>
      <Grid container sx={{ p: 3 }}>
        <TagDetailsForm data={data} />
      </Grid>
    </PageWrapper>
  );
};
