import { Grid, useTheme } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import StatsCart from './stats-cart';
import { EventAllButtons } from './event-all-buttons';
import { useTranslation } from 'react-i18next';
import PendingIcon from '@mui/icons-material/Pending';
import { IEvent } from '../types';

export const EventDashboard = ({ data }: { data: IEvent }) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={2}
      sx={{ mb: 2 }}
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <StatsCart
            avatarTheme={theme.colors.gradients.orange3}
            icon={<AccountBalanceWalletIcon fontSize="small" />}
            title={t('eventDashboard.maxPossibleRevenue')}
            count={data?.eventStats.maxPossibleRevenue || 0}
            hiddenCount={true}
            manatIcon={true}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.blue2}
            icon={<AccountBalanceWalletIcon fontSize="small" />}
            title={t('eventDashboard.supposedPaymentTotal')}
            count={data?.eventStats.supposedPaymentTotal || 0}
            hiddenCount={true}
            manatIcon={true}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.pink2}
            icon={<AccountBalanceWalletIcon fontSize="small" />}
            title={t('eventDashboard.leftPaymentTotal')}
            count={data?.eventStats.leftPaymentTotal || 0}
            hiddenCount={true}
            manatIcon={true}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.black2}
            icon={<AccountBalanceWalletIcon fontSize="small" />}
            title={t('eventDashboard.generalCirculation')}
            count={data?.eventStats.revenueTotal || 0}
            hiddenCount={true}
            manatIcon={true}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.blue4}
            icon={<HowToRegIcon fontSize="small" />}
            title={t('eventDashboard.registered')}
            count={data?.eventStats.participantsCount || 0}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.green1}
            icon={<EventSeatIcon fontSize="small" />}
            title={t('eventDashboard.numberSeatsRemaining')}
            count={data?.eventStats.leftSeatsCount || 0}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.purple1}
            icon={<PaidIcon fontSize="small" />}
            title={t('eventDashboard.paid')}
            count={data?.eventStats.paidParticipantsCount || 0}
          />
          <StatsCart
            avatarTheme={theme.colors.gradients.blue5}
            icon={<PendingIcon fontSize="small" />}
            title={t('eventDashboard.paymentIsPending')}
            count={data?.eventStats.notPaidParticipantsCount || 0}
          />
          <EventAllButtons />
        </Grid>
      </Grid>
    </Grid>
  );
};
