import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getServices = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/services`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getService = async (serviceId): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/services/${serviceId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createService = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/services`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editService = async ({
  serviceId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/services/${serviceId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteService = async (
  serviceId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/services/${serviceId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
