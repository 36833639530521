import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { addFileUpload } from '../services';

export const useAddFileUpload = () => {
  const { handleMessage } = useContext(AlertContext);
  const [imagesArr, setImagesArr] = useState([]);

  const { mutate: addFileUploadMutate, isLoading: isUpdatingFile } =
    useMutation('add-file-upload', addFileUpload, {
      onSuccess: (e: any) => {
        setImagesArr((prevImgs) => [
          ...prevImgs,
          { public_id: e?.data?.data?.public_id, url: e?.data?.data?.url },
        ]);
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    });

  return {
    addFileUploadMutate,
    isUpdatingFile,
    imagesArr,
    setImagesArr,
  };
};
