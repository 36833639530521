import { Box, Button, Modal, Grid, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 300,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const Input = styled('input')({
  display: 'none',
});

export const AddInventoryImgesModal = ({
  formik,
  openInventoryImagesModal,
  setOpenInventoryImagesModal,
  images,
  imageName,
  setImageName,
  addUploadImage,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenInventoryImagesModal(false);
  };

  const handleConfirm = () => {
    if (images.length > 0) {
      const base64Images = images.map((image) => image.base64);
      formik.setFieldValue('images', [
        ...formik.values.images,
        ...base64Images,
      ]);
      setImageName('');
      setOpenInventoryImagesModal(false);
    }
  };

  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   uploadImages(files);
  // };

  // const uploadImages = async (files) => {
  //   const fileList = Array.from(files);
  //   const base64Images = await Promise.all(
  //     fileList.map((file) => readFileAsDataURL(file)),
  //   );

  //   const newImages = fileList.map((file, index) => ({
  //     file,
  //     base64: base64Images[index],
  //   }));

  //   setImages(newImages);
  //   setImageName(files[0].name);
  // };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal
      keepMounted
      open={openInventoryImagesModal}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box component={'form'} sx={style}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Button variant="contained" sx={{ width: '100%' }}>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  name="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    addUploadImage(e);
                  }}
                />
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <UploadTwoToneIcon />
                  {t('modal.addPhoto')}
                </Box>
              </label>
            </Button>
            <Typography>{imageName}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="contained"
            sx={{ marginTop: '15px;' }}
            onClick={handleConfirm}
          >
            {t('buttons.add')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: '15px;' }}
            onClick={handleClose}
          >
            {t('buttons.cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
