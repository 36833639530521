import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { PermissionActionButtons } from '../components/action-buttons';
import { useTranslation } from 'react-i18next';
import { IPermission } from '../types';

export const usePermissionsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
  };
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('permissions.title'),
      href: '/dashboard/permissions',
    },
  ];
  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'slug',
        align: 'left',
        label: t('table.slug'),
        extructor: (permission: IPermission) => permission.slug,
      },
      {
        id: t('table.name'),
        align: 'left',
        label: t('table.name'),
        extructor: (permission: IPermission) => permission.name,
      },
      {
        id: t('table.actions'),
        align: 'right',
        label: t('table.actions'),
        extructor: (permission: IPermission) => {
          return <PermissionActionButtons permissionId={permission.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
