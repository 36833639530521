import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Card, Grid } from '@mui/material';
import { AddRentalForm } from '../components/add-rental-form';

export const AddRentalContainer = () => {
  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('rentals.title'),
      href: '/dashboard/rentals',
    },
    {
      label: t('rentals.addRental'),
      href: '/dashboard/rentals/add-rental',
    },
  ];

  return (
    <PageWrapper isPaper={false} routes={routes} title={t('rentals.addRental')}>
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddRentalForm />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
