import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { useParticipants } from '../hooks/use-participants';
import SubmitButton from 'src/shared/components/Submit-button';
import { useAddParticipant } from '../hooks/use-add-participant';
import { ITinyUser } from 'src/modules/users/types';

export const AddParticipant = () => {
  const { t } = useTranslation();
  const { data } = useParticipants();
  const { formik, isCreating } = useAddParticipant();

  // const filteredByClient = data?.filter((participant) =>
  //   participant.roles.includes('contact'),
  // );

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Autocomplete
        options={data || []}
        disablePortal
        multiple={true}
        value={formik.values.userIds.map((pId: string) => {
          const participant = data.find((participantObj: ITinyUser) => {
            return pId === participantObj.id;
          });
          return participant;
        })}
        onChange={(_e, newValue: any) => {
          formik.setFieldValue(
            'userIds',
            newValue ? newValue.map((item: ITinyUser) => item.id) : '',
          );
        }}
        getOptionLabel={(option: ITinyUser) => {
          return `${option.fullName} (${option.code})`;
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={t('events.participants.title')}
          />
        )}
      />
      {!!formik.errors.userIds && formik.touched.userIds && (
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#FF1943',
          }}
        >
          {formik.errors.userIds}
        </Typography>
      )}
      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
        <SubmitButton load={isCreating} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.confirm')}
        </SubmitButton>
      </Box>
    </Box>
  );
};
