import { AddTour } from "../pages/add-tour";
import { EditTour } from "../pages/edit-tour";
import { TourDetails } from "../pages/tour-details";
import { Tours } from "../pages/tours";

const toursRouter = {
  path: 'tours',
  children: [
    {
      path: '',
      element: <Tours />,
      children: [],
    },
    {
      path: ':tourId',
      element: <TourDetails />,
    },
    {
      path: 'add-tour',
      element: <AddTour />,
    },
    {
      path: 'edit/:tourId',
      element: <EditTour />,
    },
  ],
};

export default toursRouter;
