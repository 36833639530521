import { useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Autocomplete,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  styled,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OmInput, OmDateTimePicker } from 'src/shared/components';
import { useAddNewTask } from '../hooks/use-add-new-task';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
      padding: 0 ${theme.spacing(8)};
      position: relative;
      bottom: -1px;

      .MuiTabs-root {
        height: 44px;
        min-height: 44px;
      }

      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          min-height: 4px;
          height: 4px;
          box-shadow: none;
          bottom: -4px;
          background: none;
          border: 0;

          &:after {
            position: absolute;
            left: 50%;
            width: 28px;
            content: ' ';
            margin-left: -14px;
            background: ${theme.colors.primary.main};
            border-radius: inherit;
            height: 100%;
          }
      }

      .MuiTab-root {
          &.MuiButtonBase-root {
              height: 44px;
              min-height: 44px;
              background: ${theme.colors.alpha.white[50]};
              border: 1px solid ${theme.colors.alpha.black[10]};
              border-bottom: 0;
              position: relative;
              margin-right: ${theme.spacing(1)};
              font-size: ${theme.typography.pxToRem(14)};
              color: ${theme.colors.alpha.black[80]};
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .MuiTouchRipple-root {
                opacity: .1;
              }

              &:after {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                bottom: 0;
                height: 1px;
                content: '';
                background: ${theme.colors.alpha.black[10]};
              }

              &:hover {
                color: ${theme.colors.alpha.black[100]};
              }
          }

          &.Mui-selected {
              color: ${theme.colors.alpha.black[100]};
              background: ${theme.colors.alpha.white[100]};
              border-bottom-color: ${theme.colors.alpha.white[100]};

              &:after {
                height: 0;
              }
          }
      }
  `,
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 800,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const TABS = [
  { value: 'tasks', label: 'tasks.title' },
  { value: 'new-task', label: 'tasks.newTask' },
];

export const AddNewTask = () => {
  const [currentTab, setCurrentTab] = useState<string>('tasks');

  const { t } = useTranslation();

  const { formik, data, actionsData, isLoading } = useAddNewTask();

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const handleTaskChange = (task, checked) => {
    const { tasks } = formik.values;
    const updatedTasks = checked
      ? [...tasks, task]
      : tasks.filter((t) => t.id !== task.id);
    formik.setFieldValue('tasks', updatedTasks);
  };

  const handleAutocompleteChange = (_e, newValue) => {
    if (newValue) {
      const updatedTasks = formik.values.tasks.map((task) => ({
        ...task,
        assignedTo: newValue.id,
      }));
      formik.setFieldValue('tasks', updatedTasks);
    }
  };

  const handleTabsChange = (_event, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Modal
        keepMounted
        open={true}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('modal.addNewParticipantTask')}
          </Typography>
          <TabsContainerWrapper>
            <Tabs
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {TABS.map((tab, i) => (
                <Tab key={i} label={t(tab.label)} value={tab.value} />
              ))}
            </Tabs>
          </TabsContainerWrapper>
          <Grid container spacing={2}>
            {currentTab === 'tasks' ? (
              <Grid item xs={12} mt={2}>
                {actionsData?.map((task) => {
                  if (task.relatedTo === 'participant') {
                    return (
                      <Box key={task.id}>
                        <FormControlLabel
                          label={task.title}
                          control={
                            <Checkbox
                              checked={formik.values.tasks.some(
                                (t) => t.id === task.id,
                              )}
                              onChange={(e) =>
                                handleTaskChange(task, e.target.checked)
                              }
                            />
                          }
                        />
                      </Box>
                    );
                  }
                })}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                      options={data || []}
                      disablePortal
                      disabled={false}
                      onChange={(_e, newValue) =>
                        handleAutocompleteChange(_e, newValue)
                      }
                      getOptionLabel={(option: any) => {
                        return `${option.fullName} (${option.code})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={t('inputs.labels.addResponsiblePerson')}
                        />
                      )}
                    />
                    {!!formik.errors.assignedTo && formik.touched.assignedTo && (
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#FF1943',
                        }}
                      >
                        {formik.errors.assignedTo}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <OmDateTimePicker
                      inputFormat="dd/MM/yyyy"
                      formik={formik}
                      label={t('inputs.labels.endDate')}
                      name="deadline"
                    />
                    {!!formik.errors.deadline && formik.touched.deadline && (
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#FF1943',
                        }}
                      >
                        {formik.errors.deadline}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <OmInput
                      formik={formik}
                      label={t('inputs.labels.task')}
                      name="title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={data || []}
                      disablePortal
                      disabled={false}
                      onChange={(_e, newValue) => {
                        formik.setFieldValue(
                          'assignedTo',
                          newValue ? newValue.id : '',
                        );
                      }}
                      getOptionLabel={(option: any) => {
                        return `${option.fullName} (${option.code})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={t('inputs.labels.addResponsiblePerson')}
                        />
                      )}
                    />
                    {!!formik.errors.assignedTo && formik.touched.assignedTo && (
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#FF1943',
                        }}
                      >
                        {formik.errors.assignedTo}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <OmDateTimePicker
                      inputFormat="dd/MM/yyyy"
                      formik={formik}
                      label={t('inputs.labels.endDate')}
                      name="deadline"
                    />
                    {!!formik.errors.deadline && formik.touched.deadline && (
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#FF1943',
                        }}
                      >
                        {formik.errors.deadline}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: '15px;' }}
              onClick={handleClose}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: '15px;' }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading && <CircularProgress sx={{ mr: 0.5 }} size={14} />}
              {t('buttons.add')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
