import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertContext } from 'src/shared/contexts/Alert';
import { addUserDataService, createUser, editUser, getUser } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { userFormMapper } from '../mappers';
import { IUser, TAddUserDataRoot } from '../types';

export const useUserForm = () => {
  const { t } = useTranslation();

  const { userId } = useParams();

  const navigate = useNavigate();

  const { handleMessage } = useContext(AlertContext);

  const { data } = useQuery(
    ['get-user', userId],
    () => {
      if (userId) {
        return getUser(userId);
      }
    },
    {
      select: ({ data }): IUser => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data: addUserData } = useQuery('add-user-data', addUserDataService, {
    select: ({ data }): TAddUserDataRoot => {
      return data.data;
    },
    onError: queryError,
  });

  const { mutate: createNewUser, isLoading: isAddingUser } = useMutation(
    'create-user',
    createUser,
    {
      onSuccess: (e: any) => {
        if (e.status === 201) {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const { mutate: editUserMutate, isLoading: isEditingUser } = useMutation(
    'edit-user',
    editUser,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationTour = Yup.object().shape({
    name: Yup.string().required(t('yup.inputs.name')),
    surname: Yup.string().required(t('yup.inputs.surname')),
    numberOne: Yup.string().when('isForgeinOne', {
      is: (isForgeinOne: boolean) => isForgeinOne,
      then: Yup.string(),
      otherwise: Yup.string()
        .min(9, t('yup.inputs.numberNotvalid'))
        .max(9, t('yup.inputs.numberNotvalid')),
    }),
    numberTwo: Yup.string().when('isForgeinTwo', {
      is: (isForgeinTwo: boolean) => isForgeinTwo,
      then: Yup.string(),
      otherwise: Yup.string()
        .min(9, t('yup.inputs.numberNotvalid'))
        .max(9, t('yup.inputs.numberNotvalid')),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      fatherName: data?.fatherName || '',
      email: data?.email || '',
      dateOfBirth: data?.dateOfBirth || '',
      numberOne: data?.phoneNumbers[0] || '',
      numberTwo: data?.phoneNumbers[1] || '',
      roles: data?.roles || ['client'],
      facebook: data?.socialMediaProfiles?.facebook || '',
      instagram: data?.socialMediaProfiles?.instagram || '',
      linkedin: data?.socialMediaProfiles?.linkedin || '',
      isForgeinOne: data ? true : false,
      isForgeinTwo: data ? true : false,
      note: data?.note || '',
    },

    onSubmit: (values) => {
      if (values) {
        const addUserFormBody = userFormMapper(values);

        if (userId) {
          editUserMutate({ userId, data: addUserFormBody });
        } else {
          createNewUser(addUserFormBody);
        }
      }
    },
    validationSchema: validationTour,
    enableReinitialize: true,
  });

  return {
    addUserData,
    data,
    formik,
    isAddingUser,
    isEditingUser,
  };
};
