import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { EditPermissionForm } from '../components/edit-permission-form';
import { useEditPermission } from '../hooks/use-edit-permission';

export const EditPermissionContainer = () => {
  const { data, formik, isEditingPermission } = useEditPermission();

  const routes = [
    {
      label: 'home',
      href: '/dashboard',
    },
    {
      label: 'permissions',
      href: '/dashboard/permissions',
    },
    {
      label: `${data?.name ? data?.name : ''}`,
      href: `/dashboard/permissions/${data?.id}`,
    },
  ];
  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${data?.name ? data?.name : ''}`}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <EditPermissionForm
            formik={formik}
            isEditingPermission={isEditingPermission}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
