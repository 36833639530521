import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getRental } from '../services';
import { useParams } from 'react-router';
import { IRental } from '../types';

export const useRental = () => {
  const { rentalId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-rental', rentalId],
    () => {
      if (rentalId) {
        return getRental(rentalId);
      }
    },
    {
      select: ({ data }): IRental => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
