import { Card, Grid } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useUserForm } from '../hooks/use-user-form';
import { UserForm } from '../components/user-form';

export const UserContainer = () => {
  const { t } = useTranslation();

  const { data, addUserData, formik, isAddingUser, isEditingUser } =
    useUserForm();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('users.title'),
      href: '/dashboard/users',
    },
    {
      label: data ? data?.fullName : t('users.addUser'),
      href: data ? `/dashboard/users/${data?.id}` : '/dashboard/users/new-user',
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data ? data?.fullName : t('users.addUser')}
    >
      <Card sx={{ p: 2 }}>
        <Grid item xs={12}>
          <UserForm
            addUserData={addUserData}
            data={data}
            formik={formik}
            isAddingUser={isAddingUser}
            isEditingUser={isEditingUser}
          />
        </Grid>
      </Card>
    </PageWrapper>
  );
};
