import { useMutation, useQuery } from 'react-query';
import { getEvent, toggleTask } from '../services';
import { useContext } from 'react';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { useParams } from 'react-router-dom';
import { IEvent } from '../types';

export const useAllTasksOfParticipant = () => {
  const { eventId, participantId } = useParams();

  const { handleMessage } = useContext(AlertContext);

  const { data, refetch } = useQuery(
    ['get-event', eventId],
    () => {
      return getEvent(eventId);
    },
    {
      select: ({ data }): IEvent => {
        return data.data;
      },
      onError: queryError,
    },
  );

  const { mutate, isLoading: isUpdating } = useMutation(
    'all-tasks-of-participant',
    toggleTask,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          refetch();
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    data,
    isUpdating,
    mutate,
    eventId,
    participantId,
  };
};
