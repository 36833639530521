import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addTourFormMapper } from '../mappers';
import { useContext } from 'react';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useMutation } from 'react-query';
import { addTour } from '../services';

export const useAddTourForm = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const validationTour = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.eventTitle')),
  });

  const formik = useFormik({
    initialValues: {
      title: 'Atatürk zirvəsi',
      aboutTour: '',
      additionalInfo: [''],
      country: 'Azərbaycan',
      days: 2,
      daysToDeadline: 3,
      description: '',
      difficulty: 'medium',
      district: ' ',
      distance: '',
      duration: {
        nights: '',
        days: '',
      },
      elevation: '',
      endElevation: '',
      equipment: [],
      explanation: [],
      highestPoint: '',
      location: '',
      lowestPoint: '',
      maxElevation: '',
      maxGroupSize: '',
      mountainRange: ' ',
      nightningAt: ' ',
      pricePerPerson: '150',
      packegeIncludes: [],
      paidServices: [],
      placesToSee: [],
      prices: [],
      routeType: '',
      refundConditions: [],
      startElevation: '',
      silderImg: [],
      slug: '',
      totalLength: '',
      tourSchedule: [],
      tourThumb: '',
      tourType: '',
      type: '',
    },
    onSubmit: (values: any) => {
      const addTourBody = addTourFormMapper(values);

      mutate(addTourBody);
    },
    validationSchema: validationTour,
  });

  const { mutate } = useMutation('add-tour', addTour, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return {
    formik,
  };
};
