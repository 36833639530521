import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getEquipments } from '../services';
import { IEquipmentsRoot } from '../types';

export const useEquipments = () => {
  const { data, isLoading } = useQuery('get-equipments', getEquipments, {
    select: ({ data }: { data: IEquipmentsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
    isLoading,
  };
};
