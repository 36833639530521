import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getPermissions = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/permissions`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getPermission = async (
  permissionId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/permissions/${permissionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editPermission = async ({
  permissionId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/permissions/${permissionId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deletePermission = async (
  permissionId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/permissions/${permissionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addPermission = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/permissions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getPermissionByUserId = async (
  userId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/permissions/users/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const updateUserPermissions = async ({
  userId,
  permissions,
}): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/permissions/${userId}`, permissions, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
