export default function Manat() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 189 155">
        <path
          id="azn_symbol_wiki"
          d="M112.619,26.009l-.4-17.876L99.635,14.525l-.363,11.559C50.247,30.846,11.588,84.822,11.588,150.75q0,6.371.478,12.585H29.234c-.161-3.03-.267-6.084-.267-9.173,0-59.8,30.7-108.881,69.87-114.133l-3.508,112.32,19.92-9.588-2.316-102.71c39.087,5.373,69.7,54.4,69.7,114.113,0,3.089-.105,6.143-.267,9.173h18.207q.472-6.208.476-12.585C201.047,84.488,162,30.292,112.619,26.009Z"
          transform="translate(-11.587 -8.133)"
          fill="rgba(0, 0, 0, 0.6)"
        />
      </svg>
    </>
  );
}
