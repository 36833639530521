import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { useTranslation } from 'react-i18next';
import { editCertificate, getCertificate } from '../services';
import { IEquipmentsRoot } from 'src/modules/equipments/types';
import { getTinyUsers } from 'src/modules/users/services';
import { ICertificate } from '../types';

export const useEditCertificate = () => {
  const { handleMessage } = useContext(AlertContext);

  const { t } = useTranslation();

  const { certificateId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['get-certificate', certificateId],
    () => {
      if (certificateId) {
        return getCertificate(certificateId);
      }
    },
    {
      select: ({ data }): ICertificate => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  const { data: usersData } = useQuery('get-tiny-users', getTinyUsers, {
    select: ({ data }: { data: IEquipmentsRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  const { mutate, isLoading: isEditingCertificate } = useMutation(
    'edit-certificate',
    editCertificate,
    {
      onSuccess: (e: any) => {
        if (e.status === 200) {
          handleMessage(true, 'Process was successful', 'success');
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    user: Yup.string().required(t('yup.inputs.name')),
  });

  const formik = useFormik({
    initialValues: {
      user: data?.user.id || '',
      description: data?.description || '',
      startDate: data?.startDate || '',
      finishDate: data?.finishDate || '',
    },
    onSubmit: (values) => {
      mutate({ certificateId, data: values });
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return {
    data,
    usersData,
    formik,
    isLoading,
    isEditingCertificate,
  };
};
