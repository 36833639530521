import { Outlet } from 'react-router-dom';
import { AddEventContainer } from '../containers/add-event-container';

export const AddEvent = () => {
  return (
    <>
      <Outlet />
      <AddEventContainer />
    </>
  );
};
