import { useNavigate } from 'react-router-dom';

import {
  Box,
  Modal,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEditTaskOfParticipant } from '../hooks/use-edit-task-of-participant';
import { OmInput } from 'src/shared/components';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 700,
  maxHeight: 700,
  overflow: 'auto',
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

export const EditTaskOfParticipant = () => {
  const { t } = useTranslation();

  const { formik, isUpdating } = useEditTaskOfParticipant();

  const navigate = useNavigate();

  const handleClose = () => navigate(-1);

  return (
    <>
      <Modal
        keepMounted
        open={true}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('modal.editTask')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <OmInput
                formik={formik}
                label={t('inputs.labels.name')}
                name="title"
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%', mt: 1 }}
          >
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {t('buttons.closeWindow')}
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={isUpdating}
              type="submit"
            >
              {isUpdating && <CircularProgress size={14} />}
              {t('buttons.edit')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
