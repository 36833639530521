import { Equipments } from '../pages/equipments';
import { AddEquipment } from '../pages/add-equipment';
import { EquipmentDetails } from '../pages/equipment-details';
import { EditEquipment } from '../pages/edit-equipment';

const equipmentsRouter = {
  path: 'equipments',
  children: [
    {
      path: '',
      element: <Equipments />,
      children: [],
    },
    {
      path: ':equipmentId',
      element: <EquipmentDetails />,
    },
    {
      path: 'add-equipment',
      element: <AddEquipment />,
    },
    {
      path: 'edit/:equipmentId',
      element: <EditEquipment />,
    },
  ],
};

export default equipmentsRouter;
