import { Outlet } from 'react-router-dom';
import { RentalDetailsContainer } from '../containers/rental-details-container';

export const RentalDetails = () => {
  return (
    <>
      <Outlet />
      <RentalDetailsContainer />
    </>
  );
};
