import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { queryError } from 'src/shared/utils/query-error';
import { getTour } from '../services';
import { ITour } from '../types';

export const useTour = () => {
  const { tourId } = useParams();

  const { data } = useQuery(
    ['get-tour', tourId],
    () => {
      if (tourId) {
        return getTour(tourId);
      }
    },
    {
      select: ({ data }) : ITour => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
  };
};
