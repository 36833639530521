import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IOptions } from 'src/shared/components/generic-table/types';
import Manat from 'src/assets/icon/manat';
import { ServicesTableActionButtons } from '../components/action-buttons';
import { IService } from '../types';

export const useServicesTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('services.title'),
      href: '/dashboard/services',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (item: IService) => item.name,
      },
      {
        id: 'amount',
        align: 'left',
        label: t('table.amount'),
        extructor: (item: IService) => (
          <Typography display={'flex'} alignItems={'center'} gap={0.5}>
            {item.price} <Manat />
          </Typography>
        ),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IService) => {
          return <ServicesTableActionButtons servicesId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
