import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useContext } from 'react';

import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { addEquipment } from '../services';

export const useAddEquipment = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('yup.inputs.name')),
    brand: Yup.string().required(t('yup.inputs.brand')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      brand: '',
      model: '',
      color: '',
      type: '',
      description: '',
      size: '',
    },
    onSubmit: (values) => {
      if (values) {
        mutate(values);
      }
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { mutate, isLoading } = useMutation('add-equipment', addEquipment, {
    onSuccess: (e: any) => {
      if (e.status === 201) {
        handleMessage(true, 'Process was successful', 'success');
        formik.resetForm();
        navigate(-1);
      } else {
        handleMessage(true, e.message, 'error');
      }
    },
    onError: (error: any) => {
      const errorMsg = error?.response?.data?.message || 'Unknown error';
      handleMessage(true, errorMsg, 'error');
    },
  });

  return { formik, isLoading };
};
