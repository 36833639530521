import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { AlertContext } from 'src/shared/contexts/Alert';
import { deleteFileUpload } from '../services';

export const useDeleteFileUpload = () => {
  const { handleMessage } = useContext(AlertContext);

  const { mutate: deleteFileUploadMutate, isLoading: isUpdatingFile } =
    useMutation('delete-file-upload', deleteFileUpload, {
      onSuccess: (e: any) => {
        if (e.status === 204) {
          handleMessage(true, 'Process was successful', 'success');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    });

  return {
    deleteFileUploadMutate,
    isUpdatingFile,
  };
};
