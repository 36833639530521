import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getTasks } from '../services';
import { TTasksRoot } from '../types';

export const useTasks = () => {
  const { data, isLoading } = useQuery('get-tasks', getTasks, {
    select: ({ data }: { data: TTasksRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
    isLoading,
  };
};
