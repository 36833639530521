import { AddTask } from '../pages/add-task';
import { EditTask } from '../pages/edit-task';
import { TaskDetails } from '../pages/task-details';
import { Tasks } from '../pages/tasks';

const tasksRouter = {
  path: 'tasks',
  children: [
    {
      path: '',
      element: <Tasks />,
      children: [],
    },
    {
      path: ':taskId',
      element: <TaskDetails />,
    },
    {
      path: 'add-task',
      element: <AddTask />,
    },
    {
      path: 'edit/:taskId',
      element: <EditTask />,
    },
  ],
};

export default tasksRouter;
