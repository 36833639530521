import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useEquipmentsTable } from '../hooks/use-equipments-table';
import { useEquipments } from '../hooks/use-equipments';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const EquipmentsContainer = () => {
  const { t } = useTranslation();

  const { data } = useEquipments();

  const [columns, options, routes] = useEquipmentsTable();

  return (
    <PageWrapper
      action={
        <Button
          sx={{
            mr: { xs: 1 },
          }}
          component={RouterLink}
          to="/dashboard/equipments/add-equipment"
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          {t('buttons.addEquipment')}
        </Button>
      }
      routes={routes}
      title={t('equipments.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
