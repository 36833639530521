import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IOptions } from 'src/shared/components/generic-table/types';
import { TransactionsActionButtons } from '../components/action-buttons';
import Label from 'src/shared/components/Label';
import Manat from 'src/assets/icon/manat';
import { ITransaction } from '../types';

export const useTransactionsTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('transactions.title'),
      href: '/dashboard/transactions',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'title',
        align: 'left',
        label: t('table.name'),
        extructor: (item: ITransaction) => item.title,
      },
      {
        id: 'category',
        align: 'center',
        label: t('table.category'),
        extructor: (item: ITransaction) => item.category,
      },
      {
        id: 'type',
        align: 'center',
        label: t('table.type'),
        extructor: (item: ITransaction) => (
          <Label color={item.type === 'income' ? 'success' : 'error'}>
            {item.type}
          </Label>
        ),
      },
      {
        id: 'amount',
        align: 'center',
        label: t('table.amount'),
        extructor: (item: ITransaction) => (
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
          >
            {item.amount}
            <Manat />
          </Typography>
        ),
      },
      {
        id: 'description',
        align: 'center',
        label: t('table.description'),
        extructor: (item: ITransaction) => item.description,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: ITransaction) => {
          return <TransactionsActionButtons transactionId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
