import { useQuery } from 'react-query';

import { queryError } from 'src/shared/utils/query-error';
import { getTinyUsers } from 'src/modules/users/services';
import { TTinyUsersRoot } from 'src/modules/users/types';

export const useParticipants = () => {
  const { data } = useQuery('get-participants', getTinyUsers, {
    select: ({ data }: { data: TTinyUsersRoot }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
