import PageWrapper from 'src/shared/components/page-wrapper';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useCouponDetails } from '../hooks/use-coupon-details';
import { CouponDetailsForm } from '../components/coupon-details-form';

export const CouponDetailsContainer = () => {
  const { data, isLoading } = useCouponDetails();

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('coupons.title'),
      href: '/dashboard/coupons',
    },
    {
      label: data?.code ? data?.code : '',
      href: `/dashboard/coupons/${data?.id}`,
    },
  ];

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={`${t('coupons.couponCode')}: ${data?.code ? data?.code : ''}`}
    >
      <Helmet>
        <title>{`${t('coupons.couponCode')} | ${
          data?.code ? data?.code : ''
        }`}</title>
      </Helmet>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container>
            <CouponDetailsForm data={data} />
          </Grid>
        </Card>
      )}
    </PageWrapper>
  );
};
