import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useManageTagsTable } from '../hooks/use-manage-tags-table';
import { useUpdateUserTags } from '../hooks/use-update-user-tags';
import SubmitButton from 'src/shared/components/Submit-button';

export const ManageTagsContainer = () => {
  const { t } = useTranslation();

  const { data, formik, isLoading } = useUpdateUserTags();

  const [columns, options, routes] = useManageTagsTable(formik);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PageWrapper routes={routes} title={t('manageTags.title')}>
        <GenericTable data={data} columns={columns} options={options} />
      </PageWrapper>
      <Box display={'flex'} justifyContent={'flex-end'} p={3} pt={0}>
        <SubmitButton load={isLoading} sx={{ width: { xs: '100%', md: 200 } }}>
          {t('buttons.confirm')}
        </SubmitButton>
      </Box>
    </Box>
  );
};
