import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getCategory } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { ICategory } from '../types';

export const useCategory = () => {
  const { categoryId } = useParams();

  const { data } = useQuery(
    ['get-category', categoryId],
    () => {
      if (categoryId) {
        return getCategory(categoryId);
      }
    },
    {
      select: ({ data }): ICategory => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );
  return {
    data,
  };
};
