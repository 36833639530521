import { useQuery } from 'react-query';
import { getEvent } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { useParams } from 'react-router-dom';
import { IEvent } from '../types';

export const useEvent = () => {
  const { eventId } = useParams();

  const { data, isLoading, refetch } = useQuery(
    ['get-event', eventId],
    () => {
      return getEvent(eventId);
    },
    {
      select: ({ data }): IEvent => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
    eventId,
    refetch,
  };
};
