import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IEquipment } from '../types';

export const EquipmentDetailsForm = ({ data }: { data: IEquipment }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.equipmentName')}: {data?.name}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.brand')}: {data?.brand}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.model')}: {data?.model}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.color')}: {data?.color}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.type')}: {data?.type}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.size')}: {data?.size}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.description')}: {data?.description}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
