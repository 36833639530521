export const addNewEventMapper = (values, userId) => {
  return {
    tour: values.tour,
    title: values.eventTitle,
    date: values.date,
    pricePerPerson: values.pricePerPerson,
    guides: values.guides,
    tasks: values.tasks.map((task) => ({
      ...task,
      deadline: new Date().setDate(
        values.date.getDate() - task.beforeEventDays,
      ),
      assignedTo: userId,
    })),
    groupSize: values.groupSize,
    eventLanguages: values.eventLanguages,
    duration: values.duration,
  };
};

export const addNewTaskForTourMapper = (values) => {
  return {
    relatedTo: 'event',
    event: values.event,
    tasks: values.tasks,
  };
};

export const addNewTaskForParticipantsMapper = (values) => {
  return {
    relatedTo: 'participant',
    participants: values.participants,
    event: values.event,
    tasks: values.tasks,
  };
};
