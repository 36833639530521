import {
  Box,
  Modal,
  Button,
  CircularProgress,
  Typography,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Manat from 'src/assets/icon/manat';
import { OmInput } from 'src/shared/components';
import { useAddAmount } from '../hooks/use-add-amount';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  transform: 'translate(-50%, -50%)',
  minWidth: 220,
  maxWidth: 300,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

export const AddAmount = () => {
  const { t } = useTranslation();

  const { formik, isLoading } = useAddAmount();

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Modal
        keepMounted
        open={true}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ py: 2, fontSize: '18px', textAlign: 'center' }}
          >
            {t('modal.makePayment')}
          </Typography>
          <OmInput
            formik={formik}
            label={t('inputs.labels.price')}
            name="amount"
            endAdornment={
              <InputAdornment position="end">
                <Manat />
              </InputAdornment>
            }
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: '15px;' }}
              onClick={handleClose}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: '15px;' }}
              disabled={isLoading}
              type="submit"
            >
              {isLoading && <CircularProgress size={14} />}
              {t('buttons.confirm')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
