import { Box, Grid, Typography, Autocomplete, TextField } from '@mui/material';
import { OmDateTimePicker, OmInput } from 'src/shared/components';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { useAddCertificate } from '../hooks/use-add-certificate';
import { ITinyUser } from 'src/modules/users/types';

export const AddCertificateForm = () => {
  const { usersData, formik, isLoading } = useAddCertificate();

  const { t } = useTranslation();

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              options={usersData || []}
              disablePortal
              onChange={(_e, newValue: ITinyUser) => {
                formik.setFieldValue('user', newValue ? newValue.id : '');
              }}
              getOptionLabel={(option: ITinyUser) => {
                return `${option.fullName} (${option.code})`;
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={t('inputs.labels.user')}
                />
              )}
            />
            {!!formik.errors.user && formik.touched.user && (
              <Typography
                sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
              >
                {formik.errors.user}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmDateTimePicker
              inputFormat="dd/MM/yyyy"
              formik={formik}
              label={t('inputs.labels.startDate')}
              name="startDate"
            />
            {!!formik.errors.startDate && formik.touched.startDate && (
              <Typography
                sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
              >
                {formik.errors.startDate}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmDateTimePicker
              inputFormat="dd/MM/yyyy"
              formik={formik}
              label={t('inputs.labels.finishDate')}
              name="finishDate"
            />
            {!!formik.errors.finishDate && formik.touched.finishDate && (
              <Typography
                sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
              >
                {formik.errors.finishDate}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <OmInput
              multiline={true}
              rows={3}
              formik={formik}
              label={t('inputs.labels.description')}
              name="description"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <SubmitButton
            load={isLoading}
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {t('buttons.add')}
          </SubmitButton>
        </Grid>
      </Box>
    </>
  );
};
