import { useMemo } from 'react';
import { IOptions } from 'src/shared/components/generic-table/types';

import { useTranslation } from 'react-i18next';
import EquipmentsTableActionButtons from '../components/action-buttons';
import { IEquipment } from '../types';

export const useEquipmentsTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('equipments.title'),
      href: '/dashboard/equipments',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'name',
        align: 'left',
        label: t('table.name'),
        extructor: (equipment: IEquipment) => equipment.name,
      },
      {
        id: 'brand',
        align: 'center',
        label: t('table.brand'),
        extructor: (equipment: IEquipment) => equipment.brand,
      },
      {
        id: 'model',
        align: 'center',
        label: t('table.model'),
        extructor: (equipment: IEquipment) => equipment.model,
      },
      {
        id: 'color',
        align: 'center',
        label: t('table.color'),
        extructor: (equipment: IEquipment) => equipment.color,
      },
      {
        id: 'type',
        align: 'center',
        label: t('table.type'),
        extructor: (equipment: IEquipment) => equipment.type,
      },
      {
        id: 'size',
        align: 'center',
        label: t('table.size'),
        extructor: (equipment: IEquipment) => equipment.size,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (equipment: IEquipment) => {
          return <EquipmentsTableActionButtons equipmentId={equipment.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
