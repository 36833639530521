import { AddPermission } from '../pages/add-permission';
import { EditPermission } from '../pages/edit-permission';
import { PermissionDetails } from '../pages/permission-details';
import { Permissions } from '../pages/permissions';

const permissionsRouter = {
  path: 'permissions',
  children: [
    {
      path: '',
      element: <Permissions />,
      children: []
    },
    {
      path: ':permissionId',
      element: <PermissionDetails />,
    },
    {
      path: 'add-permission',
      element: <AddPermission />
    },
    {
      path: 'edit/:permissionId',
      element: <EditPermission />
    }
  ]
};

export default permissionsRouter;
