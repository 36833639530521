import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptions } from 'src/shared/components/generic-table/types';
import Manat from 'src/assets/icon/manat';
import { Typography } from '@mui/material';
import { RentalsTableActionButtons } from '../components/action-buttons';
import Label from 'src/shared/components/Label';
import { IRental } from '../types';

export const useRentalsTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('rentals.title'),
      href: '/dashboard/rentals',
    },
  ];

  const getRentalDateLabel = (endDate: string) => {
    const roundedDays = (
      (new Date(endDate).getTime() - Date.now()) /
      86400000
    ).toFixed(0);

    switch (true) {
      case roundedDays === '0':
        return (
          <Label color={'warning'}>
            {roundedDays} {t('daysLeft')}
          </Label>
        );
      case roundedDays <= '0':
        return (
          <Label color={'error'}>
            {roundedDays} {t('timeIsUp')}
          </Label>
        );
      default:
        return (
          <Label color={'success'}>
            {roundedDays} {t('daysLeft')}
          </Label>
        );
    }
  };

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: IRental) => item.code,
      },
      {
        id: 'customer',
        align: 'left',
        label: t('table.customer'),
        extructor: (item: IRental) => item.customer.fullName,
      },
      {
        id: 'totalCost',
        align: 'center',
        label: t('table.totalCost'),
        extructor: (item: IRental) => (
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
          >
            {item.totalCost}
            <Manat />
          </Typography>
        ),
      },
      {
        id: 'rentalDate',
        align: 'center',
        label: t('table.rentalDate'),
        extructor: (item: IRental) => getRentalDateLabel(item.endDate),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IRental) => {
          return <RentalsTableActionButtons rentalId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
