import { Box, Button, Typography, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 1000,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  outline: 'none',
  p: 4,
};

export const TaskModalDetails = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h6"
          component="h2"
          textAlign="center"
          sx={{ mb: 1.5 }}
        >
          {t('modal.details')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.action')}: kim tərəfindən bitirildi
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.action')}: kimə assign olundu
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                border: '1px solid #c4c4c4',
                padding: '12px 10px',
                borderRadius: '10px',
              }}
            >
              {t('inputs.labels.action')}: saat neçədə bitirildi
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: '15px;' }}
            onClick={handleClose}
          >
            {t('buttons.closeWindow')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
