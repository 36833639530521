import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';

import getAuthToken from 'src/utils/getAuthToken';

export const getActions = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/actions`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getAction = async (actionId: string): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/actions/${actionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const addAction = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(`/api/v1/actions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const deleteAction = async (
  actionId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/api/v1/actions/${actionId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const editAction = async ({
  actionId,
  data,
}): Promise<AxiosResponse> => {
  try {
    return await axios.patch(`/api/v1/actions/${actionId}`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
