import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getService } from '../services';
import { useParams } from 'react-router';
import { IService } from '../types';

export const useServiceDetails = () => {
  const { serviceId } = useParams();

  const { data, isLoading } = useQuery(
    ['get-service', serviceId],
    () => {
      if (serviceId) {
        return getService(serviceId);
      }
    },
    {
      select: ({ data }): IService => {
        if (data?.status === 'success') {
          return data?.data;
        }
      },
      onError: queryError,
    },
  );

  return {
    data,
    isLoading,
  };
};
