import { useContext, useState } from 'react';
import PageWrapper from 'src/shared/components/page-wrapper';
import { Box, Button, Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUsersTable } from '../hooks/use-users-table';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../hooks/use-users';
import UserSearch from '../components/user-search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UsersContext } from '../context/users-context';
import GenericTableV2 from 'src/shared/components/generic-table-v2';

export const UsersContainer = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { page, limit, setPage, setLimit } = useContext(UsersContext);

  const { data } = useUsers();

  const [columns, options, routes] = useUsersTable();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      action={
        <Link to="/dashboard/users/new-user" style={{ textDecoration: 'none' }}>
          <Button variant="contained">{t('buttons.addUser')}</Button>
        </Link>
      }
      routes={routes}
      title={t('users.title')}
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <UserSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data?.users}
          rowsCount={data?.total}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
};
