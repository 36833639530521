import {
  Autocomplete,
  TextField,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useTaskModal } from '../hooks/use-task-modal';
import { ITinyUser } from 'src/modules/users/types';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 500,
  width: '100%',
  borderRadius: '5px',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  outline: 'none',
  p: 4,
};

export const TaskModal = () => {
  const { data, formik, isUpdating, navigate } = useTaskModal();

  const { t } = useTranslation();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
        <Autocomplete
          options={data || []}
          disablePortal
          disabled={false}
          onChange={(_e, newValue) => {
            formik.setFieldValue('executedBy', newValue ? newValue.id : '');
          }}
          getOptionLabel={(option: ITinyUser) => {
            return `${option.fullName} (${option.code})`;
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={t('inputs.labels.whoCompletesTheTask')}
            />
          )}
        />
        {!!formik.errors.executedBy && formik.touched.executedBy && (
          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FF1943' }}
          >
            {formik.errors.executedBy}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: '15px;' }}
            onClick={handleClose}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginTop: '15px;' }}
            disabled={isUpdating}
            type="submit"
          >
            {isUpdating && (
              <CircularProgress size={14} sx={{ color: '#fff', mr: 0.5 }} />
            )}
            {t('buttons.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
