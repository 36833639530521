export const addTransactionMapper = ({
  values,
  transactionType,
  data,
}: {
  values: any;
  transactionType: string;
  data: any;
}) => {
  return {
    title: values.title,
    amount: values.amount,
    category: values.other,
    description: values.description,
    type: data ? data?.type : transactionType,
    spender: values.spender,
    relatedEvent: values.relatedEvent,
  };
};
