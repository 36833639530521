import type { ReactNode } from 'react';

// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import GroupIcon from '@mui/icons-material/Group';
import HikingIcon from '@mui/icons-material/Hiking';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TuneIcon from '@mui/icons-material/Tune';
import TagIcon from '@mui/icons-material/Tag';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PaymentsIcon from '@mui/icons-material/Payments';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ConstructionIcon from '@mui/icons-material/Construction';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import RepeatIcon from '@mui/icons-material/Repeat';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InfoIcon from '@mui/icons-material/Info';
import StorefrontIcon from '@mui/icons-material/Storefront';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'heading.general',
    items: [
      {
        name: 'events.title',
        icon: HikingIcon,
        link: '/dashboard/events',
      },
      {
        name: 'users.title',
        icon: GroupIcon,
        link: '/dashboard/users',
      },
      {
        name: 'tours.title',
        icon: HikingIcon,
        link: '/dashboard/tours',
      },
      {
        name: 'equipments.title',
        icon: ConstructionIcon,
        link: '/dashboard/equipments',
      },
      {
        name: 'inventories.title',
        icon: InventoryIcon,
        link: '/dashboard/inventories',
      },
      {
        name: 'additionalInfos.title',
        icon: InfoIcon,
        link: '/dashboard/additional-infos',
      },
      {
        name: 'services.title',
        icon: StorefrontIcon,
        link: '/dashboard/services',
      },
      {
        name: 'rentals.title',
        icon: SupervisedUserCircleIcon,
        link: '/dashboard/rentals',
      },
      {
        name: 'coupons.title',
        icon: LocalOfferIcon,
        link: '/dashboard/coupons',
      },
      {
        name: 'certificates.title',
        icon: WorkspacePremiumIcon,
        link: '/dashboard/certificates',
      },
      {
        name: 'transactions.title',
        icon: CompareArrowsIcon,
        link: '/dashboard/transactions',
      },
      {
        name: 'actions.title',
        icon: ChecklistRtlIcon,
        link: '/dashboard/actions',
      },
      {
        name: 'tasks.title',
        icon: AssignmentIcon,
        link: '/dashboard/tasks',
      },
      {
        name: 'repeatingTransactions.title',
        icon: RepeatIcon,
        link: '/dashboard/repeating-transactions',
      },
      {
        name: 'addExpense.title',
        icon: PaymentsIcon,
        link: '/dashboard/add-expense',
      },
      {
        name: 'addTransaction.title',
        icon: PaymentsIcon,
        link: '/dashboard/add-transaction',
      },
      {
        name: 'appManager.title',
        icon: TuneIcon,
        items: [
          {
            name: 'categories.title',
            icon: ViewCompactIcon,
            link: '/dashboard/categories',
          },
          {
            name: 'permissions.title',
            icon: LockOpenIcon,
            link: '/dashboard/permissions',
          },
          {
            name: 'tags.title',
            link: '/dashboard/tags',
            icon: TagIcon,
          },
        ],
      },
    ],
  },
];

export default menuItems;
