import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptions } from 'src/shared/components/generic-table/types';
import { TourTableButtons } from '../components/action-buttons';
import { ITour } from '../types';

export const useToursTable = (): [any, any, any] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('tours.title'),
      href: '/dashboard/tours',
    },
  ];

  const columns = useMemo(() => {
    const columns = [
      {
        id: 'title',
        align: 'left',
        label: t('table.name'),
        extructor: (tour: ITour) => tour.title,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (tour: ITour) => {
          return <TourTableButtons tourId={tour.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
