import { AxiosResponse } from 'axios';
import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getInventories = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v1/inventories`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  } catch (e) {
    return e;
  }
};

export const createInventory = async (data): Promise<AxiosResponse> => {
  try {
    const authToken = getAuthToken();

    const response = await axios.post('/api/v1/inventories', data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};

export const addFileUpload = async (data): Promise<AxiosResponse> => {
  try {
    let url = `/api/v1/files/upload`;
    return await axios.post(url, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    console.log('error from service', e);
    return e;
  }
};

export const deleteFileUpload = async (data): Promise<AxiosResponse> => {
  try {
    return await axios.post(
      `/api/v1/files/remove`,
      { fileId: data },
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};
