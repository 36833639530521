import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTransactions } from '../hooks/use-transactions';
import { useTransactionsTable } from '../hooks/use-transactions-table';

export const TransactionsContainer = () => {
  const { t } = useTranslation();

  const { data } = useTransactions();

  const [columns, options, routes] = useTransactionsTable();

  return (
    <PageWrapper
      action={
        <Box display="flex" gap={2}>
          <Link
            to="/dashboard/add-expense"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" color="error">
              {t('buttons.addExpense')}
            </Button>
          </Link>
          <Link
            to="/dashboard/add-transaction"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained">{t('buttons.addTransaction')}</Button>
          </Link>
        </Box>
      }
      routes={routes}
      title={t('transactions.title')}
    >
      <GenericTable data={data} columns={columns} options={options} />
    </PageWrapper>
  );
};
